import * as leadCaptureActionTypes from "../actions/leadCaptureActionType";

const initialState = {
  leadCaptureList: null,
  leadCaptureCount: null,
  pageVisitList: null,
  pageVisitCount: null
};

const leadCaptureReducer = (state = initialState, action) => {
  switch (action.type) {
    case leadCaptureActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case leadCaptureActionTypes.GET_LEADCAPTURE_LIST:
      return {
        ...state,
        leadCaptureList: action.payload
      };
    case leadCaptureActionTypes.GET_LEADCAPTURE_COUNT:
      return {
        ...state,
        leadCaptureCount: action.payload
      };
    case leadCaptureActionTypes.GET_PAGE_VISIT_LIST:
      return {
        ...state,
        pageVisitList: action.payload
      };
    case leadCaptureActionTypes.GET_PAGE_VISIT_COUNT:
      return {
        ...state,
        pageVisitCount: action.payload
      };
    default:
      return state;
  }
};

export default leadCaptureReducer;
