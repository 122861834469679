import * as actionTypes from "../actions/actionTypes";

const initialState = {
    isAuthenticated: false,
    loginErrorMessage: null,
    signUpErrorMessage: null,
    signUpSuccess: false,
    userInfo: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                userInfo: action.payload.payload
            };
        }
        case actionTypes.LOGOUT_SUCCESS: {
            return {
                ...state,
                isAuthenticated: false,
                userInfo: null
            };
        }
        case actionTypes.LOGIN_ERROR: {
            return {
                ...state,
                isAuthenticated: false,
                loginErrorMessage: action.payload
            };
        }
        case actionTypes.CLEAR_LOGIN_ERROR_MESSAGE: {
            return {
                ...state,
                loginErrorMessage: null
            }
        }
        case actionTypes.SIGNUP_SUCCESS: {
            return {
                ...state,
                signUpSuccess: true,
                signUpErrorMessage: false
            }
        }
        case actionTypes.SIGNUP_ERROR: {
            return {
                ...state,
                signUpErrorMessage: action.payload,
                signUpSuccess: false
            }
        }
        case actionTypes.CLEAR_SIGNUP_ERROR_MESSAGE: {
            return {
                ...state,
                signUpErrorMessage: null,
                signUpSuccess: false
            }
        }
        default:
            return state;
    }
};