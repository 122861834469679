import * as reportActionTypes from "../actions/reportActionTypes";

const initialState = {
  saleReport: null,
  labourVsSaleReport: null,
  inventoryReport: null,
  cogsReport: [],
  itemSaleReport: [],
  commissionReport: [],
  commissionReportDetail: null,
};

const salereportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case reportActionTypes.GET_SALE_REPORT_LIST:
      return {
        ...state,
        saleReport: action.payload
      };
    case reportActionTypes.GET_LABOUR_VS_SALES_LIST:
      return {
        ...state,
        labourVsSaleReport: action.payload
      };
    case reportActionTypes.GET_INVENTORY_REPORT_LIST:
      return {
        ...state,
        inventoryReport: action.payload
      };
    case reportActionTypes.GET_COGS_REPORT_LIST:
      return {
        ...state,
        cogsReport: action.payload
      };
    case reportActionTypes.GET_ITEM_SALE_REPORT_LIST:
      return {
        ...state,
        itemSaleReport: action.payload
      };
    case reportActionTypes.GET_COMMISSION_REPORT_LIST:
      return {
        ...state,
        commissionReport: action.payload
      }
    case reportActionTypes.GET_COMMISSION_REPORT_DETAILS:
      return {
        ...state,
        commissionReportDetail: action.payload
      }
    default:
      return state;
  }
};

export default salereportReducer;

