import * as repackOrderActionTypes from "../actions/repackOrderActionTypes";

const initialState = {
  repackOrderDetails: null,
  repackOrderList: null,
  selectedRepackOrderCode: null,
  repackOrderStockList: null,
};

const repackOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case repackOrderActionTypes.SET_REPACK_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case repackOrderActionTypes.SET_REPACK_ORDER_DETAILS:
      return {
        ...state,
        repackOrderDetails: action.payload
      };
    case repackOrderActionTypes.GET_REPACK_ORDER_STOCK_LIST:
      return {
        ...state,
        repackOrderStockList: action.payload,
      };
    case repackOrderActionTypes.GET_REPACK_ORDER_LIST:
      return {
        ...state,
        repackOrderList: action.payload
      };
    case repackOrderActionTypes.SET_SELECTED_REPACK_ORDER_CODE:
      return {
        ...state,
        selectedRepackOrderCode: action.payload
      };
    case repackOrderActionTypes.GET_REPACK_ORDER_DETAILS:
      return {
        ...state,
        repackOrderDetails: action.payload
      };


    default:
      return state;
  }
};

export default repackOrderReducer;
