/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import map from "lodash/map";
import intersection from "lodash/intersection";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
import { leftPanel } from "../../constants/GlobalConstants";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import isAuthorized from "auth-plugin";

// image for avatar in Sidebar
import avatar from "assets/img/default-avatar.png";

import getRoutes from "routes.js";

var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(getRoutes({ track: props.track })),
      openAvatar: false,
      isWindows: navigator.userAgentData.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      const availablePostions = prop.positions || [];
      const isLeftPanel =
        intersection([leftPanel], availablePostions).length > 0;
      if (prop.redirect || !isAuthorized(prop.id) || !isLeftPanel) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <OverlayTrigger
            placement="right"
            key={key}
            overlay={
              <Tooltip id={prop.name} className="sidelinks">
                {prop.name}
              </Tooltip>
            }
          >
            <li
              className={this.getCollapseInitialState(prop.views) ? "active" : ""}
              key={key}
              onClick={() => !document.body.classList.contains('sidebar-mini') && document.body.classList.toggle("sidebar-mini")}
            >
              <NavLink
                to={prop.layout + prop.path}
                className="nav-link"
                activeClassName="active"
              >
                <img src={prop.icon} alt={prop.name} />
                <p>{prop.name}</p>
              </NavLink>
              <Collapse in={this.state[prop.state]} style={{ display: "none" }}>
                <ul className="nav">{this.createLinks(prop.views)}</ul>
              </Collapse>
            </li>
          </OverlayTrigger>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key} onClick={() => !document.body.classList.contains('sidebar-mini') && document.body.classList.toggle("sidebar-mini")}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <img src={prop.icon} alt={prop.name} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidUpdate() {
    if (navigator.userAgentData.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.userAgentData.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper);
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.userAgentData.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  openChangePassword = () => {
    this.props.history.push("changepassword");
  };

  getPermissions = (userRoles) => {
    const roles = map(userRoles, (role) => {
      return role.role;
    });
    return roles;
  };

  render() {
    const track = this.props.track;
    return (
      <div className="sidebar">
        <div className="navbar-minimize">
          <button
            id="minimizeSidebar"
            className="btn btn-icon"
            onClick={this.props.handleMiniClick}
          >
            <i className="fa fa-chevron-circle-left visible-on-sidebar-regular" />
            <i className="fa fa-chevron-circle-right visible-on-sidebar-mini" />
          </button>
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(getRoutes({ track }))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authReducer.userInfo,
    permissionMapping: state.security.uiComponentsPermissionMapping,
    track: state.dataDictionary.track,
  };
};
export default connect(mapStateToProps, {})(withRouter(Sidebar));