import * as stockTransferActionTypes from "../actions/stockTransferActionTypes";

const initialState = {
  stockTransferDetails: null,
  stockTransferList: null,
  selectedStockTransferCode: null,
};

const stockTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case stockTransferActionTypes.SET_STOCK_TRANSFER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case stockTransferActionTypes.SET_STOCK_TRANSFER_DETAILS:
      return {
        ...state,
        stockTransferDetails: action.payload,
      };
    case stockTransferActionTypes.GET_STOCK_TRANSFER_LIST:
      return {
        ...state,
        stockTransferList: action.payload,
      };
    case stockTransferActionTypes.SET_SELECTED_STOCK_TRANSFER_CODE:
      return {
        ...state,
        selectedStockTransferCode: action.payload,
      };
    case stockTransferActionTypes.GET_STOCK_LIST_BY_LOT_NUMBER:
      return {
        ...state,
        stockListByLot: action.payload,
      };
    case stockTransferActionTypes.GET_STOCK_TRANSFER_DETAILS:
      return {
        ...state,
        stockTransferDetails: action.payload,
      };

    default:
      return state;
  }
};

export default stockTransferReducer;
