import * as financeActionTypes from "../actions/financeActionTypes";

const initialState = {
  financeList: null,
  financeSupplierList: null,
  financeCustomerList: null,
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case financeActionTypes.SET_FINANCE_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case financeActionTypes.GET_FINANCE_LIST:
      return {
        ...state,
        financeList: action.payload,
      };
    case financeActionTypes.GET_FINANCE_SUPPLIER_LIST:
      return {
        ...state,
        financeSupplierList: action.payload,
      };
    case financeActionTypes.GET_FINANCE_CUSTOMER_LIST:
      return {
        ...state,
        financeCustomerList: action.payload,
      };
    default:
      return state;
  }
};

export default financeReducer;
