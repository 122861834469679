export const SET_SELECTED_SUPPLIER_CODE = "SET_SELECTED_SUPPLIER_CODE";
export const SET_SUPPLIER_DETAILS = "SET_SUPPLIER_DETAILS";
export const SET_SUPPLIER_REDUCER_INIT_MODE = "SET_SUPPLIER_REDUCER_INIT_MODE";
export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST";
export const GET_SUPPLIER_PERFORMANCE_DASHBOARD_LIST = "GET_SUPPLIER_PERFORMANCE_DASHBOARD_LIST";


export const GET_SUPPLIER_DETAILS = "GET_SUPPLIER_DETAILS";
export const SET_UPLOADED_FILE_URL = "SET_UPLOADED_FILE_URL";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_INGREDIENT_LIST = "GET_INGREDIENT_LIST";
export const SET_BULK_IMPORT_SUPPLIER_DETAILS = "SET_BULK_IMPORT_SUPPLIER_DETAILS";
export const GET_SUPPLIER_PAYMENT_DETAILS = "GET_SUPPLIER_PAYMENT_DETAILS";