export const INCIDENT_DETAILS_HEADER_TITLE = "incident";
export const MANAGE_INCIDENT_HEADER_TITLE = "incident";

export const CREATE_INCIDENT_HEADER_TITLE = "Create Incident";
export const EDIT_INCIDENT_HEADER_TITLE = "Edit Incident";
export const MODULE_NAME = "incident";

export const CREATE_INCIDENT = "createIncident";
export const DEATIVATE_INCIDENT = "deActivate";
export const ACTIVATE_INCIDENT = "activate";

export const CREATE_INCIDENT_PAGE_URL = '/admin/create-incident';
export const CREATE_MFG_ORDER_PAGE_URL = '/admin/create-mfg-order';
export const CREATE_SALE_ORDER_PAGE_URL = '/admin/create-sale-order';
export const CREATE_KIT_ORDER_PAGE_URL = '/admin/create-stock-kit'
export const MANAGE_INCIDENT_PAGE_URL = '/admin/manage-incident';
export const CREATE_PAGE = "createPage";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GET_INCIDENT_NOTIFICATION_LIST_URL = `${BASE_URL}/notifications`;
export const GET_INCIDENT_LIST_URL = `${BASE_URL}/incidents`;
export const GET_INCIDENT_DETAILS_URL = `${BASE_URL}/incidents/`;
export const GET_BATCH_INCIDENT_DETAILS_URL = `${BASE_URL}/batchess/`;
export const SET_CREATE_INCIDENT_DETAILS_URL = `${BASE_URL}/incidents/`;
export const SET_UPDATE_INCIDENT_DETAILS_URL = `${BASE_URL}/incidents/`;
export const SET_UPDATE_INCIDENT_STATUS_URL = `${BASE_URL}/incidents/`;
export const GET_USER_LIST = `${BASE_URL}/users`;
export const GET_INCIDENT_DASHBOARD_LIST_URL = `${BASE_URL}/incidents/dashboard`;
