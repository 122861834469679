import * as bulkActionTypes from "../actions/bulkActionTypes";

const initialState = {
  bulkExecutionDetails: null,
  bulkExecutionList: null,
  setSelectedTab:null
};

const bulkReducer = (state = initialState, action) => {
  switch (action.type) {
    case bulkActionTypes.GET_BULKEXECUTION_LIST:
      return {
        ...state,
        bulkExecutionList: action.payload
    };
    case bulkActionTypes.GET_BULKEXECUTION_DETAIL:
      return {
        ...state,
        bulkExecutionDetails: action.payload
    };
    case bulkActionTypes.SET_SELECTED_TAB:
      return {
        ...state,
        setSelectedTab: action.payload
    };
    default:
      return state;
  }
};

export default bulkReducer;
