import * as timesheetActionTypes from "../actions/timesheetActionTypes";

const initialState = {
  timesheetList: null,
};

const timesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case timesheetActionTypes.SET_TIMESHEET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case timesheetActionTypes.GET_TIMESHEET_LIST:
      return {
        ...state,
        timesheetList: action.payload,
      };
    default:
      return state;
  }
};

export default timesheetReducer;
