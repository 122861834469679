import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "components/CustomButton/CustomButton.jsx";
import SubscriptionModal from "./SubscriptionModal.jsx";
import { getPrices } from "../../modules/billing/actions/billingActions";
class SubscriptionBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBanner: true,
      openModal: false,
    };
    this.closeBanner = this.closeBanner.bind(this);
    this.getCompleteDetails = this.getCompleteDetails.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  closeBanner = () => {
    this.setState({ openBanner: false });
  }

  getCompleteDetails = (completeDetails) => {
    this.setState({ openModal: false });
  }

  showModal = () => {
    this.setState({ openModal: true });
  };

  payNowBill = (hostedInvoiceURL) => {
    window.open(this.props.userProfile.subscription.hostedInvoiceURL);
  };

  hideModal = () => {
    this.setState({ openModal: false });
  };

  componentDidMount = () => {
    this.props.getPrices("month");
  }

  render() {
    let message;
    const { userProfile } = this.props;
    if (!this.props.paymentStatus.active) {
      message = 'Plan has been expired';
    } else {
      message = `Your Plan will expire in next ${this.props.paymentStatus.daysRemaining} Days`;
    }
    return (
      <React.Fragment>
        {((this.props.paymentStatus.showWarningBanner && this.state.openBanner) || (!this.props.paymentStatus.active)) && (
          <div className="subscription-banner">
            <div className="subscription-massage"><span>{message}</span>, please renew your Plan for best Service.</div>
            <div className="subscription-button">
              {userProfile && userProfile.subscription && userProfile.subscription.hostedInvoiceURL != null ?
                <Button className="btn btn-fill btn-save" onClick={this.payNowBill}>Pay Now</Button>
                : null}
              <i className="fa fa-close" onClick={this.closeBanner} />
            </div>
          </div>
        )}

        {this.state.openModal == true ?
          <SubscriptionModal
            getCompleteDetails={this.getCompleteDetails}
          >
          </SubscriptionModal>
          : null}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    userProfile: state.user.userProfile,
    paymentStatus: state.billing.paymentStatus,
    prices: state.billing.prices
  }
}


export default connect(mapStatetoProps, {
  getPrices
})(SubscriptionBanner);
