import axios from "../../../axios/axios";
import * as dataDictionaryActionTypes from "./dataDictionaryActionTypes";
import * as dataDictionaryConstant from "../constant/dataDictionaryConstant";
import {
    beginAjaxCall, endAjaxCall, updateApiResponse, createApiResponse, failedApiResponse,
    setAjaxCallStatus
} from "../../../actions/ajaxStatusActions";
import CommonUtil from '../../common/util/commonUtil'

export function setReducerInitMode() {
    return function (dispatch) {
        dispatch({
            type: dataDictionaryActionTypes.SET_REDUCER_INIT_MODE,
            payload: null,
        });
    };
}

export function setDataDictionaryDetails(dataDictionaryObj, actionMode) {
    if (CommonUtil.isCreateMode(actionMode)) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            axios.post(dataDictionaryConstant.SET_CREATE_DATA_DICTIONARY_URL,
                dataDictionaryObj).then(response => {
                    dispatch(setAjaxCallStatus(createApiResponse(response)));
                    dispatch(endAjaxCall());
                }).catch(error => {
                    dispatch(endAjaxCall());
                    dispatch(setAjaxCallStatus(failedApiResponse(error)));
                });
        };
    }

    if (CommonUtil.isEditMode(actionMode)) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            axios.put(dataDictionaryConstant.SET_CREATE_DATA_DICTIONARY_URL + "DATA-0001",
                dataDictionaryObj).then(response => {
                    dispatch(setAjaxCallStatus(updateApiResponse(response)));
                    dispatch(endAjaxCall());
                }).catch(error => {
                    dispatch(endAjaxCall());
                    dispatch(setAjaxCallStatus(failedApiResponse(error)));
                });
        };
    }
}

export function getDataDictionaryDetails(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(dataDictionaryConstant.GET_DATA_DICTIONARY_LIST_URL + "DATA-0001", { params: params }).then((response) => {
            if (response.status == 200) {
                dispatch({
                    type: dataDictionaryActionTypes.GET_DATA_DICTIONARY_DETAILS,
                    payload: response.data,
                });
            }
            dispatch(endAjaxCall());
        })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function getOrganizationDetails(selectedFacilityCode) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(dataDictionaryConstant.GET_ORGANIZATION_LIST_URL
            + selectedFacilityCode).then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: dataDictionaryActionTypes.GET_ORGANIZATION_DETAILS,
                        payload: response.data,
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function setOrganizationDetails(organizationObj, OrgId) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            axios.post(dataDictionaryConstant.SET_CREATE_ORGANIZATION_URL,
                organizationObj).then(response => {
                    dispatch(setAjaxCallStatus(updateApiResponse(response)));
                    dispatch(endAjaxCall());
                }).catch(error => {
                    dispatch(endAjaxCall());
                    dispatch(setAjaxCallStatus(failedApiResponse(error)));
                });
        };
}

export function getTierPricingDetails(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(dataDictionaryConstant.GET_TIERPRICING_LIST_URL, {params}).then((response) => {
            if (response.status == 200) {
                dispatch({
                    type: dataDictionaryActionTypes.GET_TIERPRICING_DETAILS,
                    payload: response.data,
                });
            }
            dispatch(endAjaxCall());
        })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function setTierPricingDetails(pricingObj) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.post(dataDictionaryConstant.SET_TIERPRICING_URL,
            pricingObj).then(response => {
                dispatch(setAjaxCallStatus(createApiResponse(response)));
                dispatch(endAjaxCall());
            }).catch(error => {
                dispatch(endAjaxCall());
                dispatch(setAjaxCallStatus(failedApiResponse(error)));
            });
    };
}

export function getUOMDetails() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(dataDictionaryConstant.GET_UOM_LIST_URL).then((response) => {
            if (response.status == 200) {
                dispatch({
                    type: dataDictionaryActionTypes.GET_UOM_DETAILS,
                    payload: response.data,
                });
            }
            dispatch(endAjaxCall());
        })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}