import * as actionTypes from "../actions/userActionsType";

const initialState = {
    authObject: null,
    changePasswordSuccess: false,
    changePasswordError: null,
    newPasswordRequired: false,
    newPasswordSucess: false,
    newPasswordError: null,
    sentCodeSuccess: false,
    sentCodeError: null,
    forgotPasswordError: null,
    forgotPasswordSuccess: false,
    tenantExists: false,
    uiComponentsPermissionMapping: {},
    message:null,
    shopifyDetail:null,
    soDetails:null,
    chargeCreditCardData:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COGNITO_AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                authObject: action.payload
            };
        }
        case actionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePasswordSuccess: true
            }
        }
        case actionTypes.CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                changePasswordError: action.payload,
                changePasswordSuccess: false
            }
        }
        case actionTypes.CLEAR_CHANGE_PASSWORD: {
            return {
                ...state,
                changePasswordError: null,
                changePasswordSuccess: false
            }
        }
        case actionTypes.NEW_PASSWORD_CHALLENGE: {
            return {
                ...state,
                newPasswordRequired: true,
                currentTempUser: action.payload
            }
        }
        case actionTypes.SET_NEW_PASSWORD_SUCCESS: {
            return {
                ...state,
                newPasswordSucess: true,
                newPasswordError: null
            }
        }
        case actionTypes.SET_NEW_PASSWORD_ERROR: {
            return {
                ...state,
                newPasswordError: action.payload,
                newPasswordSucess: false,
                newPasswordRequired: false
            }
        }
        case actionTypes.CLEAR_NEW_PASSWORD_MESSAGES: {
            return {
                ...state,
                newPasswordSucess: false,
                newPasswordError: null,
                newPasswordRequired: false
            }
        }
        case actionTypes.FORGOT_PASSWORD_CODE_SENT: {
            return {
                ...state,
                sentCodeSuccess: true,
                sentCodeError: null
            }
        }
        case actionTypes.FORGOT_PASSWORD_CODE_ERROR: {
            return {
                ...state,
                sentCodeError: action.payload,
                sentCodeSuccess: false
            }
        }
        case actionTypes.CLEAR_FORGOT_PASSWORD: {
            return {
                ...state,
                sentCodeError: null,
                sentCodeSuccess: false,
                forgotPasswordSuccess: false
            }
        }

        case actionTypes.FORGOT_PASSWORD_FLOW_SUCCESS: {
            return {
                ...state,
                forgotPasswordSuccess: true,
                forgotPasswordError: null
            }
        }

        case actionTypes.FORGOT_PASSWORD_FLOW_ERROR: {
            return {
                ...state,
                forgotPasswordError: action.payload,
                forgotPasswordSuccess: false
            }
        }

        case actionTypes.CHECK_IF_TENANT_EXISTS_ERROR: {
            return {
                ...state,
                tenantExists: true
            }
        }

        case actionTypes.CHECK_IF_TENANT_EXISTS: {
            return {
                ...state,
                tenantExists: false
            }
        }

        case actionTypes.GET_UI_COMPONENTS_PERMISSION_MAPPING: {
            return {
                ...state,
                uiComponentsPermissionMapping: action.payload,
            }
        }

        case actionTypes.GET_UI_COMPONENTS_PERMISSION_MAPPING_ERROR: {
            return {
                ...state,
                uiComponentsPermissionMapping: {}
            }
        }

        case actionTypes.GET_SHOPIFY_DETAILS: {
          return {
                ...state,
                shopifyDetail: action.payload,
            }
        }

        case actionTypes.GET_SO_DETAILS: {
            return {
                  ...state,
                  soDetails: action.payload,
              }
          }
        case actionTypes.CHARGE_CREDIT_CARD: {
            return {
                  ...state,
                  chargeCreditCardData: action.payload,
              }
          }

        default:
            return state;
    }
};