import axios from "../../../axios/axios";
import * as userActionTypes from "./userActionTypes";
import * as userConstant from "../constant/userConstant";
import CommonUtil from '../../common/util/commonUtil';
import {
    Auth
} from "aws-amplify";
import {
    beginAjaxCall,
    endAjaxCall,
    setAjaxCallStatus,
    updateApiResponse,
    createApiResponse,
    failedApiResponse
} from "../../../actions/ajaxStatusActions";

export function setReducerInitMode() {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.SET_REDUCER_INIT_MODE,
            payload: null,
        });
    };
}

export function setUserDetails(userDetailsObj, actionMode) {
    if (CommonUtil.isCreateOrCloneMode(actionMode)) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            return axios.post(userConstant.SET_CREATE_USER_DETAILS_URL, userDetailsObj)
                .then((response) => {
                    dispatch(setAjaxCallStatus(createApiResponse(response)));
                    dispatch(endAjaxCall());
                }).catch((error) => {
                    dispatch(endAjaxCall());
                    if (JSON.stringify(error).includes('401')) {
                        dispatch({
                            type: userActionTypes.USERS_EXHAUSTED
                        });
                    } else {
                        dispatch(setAjaxCallStatus(failedApiResponse(error)));
                    }
                });
        };
    }
    if (CommonUtil.isEditMode(actionMode)) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            axios.put(
                    userConstant.SET_UPDATE_USER_DETAILS_URL,
                    userDetailsObj
                )
                .then((response) => {
                    dispatch(setAjaxCallStatus(updateApiResponse(response)));
                    dispatch(endAjaxCall());
                })
                .catch((error) => {
                    dispatch(endAjaxCall());
                    dispatch(setAjaxCallStatus(failedApiResponse(error)));
                });
        };
    }
}

export function resendUserPassword(userDetailsObj,) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return axios.post(userConstant.RESEND_USER_PASSWORD_URL, userDetailsObj)
            .then((response) => {
                dispatch(setAjaxCallStatus(createApiResponse(response)));
                dispatch(endAjaxCall());
            }).catch((error) => {
                dispatch(endAjaxCall());
                dispatch(setAjaxCallStatus(failedApiResponse(error)));
            });
    };
}

export function setSelectedUserCode(selectedUserCode) {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.SET_SELECTED_USER_CODE,
            payload: selectedUserCode,
        });
    };
}

const userCreateProfile = async (userDetails) => {
    const result = await Auth.signUp({
        username: userDetails.email,
        password: userDetails.password,
        attributes: {
            email: userDetails.email,
        },
        clientMetadata: {
            username: userDetails.email,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            companyName: userDetails.companyName,
        },
    }).then();
    return result;
};

export function getUserList(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios
            .get(userConstant.GET_USER_LIST_URL, {
                params: params
            })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: userActionTypes.GET_USER_LIST,
                        payload: response.data,
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function getUserFacilityList(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios
            .get(userConstant.GET_USER_LIST_URL, {
                params: params
            })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: userActionTypes.GET_USER_FACILITY_LIST,
                        payload: response.data,
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function getUserFacilityNewList(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios
            .get(userConstant.GET_USER_LIST_NEW_URL, {
                params: params
            })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: userActionTypes.GET_USER_FACILITY_NEW_LIST,
                        payload: response.data,
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function getUserDetails(selectedUserCode) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios
            .get(userConstant.GET_USER_DETAILS_URL + selectedUserCode)
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: userActionTypes.GET_USER_DETAILS,
                        payload: response.data[0],
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(endAjaxCall());
                throw error;
            });
    };
}

export function updateUserStatus(userDetailsObj, email, setNewPassWordFlag) {
    if (setNewPassWordFlag) {
        userDetailsObj = {};
        userDetailsObj.requestFrom = "reset_password";
        userDetailsObj.status = "confirmed";
        userDetailsObj.email = email.challengeParam.userAttributes.email;

    }

    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios
            .patch(
                userConstant.SET_UPDATE_USER_STATUS_URL + "/" + userDetailsObj.email,
                userDetailsObj
            )
            .then((response) => {
                if (response.status == 200 && !setNewPassWordFlag) {

                    axios.get(userConstant.GET_USER_LIST_URL + '?source=manage&limit=50&page=1&pageIndex=1').then((response) => {
                        if (response.status == 200) {

                            dispatch({
                                type: userActionTypes.GET_USER_LIST,
                                payload: response.data,
                            });
                            dispatch(setAjaxCallStatus(updateApiResponse(response)));

                        }
                        dispatch(endAjaxCall());
                    });
                }
                dispatch(endAjaxCall());
            })
            .catch((error) => {
                dispatch(setAjaxCallStatus(failedApiResponse(error)));
                dispatch(endAjaxCall());
            });
    };
}

export function getUserProfile() {
    return dispatch => {
        dispatch(beginAjaxCall());
        axios.get(userConstant.USER_PROFILE).then(user => {
            dispatch({
                type: userActionTypes.GET_USER_PROFILE,
                payload: user.data[0]
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: userActionTypes.GET_USER_PROFILE_ERROR,
                payload: error
            });
            dispatch(endAjaxCall());
        })
    }
}

export function editUserProfile(updatedObject) {
    return dispatch => {
        dispatch(beginAjaxCall());
        axios.put(userConstant.USER_PROFILE, updatedObject).then(response => {
            dispatch({
                type: userActionTypes.UPDATE_USER_PROFILE,
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: userActionTypes.UPDATE_USER_PROFILE_ERROR,
                payload: error
            });
            dispatch(endAjaxCall());
        })
    }
}


export function uploadUserImage(documentObj, documentIdObj, userVersion) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.post(userConstant.UPLOAD_USER_IMAGE, documentObj).then(response => {
            if (response.status == 201) {
                documentIdObj.documentURL = response.data;
                const imageBody = {
                    profileImage: response.data,
                    version: userVersion
                };
                axios.put(userConstant.UPDATE_USER_PROFILE_IMAGE, imageBody).then(imageResponse => {
                    dispatch({
                        type: userActionTypes.SET_USER_PROFILE_URL,
                        payload: documentIdObj
                    });
                }).catch(imageError => {

                })

            } else {
                dispatch(setAjaxCallStatus(userActionTypes.SET_USER_PROFILE_URL_ERROR));
            }
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch(setAjaxCallStatus(userActionTypes.SET_USER_PROFILE_URL_ERROR));
            dispatch(endAjaxCall());
        });
    };
}

export function checkUserExist(email) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(`${userConstant.USER_CHECK_BASE_URL}/${email}`).then(response => {
            if (response.status == 200) {
                dispatch({
                    type: userActionTypes.CHECK_USER_EXIST,
                    payload: response.data.exists
                });
                dispatch(endAjaxCall());
            }
        }).catch(error => {

            dispatch(endAjaxCall());
        })

    }
}

export function getUserSearchList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(userConstant.GET_USER_SEARCH_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: userActionTypes.GET_USER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
      throw error;
    });
  };
}

export function setProfileDashboardDetails(dashboardObj) {
  //  if (CommonUtil.isCreateMode(actionMode)) {
        return function (dispatch) {
            dispatch(beginAjaxCall());
            axios.put(userConstant.SET_USER_DASHBOARD_URL,
                dashboardObj).then(response => {
                    dispatch(setAjaxCallStatus(createApiResponse(response)));
                    dispatch(endAjaxCall());
                }).catch(error => {
                    dispatch(endAjaxCall());
                    dispatch(setAjaxCallStatus(failedApiResponse(error)));
                });
        };
   // }
}