export const clickDrop = (e) => {
    const dropdown = e.target;
    var dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach(function(dropdown) {
        dropdown.classList.remove('open');
    });
    setTimeout(() => {
        dropdown.classList.toggle('open');
    }, 100);
    e.stopPropagation();
};