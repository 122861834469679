import CommonUtil from './commonUtil';
import PopupUtil from './popupUtil';

const PaginationUtil = {

  getPaginationParams: function (currentPage, limit, that, paginationFlag) {
    let lastEvaluatedKey = this.getLastEvaluatedKey(currentPage, that);
    let tempLimit = limit != null ? limit : that.state.tableConfig &&
      that.state.tableConfig.defaultPageSize;
    let tempParamas = {};
    if (that.state && that.state.additionalParams) {
      tempParamas = { ...that.state.additionalParams };
    }
    let tempcurrentPage = null;
    // if (lastEvaluatedKey) {
    if (paginationFlag) {
      tempcurrentPage = CommonUtil.isNaNValue(parseInt(currentPage)) + 1;
      tempParamas.lastEvaluatedKey = lastEvaluatedKey;
    } else {
      tempcurrentPage = 1
    }
    tempParamas.limit = tempLimit !== null ? tempLimit : 15;
    tempParamas.page = tempcurrentPage;
    tempParamas.pageIndex = tempcurrentPage;
    return tempParamas;
  },
  getProductPaginationParams: function (currentPage, limit, that) {
    var lastEvaluatedKey = this.getLastEvaluatedKey(currentPage, that);
    var tempLimit = limit != null ? limit : that.state.tableConfig &&
      that.tableConfig.state.defaultPageSize;
    var tempParamas = {};
    if (lastEvaluatedKey) {
      tempParamas.lastEvaluatedKey = lastEvaluatedKey;
    }
    tempParamas.limit = tempLimit;
    return tempParamas;
  },
  getInitPaginationParams: function () {
    let tempObj = {};
    tempObj.source = "manage";
    tempObj.limit = 15;
    tempObj.page = 1;
    tempObj.pageIndex = 1;
    return tempObj;
  },
  getOpenStatusParams: function () {
    var tempParamas = {};
    tempParamas.status = "open";
    tempParamas.limit = 15;
    return tempParamas;
  },
  initPaginationParams: function (that) {
    let source = that.state.additionalParams && that.state.additionalParams.source;
    let additionalParams = {};
    if (source == "manage") {
      additionalParams.source = "manage";
    }
    that.setState({
      tableDataList: [],
      additionalParams: additionalParams,
      nextClick: false,
      family: null,
      previousClick: false,
      nextClickDisable: true,
      selectedFilter: null,
      previousClickDisable: true,
      pageSize: 1,
      currentPage: 1,
      lastEvaluatedKeyArray: []
    });
  },
  getLastEvaluatedKey: function (currentPage, that) {
    var lastEvaluatedKey = null;
    for (var i = 0; i < that.state.lastEvaluatedKeyArray.length; i++) {
      if (that.state.lastEvaluatedKeyArray[i].currentPage == currentPage) {
        lastEvaluatedKey = JSON.stringify(that.state.lastEvaluatedKeyArray[i].lastEvaluatedKey).toString();
        break;
      }
    }
    return lastEvaluatedKey;
  },
  handlePagination: function (tableDataList, that) {
    if (tableDataList !== null) {
      var tempLastEvaluatedKeyArray = [];
      var nullFlag = false;
      for (var i = 0; i < that.state.lastEvaluatedKeyArray.length; i++) {
        if (that.state.lastEvaluatedKeyArray[i].currentPage !== that.state.currentPage) {
          tempLastEvaluatedKeyArray.push({ ...that.state.lastEvaluatedKeyArray[i] });
        }
        if (that.state.lastEvaluatedKeyArray[i].currentPage == null) {
          nullFlag = true;
        }
      }
      if (tableDataList.LastEvaluatedKey != undefined) {
        var tempObj = {}
        tempObj.currentPage = that.state.currentPage;
        tempObj.lastEvaluatedKey = tableDataList.LastEvaluatedKey;
        tempLastEvaluatedKeyArray.push({ ...tempObj });
        that.setState({ nextClickDisable: false });
      }
      else {
        if (!nullFlag) {
          var tempObj = {}
          tempObj.currentPage = null;
          tempLastEvaluatedKeyArray.push({ ...tempObj });
        }
        that.setState({ nextClickDisable: true });
      }

      that.setState({
        tableDataList: CommonUtil.getCurrentZoneDate(tableDataList),
        previousClick: false,
        nextClick: false,
        previousClickDisable: tempLastEvaluatedKeyArray.length > 0 && that.state.currentPage != 1 ? false : true,
        pageSize: tempLastEvaluatedKeyArray.length,
        lastEvaluatedKeyArray: [...tempLastEvaluatedKeyArray]
      });
    }
  },
  handleNextClick: async function (that) {
    await that.setState({
      nextClick: true,
      pageNumber: that.state.currentPage,
      currentPage: that.state.currentPage + 1
    });
    that.state.autoSave && !that.state.customPagination ? PopupUtil.popupSave(that) : that.makeCustomAPICall(this.getPaginationParams(that.state.currentPage - 1, null, that, true));
  },
  handlePreviousClick: async function (that) {
    await that.setState({
      previousClick: true,
      pageNumber: that.state.currentPage,
      currentPage: that.state.currentPage - 1
    });
    that.state.autoSave && !that.state.customPagination ? PopupUtil.popupSave(that) : that.makeCustomAPICall(this.getPaginationParams(that.state.currentPage - 1, null, that, true));
  },
  onPageChange: async function (currentPage, that) {
    if (!that.state.nextClick && !that.state.previousClick) {
      await that.setState({
        currentPage: currentPage + 1,
      });
      that.makeCustomAPICall(this.getPaginationParams(that.state.currentPage - 1, null, that, true));
    }
  },
  onPageSizeChange: async function (limit, that) {
    const { tableConfig } = that.state;
    await that.setState({
      pageSize: 1,
      currentPage: 1,
      lastEvaluatedKeyArray: [],
      tableConfig: {
        ...tableConfig,
        defaultPageSize: limit
      }
    });
    that.makeCustomAPICall(this.getPaginationParams(that.state.currentPage - 1, limit, that, true));
  },
};
export default PaginationUtil;