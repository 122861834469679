import * as documentActionTypes from "../actions/documentActionTypes";

const initialState = {
  documentDetails: null,
  documentDetailsList: null,
  selectedDocumentDetailsCode: null,
};

const documentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentActionTypes.SET_DOCUMENT_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case documentActionTypes.SET_DOCUMENT_DETAILS:
      return {
        ...state,
        documentDetails: action.payload
      };
    case documentActionTypes.GET_DOCUMENT_LIST:
      return {
        ...state,
        documentDetailsList: action.payload
      };
    case documentActionTypes.SET_SELECTED_DOCUMENT_CODE:
      return {
        ...state,
        selectedDocumentDetailsCode: action.payload
      };
    case documentActionTypes.GET_DOCUMENT_DETAILS:
      return {
        ...state,
        documentDetails: action.payload
      };
    default:
      return state;
  }
};

export default documentDetailsReducer;
