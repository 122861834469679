import * as ingredientActionTypes from "../actions/ingredientActionTypes";
import * as supplierActionTypes from "../../supplierManagement/actions/supplierActionTypes";

const initialState = {
  ingredientDetails: null,
  ingredientList: null,
  inactiveIngredientList: null,
  ingredientGrossList: null,
  selectedIngredientCode: null,
  bulkImportIngredientDetails: null,
  skuNumber: null,
  selectedCategoryCode: null,
};

const ingredientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ingredientActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case ingredientActionTypes.SET_SELECTED_INGREDIENT_DETAILS:
      return {
        ...state,
        selectedIngredientDetails: { ...action.payload.ingredientDetailsObj }
      };
    case ingredientActionTypes.SET_BULK_IMPORT_INGREDIENT_DETAILS:
      return {
        ...state,
        bulkImportIngredientDetails: action.payload
      };
    case ingredientActionTypes.GET_INGREDIENT_LIST:
      return {
        ...state,
        ingredientList: action.payload
      };
    case ingredientActionTypes.GET_INGREDIENT_DETAILS_LIST:
      return {
        ...state,
        ingredientDetailList: action.payload
      }
    case ingredientActionTypes.GET_INACTIVE_INGREDIENT_LIST:
      return {
        ...state,
        inactiveIngredientList: action.payload
      };
    case ingredientActionTypes.GET_INGREDIENT_GROSS_LIST:
      return {
        ...state,
        ingredientGrossList: action.payload
      };
    case ingredientActionTypes.SET_SELECTED_INGREDIENT_CODE:
      return {
        ...state,
        selectedIngredientCode: action.payload
      };
    case ingredientActionTypes.GET_INGREDIENT_DETAILS:
      return {
        ...state,
        ingredientDetails: action.payload
      };
    case supplierActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierDetails: action.payload
      };
    case ingredientActionTypes.GET_SKU_DETAILS:
      return {
        ...state,
        skuNumber: action.payload
      };
    case ingredientActionTypes.SET_SELECTED_CATEGORY_CODE:
      return {
        ...state,
        selectedCategoryCode: action.payload
      };
    default:
      return state;
  }
};

export default ingredientReducer;
