import React from "react";
import { connect } from "react-redux";
import { doLogout } from "actions/authActions";
import { setActionMode } from "actions/appActions";
import {
    NavDropdown, MenuItem, OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import CommonUtil from '../../modules/common/util/commonUtil';
import * as statusConstant from '../../modules/common/constant/statusConstant';
import avatar from "assets/img/avatar.jpg";
import { clickDrop } from "constants/dropdownevent";

const UserProfile = (props) => {
    let userAvatar
    const profileImage = props.userProfile.profileImage;
    const firstName = props.userProfile.firstName;
    const lastName = props.userProfile.lastName;
    const email = props.userProfile.email;
    const status = props.userProfile.status;
    let initials;
    if (firstName && lastName) {
        initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }
    if (status != null && status.toLowerCase() === statusConstant.USER_INACTIVE_STATUS) {
        props.setActionMode(statusConstant.USER_INACTIVE_STATUS);
        props.doLogout();
    }

    let userProfileIcon
    if (profileImage) {
        userProfileIcon = <img src={profileImage} alt="Avatar" />
    } else {
        userProfileIcon = <span>{initials}</span>
    }

    const overlayTrigger = (<OverlayTrigger
        placement="left"
        overlay={
            <Tooltip id="userprofile" className="userprofile">
                <div>
                    {firstName} {lastName}
                </div>
                <div>
                    {email}
                </div>
            </Tooltip>
        }
    >
        {userProfileIcon}
    </OverlayTrigger>);
    return (
        <React.Fragment>
            <NavDropdown
                key={11}
                title={
                    <div className="user">
                        <div className="photo">
                            {overlayTrigger}
                        </div>
                        <p className="hidden-md hidden-lg">
                            {`${props.userProfile ? props.userProfile.firstName : ""} ${props.userProfile ? props.userProfile.lastName : ""}`}
                            <b className="caret" />
                        </p>
                    </div>
                }
                noCaret
                id="basic-nav-dropdown-4"
                onClick={clickDrop}
            >
                <MenuItem>
                    <NavLink key="My Profile" to="/admin/my-profile">
                        My Profile
                    </NavLink>
                </MenuItem>
                <MenuItem>
                    <div onClick={props.doLogout}>
                        Log out
                    </div>
                </MenuItem>
            </NavDropdown>
            {/*<NavDropdown
                key={12}
                title={
                    <div>
                        <i className="fa fa-ellipsis-v" />
                        <p className="hidden-md hidden-lg">
                            More
                            <b className="caret" />
                        </p>
                    </div>
                }
                noCaret
                id="basic-nav-dropdown-5"
                bsClass="dropdown-with-icons dropdown">
                <MenuItem key={11.1}>
                    <i className="pe-7s-mail" /> Messages
                </MenuItem>
                <MenuItem key={11.2}>
                    <i className="pe-7s-help1" /> Help Center
                </MenuItem>
                <MenuItem key={11.3}>
                    <i className="pe-7s-tools" /> Settings
                </MenuItem>
                <MenuItem key={11.4}>
                    <i className="pe-7s-lock" /> Lock Screen
                </MenuItem>
                <MenuItem key={11.5}>
                    <div className="text-danger" onClick={props.doLogout}>
                        <i className="pe-7s-close-circle" /> Log out
                    </div>
                </MenuItem>
            </NavDropdown>*/}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.authReducer.userInfo,
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    doLogout: () => dispatch(doLogout()),
    setActionMode: actionMode => dispatch(setActionMode(actionMode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
