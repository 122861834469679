import * as saleTaxActionTypes from "../actions/saleTaxActionTypes";

const initialState = {
  saleTaxDetails: null,
  saleTaxList: null,
  selectedTaxCode: null,
};

const saleTaxReducer = (state = initialState, action) => {
  switch (action.type) {
    case saleTaxActionTypes.SET_SALETAX_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case saleTaxActionTypes.GET_SALETAX_DETAILS:
      return {
        ...state,
        saleTaxDetails: action.payload,
      };
    case saleTaxActionTypes.GET_SALETAX_LIST:
      return {
        ...state,
        saleTaxList: action.payload,
      };
    case saleTaxActionTypes.SET_SELECTED_SALETAX_CODE:
      return {
        ...state,
        selectedTaxCode: action.payload
      };
    case saleTaxActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    default:
      return state;
  }
};

export default saleTaxReducer;
