import React, { Component } from "react";
import { Grid, Row, Col, PanelGroup, Panel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { connect } from "react-redux";
import { setAjaxCallStatus } from "../../actions/ajaxStatusActions";
import CommonUtil from "../../modules/common/util/commonUtil";
import PopupUtil from "../../modules/common/util/popupUtil";
import * as Common from "./common.jsx";
import mostpopular from "assets/img/most-popular.svg";
import getSymbolFromCurrency from 'currency-symbol-map';
import { checkoutPlans } from "../../modules/billing/actions/billingActions";
import { getPrices } from "../../modules/billing/actions/billingActions";

var Modal = require('react-bootstrap-modal');

class SubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      alert: null,
      duration: 'month',
    };
    this.handlePopupCancel = this.handlePopupCancel.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.handleAjaxResponse = this.handleAjaxResponse.bind(this);
  }

  componentDidMount = () => {
    this.setState({ openModal: true });
    this.checkIfOnlyOneActivePlan();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ajaxCallStatus != null && prevProps.ajaxCallStatus != this.props.ajaxCallStatus) {
      this.handleAjaxResponse();
    }
  }

  checkIfOnlyOneActivePlan = () => {
    const { prices } = this.props;
    if (CommonUtil.isNotNull(prices) && prices.length == 1 && prices[0].isActivePrice == true) {
      this.handlePopupClose(prices[0].id);
    }
  }

  handleAjaxResponse() {
    if (this.props.ajaxCallStatus.status == "SUCCESS") {
      this.props.setAjaxCallStatus(null);
      this.props.handleClick(CommonUtil.prepareUpdateSuccessPopUpConfig());
    }
    if (this.props.ajaxCallStatus.status == "FAILED") {
      PopupUtil.popupErrorResponse(this);
      this.props.setAjaxCallStatus(null);
    }
  }

  handlePopupCancel() {
    this.props.getCompleteDetails(true);
    this.setState({ openModal: false, alert: null });
  }

  handlePopupClose(priceId) {
    this.props.checkoutPlans(priceId);
    this.setState({ openModal: false, alert: null });
  }

  handleCustomSelection = (options) => {
    this.setState({ duration: options });
    // this.props.getPrices(options);
  }

  render() {
    const { duration } = this.state;
    const prices = this.props.prices;
    return (
      <div>
        <Modal show={this.state.openModal}
          onHide={this.handlePopupCancel} aria-labelledby="ModalHeader" className="subscription-modal" backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="main-content">
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <form>
                      <Card
                        content={
                          <div>
                            <div className="header-section">
                              <div className="heading">Subscribe to a Plan</div>
                              <div className="subheading">Choose the plan that's right for your business. save money with yearly plans.</div>
                            </div>

                            <div className="premium-section">
                              <div className="premium">
                                <div className="details">
                                  <div className="heading">Premium <span>PACKAGE</span></div>
                                  <p>Connect with us to avail this plan</p>
                                </div>
                                <div className="contact">
                                  <span>Call : +61 4567 235 983 </span>
                                  <span>email : info@originscale.io</span>
                                </div>
                              </div>
                            </div>

                            <div className="billing-period">
                              <div className="heading">Choose your billing period</div>
                              {/* <ul className="billing-time">
                                <li className={duration == "month" ? 'active' :''} onClick={() => this.handleCustomSelection('month')}>Monthly</li>
                                <li className={duration == "year" ? 'active' :''} onClick={() => this.handleCustomSelection('year')}>Yearly -18%</li>
                                <li className={duration == "all" ? 'active' :''} onClick={() => this.handleCustomSelection('all')}>All</li>
                              </ul> */}
                            </div>

                            <div className="subscription-modal-content">
                              {prices.map((plan) => {
                                let unitPrice;
                                const currencyCode = plan.currency.toUpperCase();
                                const currencySymbol = getSymbolFromCurrency(currencyCode);
                                const unitSymbol = currencySymbol ? currencySymbol : currencyCode;
                                if (plan.type === "recurring") {
                                  unitPrice = ` ${unitSymbol}${plan.unit_amount / 100}/${plan.recurring.interval}`;
                                } else {
                                  unitPrice = `${plan.unit_amount / 100}`;
                                }
                                let image = "";
                                if (plan.productDetails.metadata.image === "mostpopular") {
                                  image = mostpopular;
                                }
                                if (plan.isActivePrice == true) {
                                  return;
                                }
                                const button = "Select";
                                return (
                                  <div className="subscription-plans-details">
                                    <img src={image} alt="" className="most-popular" />
                                    <div className={`subscription-plans ${plan.productDetails.metadata.className}`}>
                                      <div className="plan-title">{plan.productDetails.name}<span>PACKAGE</span></div>
                                      <div className="plan-price"><span></span>{unitPrice}</div>
                                      <Button onClick={() => this.handlePopupClose(plan.id)}>{button}</Button>
                                    </div>
                                    <div className="features">
                                      <div className="heading">{plan.featuretitle}</div>
                                      <ul>
                                        {plan.productDetails.metadata.features && plan.productDetails.metadata.features.split(";").map((item) => (
                                          <li>- {item}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })
                              }
                            </div>

                            <div className="faq-section">
                              <div className="heading">Frequently Asked Questions</div>
                              <div className="accordion-section">
                                <div className="left">
                                  {Common.faqLeft.map((faq, index) => (
                                    <PanelGroup
                                      id="faq-panels"
                                      ref="faq-panels"
                                      onClick={() => this.forceUpdate()}
                                    >
                                      <Panel eventKey={index}>
                                        <Panel.Heading>
                                          <Panel.Title toggle>- {faq.question}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                          {faq.answer}
                                        </Panel.Body>
                                      </Panel>
                                    </PanelGroup>
                                  ))}
                                </div>
                                <div className="right">
                                  {Common.faqRight.map((faq, index) => (
                                    <PanelGroup
                                      accordion
                                      id="faq-panels"
                                      ref="faq-panels"
                                      onClick={() => this.forceUpdate()}
                                    >
                                      <Panel eventKey={index}>
                                        <Panel.Heading>
                                          <Panel.Title toggle>- {faq.question}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                          {faq.answer}
                                        </Panel.Body>
                                      </Panel>
                                    </PanelGroup>
                                  ))}
                                </div>
                              </div>
                            </div>

                          </div>
                        }
                      />
                    </form>
                  </Col>
                </Row>
              </Grid>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ajaxCallStatus: state.ajaxStatusReducer.ajaxCallStatus,
    prices: state.billing.prices
  };
}

const mapDispatchToProps = (dispatch) => ({
  setAjaxCallStatus: (ajaxCallStatus) => dispatch(setAjaxCallStatus(ajaxCallStatus)),
  checkoutPlans: (priceId) => dispatch(checkoutPlans(priceId)),
  getPrices: (interval) => dispatch(getPrices(interval))
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);
