import * as automationActionTypes from "../actions/automationActionTypes";

const initialState = {
  automationDetails: null,
  automationList: null,
  automationFacilityList: null,
  automationDraftList: null,
  automationProductRuleList: null,
  selectedAutomationCode: null,
};

const automationReducer = (state = initialState, action) => {
  switch (action.type) {
    case automationActionTypes.SET_AUTOMATION_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case automationActionTypes.SET_AUTOMATION_DETAILS:
      return {
        ...state,
        automationDetails: action.payload,
      };
    case automationActionTypes.GET_AUTOMATION_LIST:
      return {
        ...state,
        automationList: action.payload,
      };
    case automationActionTypes.GET_AUTOMATION_FACILITY_LIST:
      return {
        ...state,
        automationFacilityList: action.payload,
      };
    case automationActionTypes.GET_AUTOMATION_DRAFT_ORDER_LIST:
      return {
        ...state,
        automationDraftList: action.payload,
      };
    case automationActionTypes.GET_AUTOMATION_PRODUCT_RULES:
      return {
        ...state,
        automationProductRuleList: action.payload,
      };
    case automationActionTypes.SET_SELECTED_AUTOMATION_CODE:
      return {
        ...state,
        selectedAutomationCode: action.payload,
      };
    case automationActionTypes.GET_AUTOMATION_DETAILS:
      return {
        ...state,
        automationDetails: action.payload,
      };

    default:
      return state;
  }
};

export default automationReducer;
