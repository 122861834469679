export const SET_SELECTED_BATCH_CODE = "SET_SELECTED_BATCH_CODE";
export const SET_BATCH_DETAILS = "SET_BATCH_DETAILS";

export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";
export const GET_BATCH_LIST = "GET_BATCH_LIST";
export const GET_PRODUCT_BATCH_LIST = "GET_PRODUCT_BATCH_LIST";
export const GET_BATCH_DETAILS = "GET_BATCH_DETAILS";
export const GET_GENERATED_BATCH_CODE = "GET_GENERATED_BATCH_CODE";
export const SET_UPLOADED_FILE_URL  = "SET_UPLOADED_FILE_URL";


export const GET_INCOMPLETE_BATCH_LIST = "GET_INCOMPLETE_BATCH_LIST";
export const GET_INCOMPLETE_BATCH_COUNT = "GET_INCOMPLETE_BATCH_COUNT";

export const GET_BATCH_WITHOUT_QR_LIST = "GET_BATCH_WITHOUT_QR_LIST";
export const GET_BATCH_WITHOUT_QR_COUNT = "GET_BATCH_WITHOUT_QR_COUNT";

export const GET_BATCH_WITHOUT_CMS_LIST = "GET_BATCH_WITHOUT_CMS_LIST";
export const GET_BATCH_WITHOUT_CMS_COUNT = "GET_BATCH_WITHOUT_CMS_COUNT";