export const SET_SELECTED_MFG_ORDER_CODE = "SET_SELECTED_MFG_ORDER_CODE";
export const SET_MFG_ORDER_DETAILS = "SET_MFG_ORDER_DETAILS";
export const SET_MFG_ORDER_REDUCER_INIT_MODE = "SET_MFG_ORDER_REDUCER_INIT_MODE";
export const GET_MFG_ORDER_LIST = "GET_MFG_ORDER_LIST";
export const GET_MFG_ORDER_DETAILS = "GET_MFG_ORDER_DETAILS";
export const GET_MFG_BATCH_DETAILS = "GET_MFG_BATCH_DETAILS";
export const GET_MFG_ORDER_PRIORITY_DETAILS = "GET_MFG_ORDER_PRIORITY_DETAILS";
export const SET_INGREDIENT_STATUS_LIST = "SET_INGREDIENT_STATUS_LIST";
export const GET_RECEIVED_STOCK_LIST = "GET_RECEIVED_STOCK_LIST";
export const GET_MFG_ORDER_REPORT_LIST ="GET_MFG_ORDER_REPORT_LIST";
export const GET_MFG_OTIF_DASHBOARD_LIST ="GET_MFG_OTIF_DASHBOARD_LIST";
export const GET_MFG_ORDER_COST_REPORT_LIST ="GET_MFG_ORDER_COST_REPORT_LIST";
export const GET_MFG_ORDER_RESOURCE_VIEW_DATA = 'GET_MFG_ORDER_RESOURCE_VIEW_DATA';
export const GET_MFG_ORDER_RESOURCE_VIEW_DATA_ERROR = 'GET_MFG_ORDER_RESOURCE_VIEW_DATA_ERROR';

export const GET_MFG_ORDER_ORDER_VIEW_DATA = 'GET_MFG_ORDER_ORDER_VIEW_DATA';
export const GET_MFG_ORDER_ORDER_VIEW_DATA_ERROR = 'GET_MFG_ORDER_ORDER_VIEW_DATA_ERROR';
export const UPDATE_OPERATION_STATUS = "UPDATE_OPERATION_STATUS";
export const UPDATE_OPERATION_STATUS_ERROR = "UPDATE_OPERATION_STATUS_ERROR";
export const GET_ETA = 'GET_ETA';
export const GET_ETA_ERROR = 'GET_ETA_ERROR';