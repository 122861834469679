export const IN_STOCK_STATUS = "instock";
export const IN_STOCK_LABEL = "In Stock";

export const IN_PRODUCTION_STATUS = "inproduction";
export const IN_PRODUCTION_LABEL = "In Production";

export const NOT_AVAILABLE_STATUS = "notavailable";
export const NOT_AVAILABLE_LABEL = "Not Available";

export const NOT_SHIPPED_STATUS = "notshipped";
export const NOT_SHIPPED_LABEL = "Not Shipped";

export const UPDATE_STATUS = "updateStatus";
export const UPDATE_STATUS_LABEL = "Update Status";

export const PACKED_STATUS = "packed";
export const PACKED_LABEL = "Packed";

export const SHIP_STATUS = "shipping";
export const SHIP_LABEL = "Shipping";

export const SHIPPED_STATUS = "shipped";
export const SHIPPED_LABEL = "Shipped";

export const DISCARD_STATUS = "discard";
export const DISCARD_LABEL = "Discard";

export const COLLECT_STATUS = "pick-up";
export const COLLECT_LABEL = "Pick-up";

export const DELIVERY_STATUS = "delivery";
export const DELIVERY_LABEL = "Delivery";

export const DELIVERED_STATUS = "delivered";
export const DELIVERED_LABEL = "Delivered";

export const ACTIVE_STATUS = "active";
export const ACTIVE_LABEL = "Active";

export const INACTIVE_STATUS = "inactive";
export const INACTIVE_LABEL = "Inactive";

export const IN_PROGRESS_STATUS = "inprogress";
export const IN_PROGRESS_LABEL = "In Progress";


export const ACCEPTED_STATUS = "accepted";
export const ACCEPTED_LABEL = "Accepted";

export const DECLINED_STATUS = "declined";
export const DECLINED_LABEL = "Declined";

export const SENT_FOR_REVIEW_STATUS = "sentforreview";
export const SENT_FOR_REVIEW_LABEL = "Sent For Review";

export const OPEN_STATUS = "open";
export const OPEN_LABEL = "Open";

export const NEW_STATUS = "new";
export const NEW_LABEL = "New";

export const ALL_STATUS = "all";
export const ALL_LABEL = "All";

export const CLOSED_STATUS = "closed";
export const CLOSED_LABEL = "Closed";

export const DELETE_STATUS = "deleted";
export const DELETE_LABEL = "Deleted";

export const NOT_RECEIVED = "notreceived";
export const NOT_RECEIVED_LABEL = "Not Received";

export const PARTIAL_RECEIVED = "partialreceived";
export const PARTIAL_RECEIVED_LABEL = "Partial Received";

export const RECEIVED = "received";
export const RECEIVED_LABEL = "Receive All";

export const RECALL_STATUS = "recall";
export const RECALL_LABEL = "Recall";

export const WITHDRAW_STATUS = "withdraw";
export const WITHDRAW_LABEL = "Withdraw";

export const NOT_STARTED_STATUS = "notstarted";
export const NOT_STARTED_LABEL = "Not Started";

export const COMPLETED_STATUS = "completed";
export const COMPLETED_LABEL = "Completed";

export const DONE_STATUS = "done";
export const DONE_LABEL = "Done";

export const BLOCKED_STATUS = "blocked";
export const BLOCKED_LABEL = "Blocked";

export const FAIL_STATUS = "failed";
export const FAIL_LABEL = "Failed";

export const PASS_STATUS = "pass";
export const PASS_LABEL = "pass";


export const CREATE_STATUS = "created";
export const CREATE_LABEL = "Created";

export const PENDING_STATUS = "pending";
export const PENDING_LABEL = "Pending";

export const PARTIALLY_PAID_STATUS = "partiallypaid";
export const PARTIALLY_PAID_LABEL = "Partially Paid";

export const PAID_STATUS = "paid";
export const PAID_LABEL = "Paid";

export const UNPAID_STATUS = "unpaid";
export const UNPAID_LABEL = "Unpaid";

export const GENERATED_STATUS = "generated";
export const GENERATED_LABEL = "Generated";

export const EXPIRE_STATUS = "expire";
export const EXPIRE_LABEL = "Expired";

export const SOLD_OUT_STATUS = "soldout";
export const SOLD_OUT_LABEL = "Sold Out";

export const PARTIALLY_SOLD_STATUS = "partialsold";
export const PARTIALLY_SOLD_LABEL = "Partially Sold";

export const PARTIAL_DELIVERED_STATUS = "partialdelivered";
export const PARTIAL_DELIVERED_LABEL = "Partial Delivered";

export const NOT_SOLD_STATUS = "notsold";
export const NOT_SOLD_LABEL = "Not Sold";

export const CREATE_USER_STATUS = "createUser";

export const CONFIRMED_STATUS = "confirmed";
export const CONFIRMED_LABEL = "Confirmed";

export const UNCONFIRMED_STATUS = "unconfirmed";
export const UNCONFIRMED_LABEL = "Unconfirmed";

export const OVERDUE_STATUS = "overdue";
export const OVERDUE_LABEL = "OverDue";

export const USER_INACTIVE_STATUS = "inactive";
export const USER_UNCONFIRMED_STATUS = "UNCONFIRMED";
export const USER_CONFIRMED_STATUS = "CONFIRMED";

export const DRAFT_STATUS = "draft";
export const DRAFT_LABEL = "Draft";

export const CREATE_BILLS_STATUS = "createdbill";
export const CREATE_BILLS_LABEL = "View Bill";

export const PENDING_BILLS_STATUS = "pendingbill";
export const PENDING_BILLS_LABEL = "Create Bill";

export const CREATE_INVOICE_STATUS = "createdinvoice";
export const CREATE_INVOICE_LABEL = "View Invoice";

export const PENDING_INVOICE_STATUS = "pendinginvoice";
export const PENDING_INVOICE_LABEL = "Create Invoice";

export const NOTIFY_STATUS = "notified";
export const NOTIFY_LABEL = "Notified";

export const LIQUIDATE_STATUS = "liquidate";
export const LIQUIDATE_LABEL = "Liquidate";

export const PICKING_STATUS = "picking";
export const PICKING_LABEL = "Picking";


export const SO_RETURN_STATUS = "soreturn";
export const SO_RETURN_LABEL = "SO Return";
export const PO_RETURN_STATUS = "poreturn";
export const PO_RETURN_LABEL = "PO Return";
export const CUSTOMER_CREDIT_STATUS = "customercredit";
export const CUSTOMER_CREDIT_LABEL = "Customer Credit";
export const SUPPLIER_CREDIT_STATUS = "suppliercredit";
export const SUPPLIER_CREDIT_LABEL = "Supplier Credit";


export const CUSTOMER_RETURN_STATUS = "customerreturn";
export const CUSTOMER_RETURN_LABEL = "Customer Item Return";
export const SUPPLIER_RETURN_STATUS = "supplierreturn";
export const SUPPLIER_RETURN_LABEL = "Supplier Item Return";