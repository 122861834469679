import * as actionTypes from "../actions/actionTypes";

const initialState = {
  actionMode: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_ACTION_MODE: 
      return {
        ...state,
        actionMode: action.payload
      };
    default: {
      return state;
    }
  }
};
