import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { connect } from "react-redux";
import { doLogout } from "actions/authActions";
import intersection from "lodash/intersection";
import map from "lodash/map";
import UserProfile from "../Topbar/UserProfile";
import Notifications from "../Topbar/Notifications";
// import HelpSection from "../Topbar/HelpSection";
import NewItem from "../Topbar/NewItem";
// import InSights from "../Topbar/InSights";
import { topPanel } from "../../constants/GlobalConstants";
import * as saleOrderConstant from '../../modules/saleOrderAMS/constant/saleOrderConstant';
import isAuthorized from "auth-plugin";
// import { Collapse } from "react-bootstrap";

import getRoutes from "routes.js";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(getRoutes({track: props.track})),
      openAvatar: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the Topbar (top menu)
  createLinks = (routes) => {
    const handleMenuItemClick = (path) => {
      this.props.history.push(path);
    };

    return routes.map((prop, key) => {
      const availablePostions = prop.positions || [];
      const isRightPanel = intersection([topPanel], availablePostions).length > 0;
      if (prop.redirect || !isAuthorized(prop.id) || !isRightPanel) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        // if (prop.path === '/manage-sale-order' && (isAuthorized('QuotationView') || isAuthorized('QuotationUpdate'))) {
        //   return (
        //     <li
        //       className={`navbar-item ${this.getCollapseInitialState(prop.views) ? "active" : ""}`}
        //       key={key}
        //     >
        //       <Nav>
        //         <div className="sell-btn">
        //           <NavDropdown id="sell" title={<div className="sell-dropdown"><img src={prop.icon} alt={prop.name} /><p>Sell</p></div>} noCaret>
        //             <MenuItem id="1" onClick={() => handleMenuItemClick(saleOrderConstant.QUOTATION_PAGE_URL)}>Quotation</MenuItem>
        //             <MenuItem id="2" onClick={() => handleMenuItemClick(prop.layout + prop.path)}>Sell</MenuItem>
        //           </NavDropdown>
        //         </div>
        //       </Nav>
        //     </li>
        //   );
        // } else {
          return (
            <li
              className={`navbar-item ${this.getCollapseInitialState(prop.views) ? "active" : ""}`}
              key={key}
            >
              <NavLink
                key={prop.name}
                to={prop.layout + prop.path}>
                <img src={prop.icon} alt={prop.name} />
                <p>{prop.topPanelName}</p>
              </NavLink>
            </li>
          );
        // }
      }
      return (
        <li className={`navbar-item ${this.activeRoute(prop.layout + prop.path)}`} key={prop.name}>
          <NavLink
            key={prop.name}
            to={prop.layout + prop.path}>
            <img src={prop.icon} alt={prop.name} />
            <p>{prop.topPanelName}</p>
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  getPermissions = (userRoles) => {
    const roles = map(userRoles, (role) => {
      return role.role;
    });
    return roles;
  };

  render() {
    const track = this.props.track;
    return (
      <div>
        <Nav pullRight>
          {this.createLinks(getRoutes({track}))}
          {/*<InSights />*/}
          <NewItem />
          <Notifications />
          {/*<HelpSection />*/}
          <UserProfile />
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authReducer.userInfo,
    permissionMapping: state.security.uiComponentsPermissionMapping,
    track: state.dataDictionary.track,
  };
};

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderLinks));
