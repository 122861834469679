import * as operationActionTypes from "../actions/operationActionTypes";

const initialState = {
  operationDetails: null,
  operationList: null,
  operationId: null,
  operationActionMode: null,
};

const operationReducer = (state = initialState, action) => {
  switch (action.type) {
    case operationActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case operationActionTypes.SET_SELECTED_OPERATION_DETAILS:
      return {
        ...state,
        selectedOperationDetails: { ...action.payload.operationDetailsObj }
      };
    case operationActionTypes.GET_OPERATION_LIST:
      return {
        ...state,
        operationList: action.payload
      };
    case operationActionTypes.SET_SELECTED_OPERATION_CODE:
      return {
        ...state,
        operationId: action.payload
      };
    case operationActionTypes.SET_OPERATION_ACTION_MODE:
      return {
        ...state,
        operationActionMode: action.payload
      }
    case operationActionTypes.GET_OPERATION_DETAILS:
      return {
        ...state,
        operationDetails: action.payload
      };
    default:
      return state;
  }
};

export default operationReducer;
