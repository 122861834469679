import * as quotationOrderActionTypes from "../actions/quotationOrderActionTypes";

const initialState = {
  attributeObj: null,
  saleOrderDetails: null,
  quotationOrderList: null,
  selectedQuotationOrderCode: null,
  productStatusList: null,
  saleOrderStockList: null,
  topProductList: null,
  saleOrderCount: null,
  saleOTIFList: null,
  inventoryCoverageList: null,
  saleChannelCount: [],
  saleChannelCost: [],
  saleProfitOverTimeList: null,
  saleOrderPriorityDetails: null,
  shopifyCustomerDetails: null,
  qboInvoiceDetail: null,
  saleOrderPackingList: null,
  saleOrderRefDetails: null,
  saleOrderPaymentDetails: null,
  cartDetails: null,
  quotationOrder:null,
  selectedFacilityId:null,
};

const quotationOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case quotationOrderActionTypes.SET_SALE_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case quotationOrderActionTypes.SET_SALE_ORDER_DETAILS:
      return {
        ...state,
        saleOrderDetails: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_STOCK_LIST:
      return {
        ...state,
        saleOrderStockList: action.payload,
      };
    case quotationOrderActionTypes.GET_QUOTATION_ORDER_LIST:
      return {
        ...state,
        quotationOrderList: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_PACKING_LIST:
      return {
        ...state,
        saleOrderPackingList: action.payload,
      };
    case quotationOrderActionTypes.SET_PRODUCT_STATUS_LIST:
      return {
        ...state,
        productStatusList: action.payload,
      };
    case quotationOrderActionTypes.SET_SELECTED_QUOTATION_ORDER_CODE:
      return {
        ...state,
        selectedQuotationOrderCode: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_DETAILS:
      return {
        ...state,
        saleOrderDetails: action.payload,
        attributeObj: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_REFERENCE_DETAILS:
      return {
        ...state,
        saleOrderRefDetails: action.payload,
      };
    case quotationOrderActionTypes.GET_SHOPIFY_CUSTOMER_DETAILS:
      return {
        ...state,
        shopifyCustomerList: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_PRIORITY_DETAILS:
      return {
        ...state,
        saleOrderPriorityDetails: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_DASHBOARD_LIST_URL:
      return {
        ...state,
        topProductList: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        saleOrderCount: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_OTIF_DASHBOARD_LIST:
      return {
        ...state,
        saleOTIFList: action.payload,
      };
    case quotationOrderActionTypes.GET_INVENTORY_COVERAGE_DASHBOARD_LIST:
      return {
        ...state,
        inventoryCoverageList: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        saleChannelCount: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_CHANNEL_COST_DASHBOARD_LIST:
      return {
        ...state,
        saleChannelCost: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_PROFIT_DASHBOARD_LIST:
      return {
        ...state,
        saleProfitOverTimeList: action.payload,
      };
    case quotationOrderActionTypes.GET_QBO_INVOICE_DETAILS:
      return {
        ...state,
        qboInvoiceDetail: action.payload,
      };
    case quotationOrderActionTypes.GET_SALE_ORDER_PAYMENT_DETAILS:
      return {
        ...state,
        saleOrderPaymentDetails: action.payload,
      };
    case quotationOrderActionTypes.GET_CART_DETAILS:
      return {
        ...state,
        cartDetails: action.payload,
      };
    case quotationOrderActionTypes.GET_DETAILS:
      return {
        ...state,
        attributeObj: action.payload,
      };
    case quotationOrderActionTypes.SET_SELECTED_FACILITY_ID:
    return {
      ...state,
      selectedFacilityId: action.payload
    };
    default:
      return state;
  }
};

export default quotationOrderReducer;
