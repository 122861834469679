import * as incidentActionTypes from "../actions/incidentActionTypes";

const initialState = {
  incidentDetails: null,
  incidentList: null,
  selectedIncidentCode: null,
  selectedBatchCode:null,
  notificationList: null,
  incidentCount:null,
  incidentReportList:null,
};

const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentActionTypes.SET_INCIDENT_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case incidentActionTypes.SET_INCIDENT_DETAILS:
      return {
        ...state,
        incidentDetails: action.payload
      };
    case incidentActionTypes.GET_NOTIFICATION_INCIDENT_LIST:
      return {
        ...state,
        notificationList: action.payload
      };
    case incidentActionTypes.GET_INCIDENT_LIST:
      return {
        ...state,
        incidentList: action.payload
      };
    case incidentActionTypes.SET_SELECTED_INCIDENT_CODE:
      return {
        ...state,
        selectedIncidentCode: action.payload
      };

    case incidentActionTypes.SET_SELECTED_BATCH_CODE:
      return {
        ...state,
        selectedBatchCode: action.payload
      };  
      
    case incidentActionTypes.GET_INCIDENT_DETAILS:
      return {
        ...state,
        incidentDetails: action.payload
      };
    case incidentActionTypes.GET_INCIDENT_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        incidentCount: action.payload
      };
    case incidentActionTypes.GET_INCIDENT_REPORT_DASHBOARD_LIST:
      return {
        ...state,
        incidentReportList: action.payload
      };  
    default:
      return state;
  }
};

export default incidentReducer;
