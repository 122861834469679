import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/pe-icon-7-stroke.css";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import { Provider } from "react-redux";
import store from "store/store";
// import Amplify from "aws-amplify";
import { createRoot } from 'react-dom/client';

import config from "./modules/security/constants/config";
import axios from "./axios/axios";
import { Auth, Amplify } from 'aws-amplify';
import { ThemeProvider, createTheme } from "@mui/material";

axios.interceptors.request.use(request => {
  return Auth.currentSession().then(session => {
    request.headers.authorization = session.getIdToken().getJwtToken();
    return request;
  });
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

if (localStorage.getItem('uiToken')) {
  const uiToken = jwt.decode(localStorage.getItem('uiToken'), {
    complete: true
  });
  store.dispatch({ type: "LOGIN_SUCCESS", payload: uiToken })
}

const root = document.getElementById("root");
const rootContainer = createRoot(root);
const theme = createTheme({});

rootContainer.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Redirect from="/" to="/auth/login-page" />
          {/* <Redirect from="/admin/" to="/auth/login-page" /> */}
        </Switch>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
);