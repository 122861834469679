import React, { Component } from "react";
import { NavDropdown, MenuItem } from "react-bootstrap";
import {
  getNotificationList, setSelectedIncidentCode, setSelectedBatchCode,
  setReducerInitMode, updateIncidentReadStatus
} from '../../modules/incident/actions/incidentActions';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setActionMode } from "../../actions/appActions";
import { setAjaxCallStatus } from '../../actions/ajaxStatusActions';
import { setSelectedMfgOrderCode } from "../../modules/manufacturingOrder/actions/mfgOrderActions";
import { setSelectedSaleOrderCode } from "../../modules/saleOrderAMS/actions/saleOrderActions";
import { setSelectedKitOrderCode } from "../../modules/stockKit/actions/kitOrderActions";
import { NavLink, withRouter } from "react-router-dom";
import make from "assets/img/make.svg";
import automation from "assets/img/automation.svg";
import info from "assets/img/info.svg";
import CommonUtil from '../../modules/common/util/commonUtil';
import * as commonConstant from '../../modules/common/constant/commonConstant';
import * as incidentConstant from '../../modules/incident/constant/incidentConstant';
import * as navigationURLConstants from '../../constants/navigationURLConstants';
import { clickDrop } from "constants/dropdownevent";
class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attributeList: null,
      attributeObj: null,
      redirect: false,
      redirectUrl: null,
      notificationCount: 0,
      dropdownIsOpen: false,
    };
    this.handleShowDetailsLink = this.handleShowDetailsLink.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.updateNotificationDetails = this.updateNotificationDetails.bind(this)
  }

  getNotificationList = () => {
    this.setState({ notificationList: null });
    this.props.getNotificationList();
  }

  toggleDropdown = () => {
    this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })
  }

  componentDidMount() {
    this.getNotificationList();
    setInterval(() => {
      this.getNotificationList();
    }, 300000);
  }

  componentWillUnmount() {
    this.props.setReducerInitMode(null);
  }

  updateNotificationDetails = () => {
    var tempCount = 0;
    var tempNotificationList = [...this.props.notificationList];
    for (var i = 0; i < tempNotificationList.length; i++) {
      tempNotificationList[i].message = tempNotificationList[i].message.replace('{fullName}',
        this.getUpdatedString(tempNotificationList[i].fullName))
      tempNotificationList[i].message = tempNotificationList[i].message.replace('{ordernumber}',
        tempNotificationList[i].mfgOrderId)
      if (!tempNotificationList[i].ifread) {
        tempCount++;
      }
    }
    this.setState({ notificationList: tempNotificationList, notificationCount: tempCount });
  }

  getUpdatedString(text) {
    return text;
  }

  handleExcelDownload = (event) =>{
    if(event.link){
        window.open(event.link,"_blank");
    }
  }
  handleShowDetailsLink = (event, list, notifyCode) => {
    var tempId = event.target.id.split("_");
    let params = {};
    let url = "";
    let location = "";

    if (tempId[1] === "INC") {
      this.props.setSelectedIncidentCode(tempId[0]);
      this.props.setSelectedBatchCode(tempId[3]);
    } else if (tempId[1] === "MFG") {
      this.props.setSelectedMfgOrderCode(tempId[0]);
    } else if (tempId[1] === "SALE") {
      this.props.setSelectedSaleOrderCode(tempId[0]);
    } else if (tempId[1] === "KIT") {
      this.props.setSelectedKitOrderCode(tempId[0]);
    }

    var tempObj = {};
    tempObj.incidentCode = notifyCode;
    // this.props.updateIncidentReadStatus(tempObj);
    this.props.setActionMode(commonConstant.VIEW_ACTION_MODE);
    if (list.incidentCode) {
      url = incidentConstant.CREATE_INCIDENT_PAGE_URL;
    } else if (list.mfgOrderId) {
      url = incidentConstant.CREATE_MFG_ORDER_PAGE_URL;
    } else if (list.saleOrderId) {
      url = incidentConstant.CREATE_SALE_ORDER_PAGE_URL;
    } else if (list.kitCode) {
      url = incidentConstant.CREATE_KIT_ORDER_PAGE_URL;
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "PO") {
      url = navigationURLConstants.MANAGE_PO_PAGE_URL;
      params = "DRAFT"; location = list.facilityId;
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "MO") {
      url = navigationURLConstants.MANAGE_MO_PAGE_URL;
      params = "DRAFT"; location = list.facilityId;
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "SO") {
      url = navigationURLConstants.MANAGE_SO_PAGE_URL;
      params = "OPEN"; location = list.facilityId;
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "STO") {
      url = navigationURLConstants.MANAGE_STO_PAGE_URL;
      params = "DRAFT"; location = list.facilityId;
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "KIT") {
      url = navigationURLConstants.MANAGE_KIT_PAGE_URL;
      params = "DRAFT"; location = list.facilityId;
    } else if (list.moduleName.toUpperCase() == "REPORT") {
      url = navigationURLConstants.MANAGE_REPORT_PAGE_URL
    } else if (list.moduleName.toUpperCase() == "AUTOMATION" && list.moduleName.toUpperCase() == "INVENTORY") {
      if (list.ruleCode == "AUTORULE2") {
        url = navigationURLConstants.MANAGE_SO_PAGE_URL;
        params = "OPEN";
      } else {
        url = navigationURLConstants.MANAGE_INVENTORY_PAGE_URL;
        if(CommonUtil.isProductCode(list.uniqueCode) == true) {
          params = "PRODUCT";
        } else {
          params = "INGREDIENT";
        }
      }
      location = list.facilityId;
    }else if(list.moduleName.toUpperCase() == "INVENTORY" && CommonUtil.isNotNull(list.filePath)) {
        CommonUtil.buildLinkAndDownLoadFile(list.filePath);
        return;
    }else if (list.moduleName.toUpperCase() == "QUOTATION") {
      url = `${navigationURLConstants.QUOTATION_PAGE_URL}/${list.id}`; 
    }
    CommonUtil.handlePageRedirection(url, this, params, location);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notificationList != null && prevProps.notificationList != this.props.notificationList) {
      this.updateNotificationDetails();
    }
    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
    }
  }

  render() {
    const { notificationList, notificationCount } = this.state;
    if (this.state.redirect === true) {
      return <Redirect push to={{ pathname: this.state.redirectUrl, location: this.state.location, state: this.state.params }}></Redirect> 
    }
    return (
      <>
        <NavDropdown className="notifications"
          title={
            <div onClick={() => this.getNotificationList()}>
              <i className="fa fa-bell-o" />
              {notificationCount > 0 ? <span className="notification">{notificationCount}</span> : ''}
              <p className="hidden-md hidden-lg">
                Notifications
              <b className="caret" />
              </p>
            </div>
          }
          noCaret id="notification"
          open={this.state.dropdownIsOpen}
          onToggle={(val) => this.toggleDropdown(val)}
          onClick={clickDrop}
        >
          <div className="notification-header">
            <div className="title">
              notification
            </div>
          </div>
          {this.state && this.state.notificationList && this.state.notificationList.map((tempObj, index) => (
            <>
              {tempObj.ifread ?
                <MenuItem key={index} className="" onClick={() => this.toggleDropdown()}>
                  <div className="notification-section">
                    <div className="notification-icon-section">
                      {tempObj.priority == 'HIGH' ?
                        <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                          <img src={info} alt="" />
                        </div>
                        : tempObj.priority == 'MEDIUM' ?
                          <div className="notification-icon-circle" style={{ backgroundColor: "#FF9900" }}>
                            <img src={info} alt="" />
                          </div>
                          : tempObj.priority == 'LOW' ?
                            <div className="notification-icon-circle" style={{ backgroundColor: "#008000" }}>
                              <img src={info} alt="" />
                            </div>
                            : tempObj.priority == 'STATUS' ?
                              <div className="notification-icon-circle" style={{ backgroundColor: "#199D9D" }}>
                                <img src={info} alt="" />
                              </div>
                              : tempObj.module == 'MFGORDER' ?
                                <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                                  <img src={make} alt="" />
                                </div>
                              : tempObj.module == 'SALEORDER' ?
                              <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                                <img src={make} alt="" />
                              </div>
                                : tempObj.module == 'AUTOMATION' ?
                                  <div className="notification-icon-circle" style={{ backgroundColor: "#FF9900" }}>
                                    <img src={automation} alt="" />
                                  </div>

                                  : <div className="notification-icon-circle" style={{ backgroundColor: "#199D9D" }}>
                                    <img src={info} alt="" />

                                  </div>
                      }
                    </div>
                    <div className="notification-content-section">
                      <div className="notification-content">
                        {tempObj.moduleName == "Product" ? "Click on Export to download your file" :tempObj.message}
                      </div>
                      <div className="more-details-section">
                        <div className="more-detail-link"
                          key={tempObj.incidentCode + "_" + index + "_" + tempObj.batch}
                          id={tempObj.incidentCode ? tempObj.incidentCode + "_" + "INC" + "_" + index + "_" + tempObj.batch :
                            tempObj.mfgOrderId ? tempObj.mfgOrderId + "_" + "MFG" + "_" + index :
                            tempObj.saleOrderId ? tempObj.saleOrderId + "_" + "SALE" + "_" + index :
                              tempObj.kitCode ? tempObj.kitCode + "_" + "KIT" + "_" + index :
                                tempObj.module == "AUTOMATION" ? tempObj.notifyCode + "_" + tempObj.moduleName + "_" + index : tempObj.module == "REPORT" ? tempObj.notifyCode + "_" + tempObj.module + "_" + index : null}
                          onClick={(e) =>{tempObj.moduleName == "Product"? this.handleExcelDownload(tempObj) : this.handleShowDetailsLink(e, tempObj, tempObj.notifyCode)}}
                        >
                          {tempObj.moduleName == "Product" ? "Export" :"View Detail"}
                      </div>
                        {/* <div className="time-detail">
                          {CommonUtil.getCurrentZoneFormatDate(tempObj.createddate)}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </MenuItem>
                : <MenuItem key={index} className="new" onClick={() => this.toggleDropdown()}>
                  <div className="notification-section">
                    <div className="notification-icon-section">
                      {tempObj.priority == 'HIGH' ?
                        <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                          <img src={info} alt="" />
                        </div>
                        : tempObj.priority == 'MEDIUM' ?
                          <div className="notification-icon-circle" style={{ backgroundColor: "#FF9900" }}>
                            <img src={info} alt="" />
                          </div>
                          : tempObj.priority == 'LOW' ?
                            <div className="notification-icon-circle" style={{ backgroundColor: "#008000" }}>
                              <img src={info} alt="" />
                            </div>
                            : tempObj.module == 'MFGORDER' ?
                              <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                                <img src={make} alt="" />
                              </div>

                            : tempObj.module == 'SALEORDER' ?
                            <div className="notification-icon-circle" style={{ backgroundColor: "#FF0000" }}>
                              <img src={make} alt="" />
                            </div>
                              : tempObj.module == 'AUTOMATION' ?
                                <div className="notification-icon-circle" style={{ backgroundColor: "#FF9900" }}>
                                  <img src={automation} alt="" />

                                </div>
                                : <div className="notification-icon-circle" style={{ backgroundColor: "#199D9D" }}>
                                  <img src={info} alt="" />
                                </div>

                      }
                    </div>
                    <div className="notification-content-section">
                      <div className="notification-content">
                        {tempObj.moduleName == "Product" ? "Click on Export to download your file":tempObj.message}
                      </div>
                      <div className="more-details-section">
                        <div className="more-detail-link"
                          key={tempObj.incidentCode + "_" + index + "_" + tempObj.batch}
                          id={tempObj.incidentCode ? tempObj.incidentCode + "_" + "INC" + "_" + index + "_" + tempObj.batch :
                            tempObj.mfgOrderId ? tempObj.mfgOrderId + "_" + "MFG" + "_" + index :
                            tempObj.saleOrderId ? tempObj.saleOrderId + "_" + "SALE" + "_" + index :
                              tempObj.kitCode ? tempObj.kitCode + "_" + "KIT" + "_" + index :
                                tempObj.module == "AUTOMATION" ? tempObj.notifyCode + "_" + tempObj.moduleName + "_" + index : null}
                          onClick={(e) =>{tempObj.moduleName == "Product"? this.handleExcelDownload(tempObj): this.handleShowDetailsLink(e, tempObj, tempObj.notifyCode)}}
                        >
                            {tempObj.moduleName == "Product" ? "Export" :"View Detail"}
                        </div>
                        {/* <div className="time-detail">
                          {CommonUtil.getCurrentZoneFormatDate(tempObj.createddate)}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </MenuItem>
              }
            </>

          ))}
        </NavDropdown>
      </>
    )
  }
};

function mapStateToProps(state, ownProps) {
  return {
    notificationList: state.incident.notificationList,
    actionMode: state.app.actionMode,
  };
}

const mapDispatchToProps = dispatch => ({
  setSelectedMfgOrderCode: selectedMfgOrderCode => dispatch(setSelectedMfgOrderCode(selectedMfgOrderCode)),
  setSelectedSaleOrderCode: selectedMfgOrderCode => dispatch(setSelectedSaleOrderCode(selectedMfgOrderCode)),
  setSelectedIncidentCode: selectedIncidentCode => dispatch(setSelectedIncidentCode(selectedIncidentCode)),
  setSelectedBatchCode: selectedBatchCode => dispatch(setSelectedBatchCode(selectedBatchCode)),
  setActionMode: actionMode => dispatch(setActionMode(actionMode)),
  setAjaxCallStatus: ajaxCallStatus => dispatch(setAjaxCallStatus(ajaxCallStatus)),
  setSelectedKitOrderCode: (selectedSaleOrderCode) => dispatch(setSelectedKitOrderCode(selectedSaleOrderCode)),
  setActionMode: actionMode => dispatch(setActionMode(actionMode)),
  setReducerInitMode: init => dispatch(setReducerInitMode(init)),
  getNotificationList: (params) => dispatch(getNotificationList(params)),
  updateIncidentReadStatus: (notificationDetails) => dispatch(updateIncidentReadStatus(notificationDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
