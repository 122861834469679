//common action types
export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
export const END_AJAX_CALL = "END_AJAX_CALL";

export const SET_AJAX_CALL_STATUS = "SET_AJAX_CALL_STATUS";
export const SET_TEMP_AJAX_CALL_STATUS = "SET_TEMP_AJAX_CALL_STATUS";
export const SET_UPLOAD_IMPORT_FILE_STATUS = "SET_UPLOAD_IMPORT_FILE_STATUS";

//auth action types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR_MESSAGE = "CLEAR_LOGIN_ERROR_MESSAGE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const CLEAR_SIGNUP_ERROR_MESSAGE = "CLEAR_SIGNUP_ERROR_MESSAGE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const APP_ACTION_MODE = "APP_ACTION_MODE";

export const API_SUCCESS_RESPONSE = "SUCCESS";
export const API_FAILED_RESPONSE = "FAILED";