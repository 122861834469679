import * as kitOrderActionTypes from "../actions/kitOrderActionTypes";

const initialState = {
  kitOrderDetails: null,
  kitOrderList: null,
  selectedKitOrderCode: null,
  selectedFacilityId:null
};

const kitOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case kitOrderActionTypes.SET_KIT_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case kitOrderActionTypes.SET_KIT_ORDER_DETAILS:
      return {
        ...state,
        kitOrderDetails: action.payload
      };
    case kitOrderActionTypes.GET_KIT_ORDER_LIST:
      return {
        ...state,
        kitOrderList: action.payload
      };
    case kitOrderActionTypes.SET_SELECTED_KIT_ORDER_CODE:
      return {
        ...state,
        selectedKitOrderCode: action.payload
      };
    case kitOrderActionTypes.GET_KIT_ORDER_DETAILS:
      return {
        ...state,
        kitOrderDetails: action.payload
      };
    case kitOrderActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    default:
      return state;
  }
};

export default kitOrderReducer;
