import axios from '../../../axios/axios';
import * as saleOrderActionTypes from './saleOrderActionTypes';
import * as saleOrderConstant from '../constant/saleOrderConstant';
import {
  beginAjaxCall, endAjaxCall, setAjaxCallStatus, updateApiResponse,
  createApiResponse, failedApiResponse
} from "../../../actions/ajaxStatusActions";
import CommonUtil from '../../common/util/commonUtil';

export function setReducerInitMode() {
  return function (dispatch) {
    dispatch({
      type: saleOrderActionTypes.SET_SALE_ORDER_REDUCER_INIT_MODE,
      payload: null
    });
  }
}

export function setProductStatusList(productStatusList) {
  return function (dispatch) {
    dispatch({
      type: saleOrderActionTypes.SET_PRODUCT_STATUS_LIST,
      payload: productStatusList
    });
  }
}

export function setRecallWithdrawDetails(saleOrderDetailsObj) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.post(saleOrderConstant.SET_CREATE_RECALL_WITHDRAW_DETAILS_URL,
      saleOrderDetailsObj).then(response => {
        dispatch(setAjaxCallStatus(createApiResponse(response)));
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  };
}

export function setSaleOrderDetails(saleOrderDetailsObj, actionMode, saleOrderId) {
  if (CommonUtil.isCreateOrCloneMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.post(saleOrderConstant.SET_CREATE_SALE_ORDER_DETAILS_URL,
        saleOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(createApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
  if (CommonUtil.isEditMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.put(saleOrderConstant.SET_UPDATE_SALE_ORDER_DETAILS_URL +
        saleOrderId, saleOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(updateApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
  if(CommonUtil.isSendReceipt(actionMode)){}
  return function(dispatch){
    dispatch(beginAjaxCall());
    axios.put(saleOrderConstant.SET_UPDATE_SALE_ORDER_DETAILS_URL +
      saleOrderId, saleOrderDetailsObj).then(response => {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  }
}

export function setSelectedSaleOrderCode(selectedSaleOrderCode) {
  return function (dispatch) {
    dispatch({
      type: saleOrderActionTypes.SET_SELECTED_SALE_ORDER_CODE,
      payload: selectedSaleOrderCode
    });
  }
}

export function getSaleOrderList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_LIST_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}


export function getSaleOrderDetails(selectedSaleOrderCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_DETAILS_URL + selectedSaleOrderCode).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_DETAILS,
          payload: response.data[0]
        });
        dispatch({
          type: saleOrderActionTypes.GET_DETAILS,
          payload: response.data[0]
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getShopifyCustomerDetails() {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SHOPIFY_CUSTOMER_DETAILS_URL).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SHOPIFY_CUSTOMER_DETAILS,
          payload: response.data.Items
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });

  };
}

export function getPriorityDetails(selectedSaleOrderCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_PRIORITY_DETAILS_URL).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_PRIORITY_DETAILS,
          payload: response.data.Item
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function updateSaleOrderStatus(saleOrderDetailsObj, saleOrderId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(saleOrderConstant.SET_UPDATE_SALE_ORDER_STATUS_URL + saleOrderId,
      saleOrderDetailsObj).then(response => {
        if (response.status == 200) {
          axios.get(saleOrderConstant.GET_SALE_ORDER_LIST_URL).then(response => {
            if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
              dispatch({
                type: saleOrderActionTypes.GET_SALE_ORDER_LIST,
                payload: response.data
              })
            }
          })
        }
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function updateSaleOrderAmendStatus(saleOrderId, obj) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(saleOrderConstant.SET_UPDATE_SALE_ORDER_AMEND_STATUS_URL + saleOrderId, obj).then(response => {
      dispatch(setAjaxCallStatus(updateApiResponse(response)));
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function updateSaleOrderPriority(priorityList) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(saleOrderConstant.SET_UPDATE_SALE_ORDER_PRIORITY_URL, priorityList).then(response => {
      if (response.status == 200) {
        axios.get(saleOrderConstant.GET_SALE_ORDER_PRIORITY_DETAILS_URL).then(response => {
          if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
            dispatch({
              type: saleOrderActionTypes.GET_SALE_ORDER_PRIORITY_DETAILS,
              payload: response.data.Item
            });
          }
          dispatch(endAjaxCall());
        }).catch((error) => {
          dispatch(endAjaxCall());
        });
      }
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function updateSaleOrderPayment(paymentRequestObj) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(saleOrderConstant.SET_UPDATE_SALE_ORDER_PAYMENT_URL, paymentRequestObj).then(response => {
      if (response.status == 200) {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function getReceivedStockList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_STOCK_LIST_URL +
      params.entityCode + "/" + params.facilityId).then((response) => {
        if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
          dispatch({
            type: saleOrderActionTypes.GET_SALE_ORDER_STOCK_LIST,
            payload: response.data,
          });
        }
        dispatch(endAjaxCall());
      }).catch((error) => {
        dispatch(endAjaxCall());
      });
  };
}


export function updateSaleOrderDeleteStatus(saleOrderDetailsObj, saleOrderId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(saleOrderConstant.SET_UPDATE_SALE_ORDER_STATUS_URL + saleOrderId,
      saleOrderDetailsObj).then(response => {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  };
}


export function getTopProductList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_TOPSELL_DASHBOARD_LIST, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_DASHBOARD_LIST_URL,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getProfitOverTimeList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_PROFIT_DASHBOARD_LIST, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_PROFIT_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}


export function getsaleOrderCount(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_COUNT_DASHBOARD_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_COUNT_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getSaleOTIFList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_OTIF_DASHBOARD_LIST, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_OTIF_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}


export function getInventoryCoverage(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_INVENTORY_COVERGAE_DASHBOARD_LIST, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_INVENTORY_COVERAGE_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
      throw error;
    });
  };
}

export function getSaleChannelCostList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_CHANNEL_COST_DASHBOARD_LIST, { params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_CHANNEL_COST_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function getsaleChannelCountList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST, { params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getQBOInvoiceDetails(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_QBO_CREATE_DETAILS_URL, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_QBO_INVOICE_DETAILS,
          payload: response.data,
        });
      }
      dispatch(setAjaxCallStatus(updateApiResponse(response)));
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
      dispatch(setAjaxCallStatus(failedApiResponse(error)));
    });
  };
}

export function getQBODetails() {
  return function (dispatch) {
    // this code will activate the qbo
    console.info("-----------------------------" + saleOrderConstant.BASE_URL);
    console.info("-----------------------------" + saleOrderConstant.IS_QBO);
    if (saleOrderConstant.IS_QBO) {
      dispatch(beginAjaxCall());
      axios.get(saleOrderConstant.GET_QBO_DETAILS_URL).then((response) => {
        if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
          dispatch({
            type: saleOrderActionTypes.GET_QBO_DETAILS,
            payload: response.data,
          });
        }
        dispatch(endAjaxCall());
      }).catch((error) => {
        dispatch(endAjaxCall());
      });
    }
    // this code will activate the qbo
  };
}
export function downLoadXLSFile(selectedTab, params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.DOWNLOAD_XLS_FILE_URL, { params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        CommonUtil.getXlsResponseDownloadFile(response.data, selectedTab + "_SALE_ORDERS.xlsx");
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function downLoadDriverXLSFile(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.DOWNLOAD_DRIVER_XLS_FILE_URL, { params: params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        CommonUtil.getXlsResponseDownloadFile(response.data, "DRIVER_SALE_ORDERS.xlsx");
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function downloadInvoice(id, key) {
  return function (dispatch) {
    let downloadURL = '';
    downloadURL = `${saleOrderConstant.DOWNLOAD_INVOICE_URL}${id}?downloadType=${key}`

    dispatch(beginAjaxCall());
    axios.get(downloadURL).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        CommonUtil.downloadPdf(response.data.Location, response.data.key, CommonUtil.saveAs);
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function getSaleOrderSearchList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_SEARCH_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getSaleOrderPaymentDetails(selectedSaleOrderCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.GET_SALE_ORDER_PAYMENT_DETAILS_URL + selectedSaleOrderCode).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: saleOrderActionTypes.GET_SALE_ORDER_PAYMENT_DETAILS,
          payload: response.data[0]
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getCartDetails(params, tenantId) {
  return function (dispatch) {
    let headers = {
      'X-TENANT-ID': tenantId
    };
    dispatch(beginAjaxCall());
    axios.post(saleOrderConstant.GET_CART_DETAILS_URL, params, { headers }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_CART_DETAILS,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function sendReceiptOverSms(selectedSaleOrderCode,tempObj) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.post(saleOrderConstant.SEND_RECEIPT_OVER_SMS + selectedSaleOrderCode,tempObj).then(response => {
      if (response.status == 200) {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}


export function sendReceiptOverEmail(id, key, email) {
  return function (dispatch) {
    let downloadURL = '';
    downloadURL = `${saleOrderConstant.DOWNLOAD_INVOICE_URL}${id}?downloadType=${key}&email=${email}`
    dispatch(beginAjaxCall());
    axios.get(downloadURL).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function sendReceiptOverEmailIncluddePaymentLink(id,params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(saleOrderConstant.SEND_PAYMENT_EMAIL+id,{params}).then((response) => {
      if (response.status == 200) {
        console.log("=====581*******====",response)
        response["data"]["response"]["params"]=params
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
      dispatch(setAjaxCallStatus(failedApiResponse(error)));

    });
  };
}
export function getPaymentLinkApi(id,params) {

  return function (dispatch) {
    let downloadURL = '';
    downloadURL = `${saleOrderConstant.SEND_PAYMENT_EMAIL}${id}?getPaymentLink=${params.getPaymentLink}`
    dispatch(beginAjaxCall());
    axios.get(downloadURL,params).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: saleOrderActionTypes.GET_PAYMENT_DETAILS,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
      dispatch(setAjaxCallStatus(failedApiResponse(error)));

    });
  };
}


export function setSelectedFacilityId(selectedFacilityId) {
  return function (dispatch) {
    dispatch({
      type: saleOrderConstant.SET_SELECTED_FACILITY_ID,
      payload: selectedFacilityId
    });
  }
}