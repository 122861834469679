import React from 'react';
import moment from "moment";
import * as commonConstant from '../../common/constant/commonConstant';
import pdf from 'assets/img/pdf.svg';
import excel from 'assets/img/excel.svg';
import csv from 'assets/img/csv.svg';
import file from 'assets/img/file.svg';
import docx from 'assets/img/docx.svg';
import image from 'assets/img/image.svg';
import styled from 'styled-components';
import queryString from 'query-string';
import axios from 'axios';

const ProductSelectionWarningStyles = styled.div`
    .warning-header {
        color: #199D9D;
        font-size: 28px;
        width: 400px;
        font-weight: bold;
    }

    .warning-msg-title {
        color: #000;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        width: 400px;
    }

    .warning-msg {
        width: 312px;
        font-size: 13px;
        color: #616161;
        font-weight: bold;
        margin-left: 35px;
    }

    .warning-footer {
        margin-top: 20px;
        color: #616161;
        font-size: 20px;
        font-weight: bold;
    }
`;
const CommonUtil = {
    disablePastDt: function () {
        const disablePastDt = current => {
            return current.isAfter(moment().subtract(1, 'day'));
        };
        return disablePastDt;
    },
    generateUniqueCode: function (latestCount, uniqueCodePrefix, padZeroCount) {
        var tempUniqueCode = latestCount;
        tempUniqueCode = parseInt(tempUniqueCode) + 1;
        tempUniqueCode = tempUniqueCode.toString().padStart(padZeroCount, "0");
        tempUniqueCode = uniqueCodePrefix + tempUniqueCode;
        return tempUniqueCode;
    },
    generateUniqueCodeWithoutIncr: function (latestCount, uniqueCodePrefix, padZeroCount) {
        var tempUniqueCode = latestCount;
        tempUniqueCode = parseInt(tempUniqueCode);
        tempUniqueCode = tempUniqueCode.toString().padStart(padZeroCount, "0");
        tempUniqueCode = uniqueCodePrefix + tempUniqueCode;
        return tempUniqueCode;
    },
    prepareCancelPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "All changes will be lost. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "All changes will be lost. Do you want to continue?";
        return popupConfig;
    },
    prepareSavePopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "By clicking continue all changes will be saved.";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "By clicking continue all changes will be saved.";
        return popupConfig;
    },
    prepareProductSelectionWarningConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = (
            <ProductSelectionWarningStyles>
                <div className="warning-header">Product Selection Warning</div>
                <div className="warning-msg-title">Selected Product is not a KITING Product</div>
                <div className="warning-msg">If you continue with the product component screen will need to add manually</div>
                <div className="warning-footer">Would you still like to continue !</div>
            </ProductSelectionWarningStyles>
        );
        popupConfig.confirmButtonText = "YES";
        popupConfig.cancelButtonText = "NO";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        return popupConfig;
    },

    prepareSaveSupplierPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected at least a product or ingredient for the supplier. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected at least a product or ingredient for the supplier. Do you want to continue?";
        return popupConfig;
    },
    prepareSaveProductMakePopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected at least an ingredient or operation for your manufacturing product. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected at least an ingredient or operation for your manufacturing product. Do you want to continue?";
        return popupConfig;
    },
    prepareSaveProductMakeOperationPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected at least an operation for your manufacturing product. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected at least an operation for your manufacturing product. Do you want to continue?";
        return popupConfig;
    },
    prepareSaveProductMakeIngredientsPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected at least an ingredient for your manufacturing product. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected at least an ingredient for your manufacturing product. Do you want to continue?";
        return popupConfig;
    },
    prepareSaveProductKittingPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected BOM-Kit Component for your Kitting product. Do you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected BOM-Kit Component for your Kitting product. Do you want to continue?";
        return popupConfig;
    },
    prepareSaveProductRepackPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "You have not selected Repack Component for your repack product. Please select one repack component.";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "You have not selected Repack Component for your repack product. Please select one repack component.";
        return popupConfig;
    },
    prepareAjaxCallErrorPopUpConfig: function (popupActionButton, errorMessage) {
        var popupConfig = {};
        popupConfig.title = errorMessage ? errorMessage : "Records Update Failed!";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "All changes will be lost. Do you want to continue?";
        return popupConfig;
    },
    prepareTrackPopUpConfig: function (popActionButton) {
        var popupConfig = {};
        popupConfig.title = "Once you change your tracking system, you won’t be able to revert it back. Are you sure you want to continue?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popActionButton.onConfirmClick;
        popupConfig.onCancelClick = popActionButton.onCancelClick;
        popupConfig.message = "Once you change your tracking system, you won’t be able to revert it back. Are you sure you want to continue?";
        return popupConfig;
    },
    prepareCreateSuccessPopUpConfig: function (generateCode) {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        if (generateCode) {
            popupConfig.message = "Record Created Successfully with " + generateCode;
        } else {
            popupConfig.message = "Record Created Successfully";
        }
        return popupConfig;
    },
    prepareforgotPasswordPopUpConfig: function () {
        return {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Password has been reset successfully."
        };
    },
    prepareUpdateProfilePopUpConfig: function () {
        return {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "User profile updated successfully."
        };
    },
    prepareChangePasswordPopUpConfig: function () {
        return {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Password has been changed successfully."
        };
    },
    prepareEmailSentPopUpConfig: function () {
        return {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Security code has been sent over your registered email."
        };
    },
    prepareUpdateSuccessPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Record Updated Successfully ";
        return popupConfig;
    },
    prepareResetUserSuccessPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Data has been reset successfully. Now you can create fresh data as per your business need.";
        return popupConfig;
    },
    prepareDeactivateSuccessPopUpConfig: function () {
        const popupConfig = {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Record Deactivated Successfully "
        };
        return popupConfig;
    },
    prepareVariantSuccessPopUpConfig: function () {
        const popupConfig = {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Product variations are saved successfully."
        };
        return popupConfig;
    },
    prepareDeleteSuccessPopUpConfig: function () {
        const popupConfig = {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Record Deleted Successfully "
        };
        return popupConfig;
    },
    prepareDelteFailedPopUpConfig: function () {
        const popupConfig = {
            delayTime: 5,
            color: 1,
            position: "tc",
            message: "Record Deletion Failed. "
        };
        return popupConfig;
    },
    prepareTenentUpdateSuccessPopUpConfig: function (successCount, totalCount) {
        var popupConfig = {};
        popupConfig.delayTime = 10;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Successfully added " + successCount + " out of " + totalCount;
        return popupConfig;
    },
    prepareDeleteRowPopUpConfig: function (popupActionButton, moduleName) {
        var popupConfig = {};
        popupConfig.title = "Are you sure, you want to delete ?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure you want to delete ?";
        return popupConfig;
    },
    prepareConfirmPopUpConfig: function (popupActionButton, moduleName) {
        var popupConfig = {};
        popupConfig.title = "Are you sure you want to update the stock?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure you want to update the stock?";
        return popupConfig;
    },
    preparUnpackConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Are you sure, you want to unpack the box(s) ?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure, you want to unpack the box(s) ?";
        return popupConfig;
    },
    prepareDeactivatePopUpConfig: function (popupActionButton, moduleName) {
        var popupConfig = {};
        popupConfig.title = "Are you sure you want to deactivate " + moduleName + "?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure you want to deactivate " + moduleName + "?";
        return popupConfig;
    },
    prepareDeletPopUpConfig: function (popupActionButton, moduleName) {
        var popupConfig = {};
        popupConfig.title = "Are you sure you want to delete the " + moduleName + "?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure you want to delete the " + moduleName + "?";
        return popupConfig;
    },
    prepareShipmentStatusPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Are you sure you want to change status of shipment?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Are you sure you want to change status of shipment?";
        return popupConfig;
    },
    prepareTaxPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Order will be created without Tax, Do you still want to continue ?";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Order will be created without Tax, Do you still want to continue ?";
        return popupConfig;
    },
    prepareDoneStatusPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Please select Batch for all Products";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Please select Batch for all Products";
        return popupConfig;
    },
    prepareDoneStatusFpPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Please select Lot/Pallet for all the products.";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Please select Lot/Pallet for all the products.";
        return popupConfig;
    },
    prepareCustomErrorMsgPopUpConfig: function (popupActionButton, message, showConfirm) {
        var popupConfig = {};
        popupConfig.title = message;
        if (showConfirm) {
            popupConfig.confirmButtonText = "Continue";
            popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        }
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = message;
        return popupConfig;
    },
    prepareDuplicateErrorPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Please select Batch for all Products";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Please select Batch for all Products";
        return popupConfig;
    },
    prepareQBOInactivePopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Quick Book Account is not connected!";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Quick Book Account is not connected!";
        return popupConfig;
    },
    prepareQBOSupplierNotExitPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Supplier does not exit in Quick Books!";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Supplier does not exit in Quick Books!";
        return popupConfig;
    },
    prepareQBOCustomerNotExitPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Customer does not exit in Quick Books!";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Customer does not exit in Quick Books!";
        return popupConfig;
    },
    prepareDeactivateFailedPopUpConfig: function (popupActionButton, message, messageDetails) {
        var popupConfig = {};
        popupConfig.title = message;
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = messageDetails;
        return popupConfig;
    },
    withOutRowValidationPopUpConfig: function (popupActionButton, message) {
        var popupConfig = {};
        popupConfig.title = message;
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = message;;
        return popupConfig;
    },
    prepareInvalidSelectionPopUpConfig: function (popupActionButton, errorMsg) {
        var popupConfig = {};
        popupConfig.title = "Please enter valid " + errorMsg + " order Code";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Please enter valid " + errorMsg + " order Code";
        return popupConfig;
    },
    prepareReceivedStatusPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Invalid Operation! Please change status to Complete";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Invalid Operation! Please change status to Complete";
        return popupConfig;
    },
    preparePopUpConfigWithOnlyCancel: function (popupActionButton, message) {
        let popupConfig = {};
        popupConfig.title = message;
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = message;
        return popupConfig;
    },
    IntegrationConnectionPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "We do not allow you to connect to the third-party applications until your Demo/ Training data is deleted via Reset Data. This check is necessary to protect any valuable data that you may import from third-party applications.";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "";
        popupConfig.subHeading = "Please reset the data and connect again.";
        return popupConfig;
    },
    inActiveUserLogoutPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 20;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Your Account has been deactivated by Admin ";
        return popupConfig;
    },
    recallPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Records Update Failed!";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "";
        return popupConfig;
    },
    resendEmailPopUpConfig: function (generateCode) {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Temporary Password has been sent over your registered email";
        return popupConfig;
    },
    prepareReceiptOverEmail: function (type) {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Invoice Details has been sent on given " + type;
        return popupConfig;
    },
    prepareReceiptOverWithMessageEmail: function (type, message) {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = message;
        return popupConfig;
    },
    prepareReportGenerationPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Report generation is initiated, you will be notified once it is ready.";
        return popupConfig;
    },
    prepareAuditGenerationPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Audit Log generation is initiated, you will be notified once it is ready.";
        return popupConfig;
    },
    prepareStockNotAvailablePopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Stock not available.";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Stock not available.";
        return popupConfig;
    },
    prepareValidateAddressPopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Please verify your address again.";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = "Please verify your address again.";
        return popupConfig;
    },
    prepareSOReadyDonePopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Return order has already been created for the chosen sale order. Please choose a different sale order.";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.message = "Return order has already been created for the chosen sale order. Please choose a different sale order.";
        return popupConfig;
    },
    preparePOReadyDonePopUpConfig: function (popupActionButton) {
        var popupConfig = {};
        popupConfig.title = "Return order has already been created for the chosen purchase order. Please choose a different purchase order.";
        popupConfig.confirmButtonText = "Continue";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.message = "Return order has already been created for the chosen purchase order. Please choose a different purchase order.";
        return popupConfig;
    },
    prepareCreditTransactionPopUpConfig: function (popupActionButton, message) {
        var popupConfig = {};
        popupConfig.title = message;
        popupConfig.confirmButtonText = "Continue";
        popupConfig.cancelButtonText = "Cancel";
        popupConfig.onConfirmClick = popupActionButton.onConfirmClick;
        popupConfig.onCancelClick = popupActionButton.onCancelClick;
        popupConfig.message = message;
        return popupConfig;
    },
    prepareBulkUploadPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "File uploading process is initiated, you will be notified once it is done.";
        return popupConfig;
    },
    prepareProductExportSuccess: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Your file is getting ready. Please check notifications for file";
        return popupConfig;
    },
    prepareProductExportFail: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 3;
        popupConfig.position = "tc";
        popupConfig.message = "Please try again after some time or notify your admin.";
        return popupConfig;
    },
    prepareErrormessage: function (message) {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 3;
        popupConfig.position = "tc";
        popupConfig.message = message;
        return popupConfig;
    },
    getGeneratedCodeFromReponse: function (responseObj) {
        var generatedUniqueCode = null;
        if (responseObj && responseObj.data) {
            generatedUniqueCode = responseObj.data; //responseObj.data.split(":")[1];
        }
        return generatedUniqueCode;
    },
    getGeneratedSOCodeFromReponse: function (responseObj) {
        var generatedUniqueCode = null;
        if (responseObj && responseObj.data) {
            generatedUniqueCode = responseObj.data.saleOrderId; //responseObj.data.split(":")[1];
        }
        return generatedUniqueCode;
    },
    getModuleNameFromCode: function (orderCode) {
        var moduleCode = null;
        if (orderCode != null) {
            moduleCode = orderCode.split("-")[0];
        }
        return moduleCode;
    },
    getNumberFromCode: function (orderCode) {
        let numberCode = null;
        if (orderCode != null) {
            numberCode = orderCode.split("-")[1];
        }
        return numberCode;
    },
    getInvoiceNumber: function (orderCode, invoiceCode) {
        let numberCode = null;
        if (invoiceCode) {
            numberCode = invoiceCode;
        } else {
            numberCode = orderCode.split("-")[1];
        }
        return numberCode;
    },
    isProductCode: function (orderCode) {
        let isProductCode = false;
        if (orderCode != null) {
            let moduleCode = orderCode.split("-")[0];
            isProductCode = moduleCode == "PRO" ? true : false;
        }
        return isProductCode;
    },
    handlePageRedirection: function (redirectURL, that, params, location) {
        that.setState({
            redirect: true,
            redirectUrl: redirectURL,
            params: params,
            location: location,
            searchInput: that.state.searchInput
        });
    },
    getQueryParamsValue: function (that, key) {
        const value = queryString.parse(that.props.location.search);
        let token = value[key];
        return token
    },
    getAdditonalPathParams: function (that) {
        if (that.props.location && that.props.location.state) {
            return that.props.location.state
        }
        return null;
    },
    getAdditonalPathLocation: function (that) {
        if (that.props.location && that.props.location.location) {
            return that.props.location.location
        }
        return null;
    },
    getAdditonalPathSearch: function (that) {
        if (that.props.location && that.props.location.searchInput) {
            return that.props.location.searchInput
        }
        return null;
    },
    getCurrentZoneDate: function (tempObjList, key) {
        for (var i = 0; i < tempObjList.length; i++) {
            if (tempObjList[i][key ? key : 'modifieddate'] != null) {
                var tempDate = tempObjList[i][key ? key : 'modifieddate'];
                tempObjList[i][key ? key : 'modifieddate'] =
                    this.getCurrentZoneFormatDate(tempDate);
            }
            // if (tempObjList[i][key ? key : 'createdTime'] != null) {
            //     var tempDate = tempObjList[i][key ? key : 'createdTime'];
            //     tempObjList[i][key ? key : 'createdTime'] =
            //         this.getCurrentZoneFormatDate(tempDate);
            // }
        }
        return tempObjList
    },

    getCurrentZoneFormatDate: function (date) {
        var newDate = new Date(date);

        return this.getZeroPadding(newDate.getMonth() + 1) +
            "-" + this.getZeroPadding(newDate.getDate()) +
            "-" + newDate.getFullYear() + " | " +
            this.getZeroPadding(newDate.getHours()) + ":" +
            this.getZeroPadding(newDate.getMinutes());
    },

    getCurrentZoneFormatDateTimesheet: function (date) {
        var newDate = new Date(date);
        var hours = newDate.getUTCHours();
        var amOrPm = (hours < 12) ? "AM" : "PM";

        // Convert hour to 12-hour format
        var hour = hours % 12;
        hour = (hour === 0) ? 12 : hour; // Adjust hour to show 12 instead of 0

        return this.getZeroPadding(newDate.getMonth() + 1) +
            "-" + this.getZeroPadding(newDate.getUTCDate()) +
            "-" + newDate.getFullYear() + "|" +
            this.getZeroPadding(hour) + ":" +
            this.getZeroPadding(newDate.getUTCMinutes()) +
            " " + amOrPm;
    },
    getTodaysDate: function (dateFormat) {
        return moment(new Date(), dateFormat ? dateFormat : 'MM-DD-YYYY').format(dateFormat ? dateFormat : 'MM-DD-YYYY');
    },
    getMonthBacksDate: function (dateFormat) {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return moment(date, dateFormat ? dateFormat : 'MM-DD-YYYY').format(dateFormat ? dateFormat : 'MM-DD-YYYY');
    },
    getWeekBacksDate: function (dateFormat) {
        const date = new Date();
        return moment(date, dateFormat ? dateFormat : 'MM-DD-YYYY').subtract(7, 'd').format(dateFormat ? dateFormat : 'MM-DD-YYYY');
    },
    getSubString: function (tempString, startindex, length) {
        if (tempString != null) {
            return tempString.substring(0, 1);
        }
        return null
    },
    isDateExpired: function (tempDate) {
        if (!tempDate) {
            return false;
        }
        var tempDateValue = new Date(tempDate);
        tempDateValue.setHours(0, 0, 0, 0);
        var todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        if (tempDateValue < todayDate) {
            return true;
        }
        return false;
    },
    addDaysInDate: function (date, days) {
        const currentDate = new Date(date);
        currentDate.setDate(currentDate.getDate() + parseInt(days));
        console.log("New Date" + currentDate);
        console.log("New Date PP" + currentDate);
        return currentDate;
    },
    getDifferenceInDate: function (date1, date2) {
        let day1 = new Date(date1);
        let day2 = new Date(date2);
        let difference = Math.abs(day2 - day1);
        let days = difference / (1000 * 3600 * 24)
        return parseInt(days);
    },
    getFormattedDate: function (date, dateFormat) {
        return date ? moment(date).format(dateFormat ? dateFormat : 'MM-DD-YYYY') : '';
    },
    formatDate: function (dateString) {
        let year, month, day;
        if (dateString.includes('T')) {
            [year, month, day] = dateString.split('T')[0].split('-');
        } else {
            [month, day, year] = dateString.split('-');
        }
        return `${month}-${day}-${year}`;
    },

    isBothEqual: function (str1, str2) {
        return str1 === str2;
    },

    isCreateMode: function (actionMode) {
        return actionMode === commonConstant.CREATE_ACTION_MODE;
    },

    isCreateOrCloneMode: function (actionMode) {
        return actionMode === commonConstant.CREATE_ACTION_MODE ||
            actionMode === commonConstant.CLONE_ACTION_MODE;
    },
    isCreateOrEditOrCloneMode: function (actionMode) {
        return actionMode === commonConstant.CREATE_ACTION_MODE ||
            actionMode === commonConstant.CLONE_ACTION_MODE || actionMode === commonConstant.EDIT_ACTION_MODE;
    },
    isEditOrCloneMode: function (actionMode) {
        return actionMode === commonConstant.EDIT_ACTION_MODE ||
            actionMode === commonConstant.CLONE_ACTION_MODE;
    },

    isEditMode: function (actionMode) {
        return actionMode === commonConstant.EDIT_ACTION_MODE;
    },

    isViewMode: function (actionMode) {
        return actionMode === commonConstant.VIEW_ACTION_MODE;
    },

    isQoToSoMode: function (actionMode) {
        return actionMode === commonConstant.QO_TO_SO_ACTION_MODE;
    },

    isCloneMode: function (actionMode) {
        return actionMode === commonConstant.CLONE_ACTION_MODE;
    },

    isMenuMode: function (actionMode) {
        return actionMode === commonConstant.MENU_ACTION_MODE;
    },

    isGenerateBarcode: function (actionMode) {
        return actionMode === commonConstant.GENERATE_BARCODE;
    },

    isSendReceipt: function (actionMode) {
        return actionMode === commonConstant.SEND_RECEIEPT;
    },
    isSendReceiptPayment: function (actionMode) {
        return actionMode === commonConstant.SEND_RECEIPT_N_PAYMENT;
    },
    isShipment: function (actionMode) {
        return actionMode === commonConstant.SHIPMENT;
    },
    isDeleteMode: function (actionMode) {
        return actionMode === commonConstant.DELETE_ACTION_MODE;
    },

    isNullValue: function (value) {
        return value === null || value === undefined || value === "" || value === "undefined";
    },
    initializeValue: function (value) {
        return value == undefined ? "" : value;
    },
    isNullArray: function (value) {
        if (this.isNullValue(value) || (value && value.length == 0)) {
            return true;
        }
        return false;
    },

    getFormattedValue: function (value, type) {
        if (type === 'float') {
            return CommonUtil.isNaNValue(parseFloat(value));
        }
        if (type === 'integer') {
            return CommonUtil.isNaNValue(parseInt(value));
        }
        return value;
    },

    isNotNull: function (value) {
        if (value !== null && value !== undefined && value !== '') {
            if (typeof value === 'object' && Object.keys(value).length == 0) {
                return false;
            }
            return true;
        }
        return false;
    },

    isAllEqual: function (strArr) {
        var isAllEqualFlag = true;
        for (var i = 0; i < strArr.length; i++) {
            if (i === 0) {
                continue;
            }

            if (strArr[i - 1] !== strArr[i]) {
                isAllEqualFlag = false
                break;
            }
        }
        return isAllEqualFlag;
    },

    getCurrentDate: function () {
        var currentDate = new Date();
        return currentDate;
    },
    isNaNValue: function (value) {
        if (isNaN(value)) {
            return 0;
        }
        return value;
    },
    getZeroPadding: function (tempDate) {
        var tempValue = tempDate + "";
        tempValue = tempValue.toString().padStart(2, "0");
        return tempValue;
    },

    updateAttributeObj: function (event, that, obj, objName) {
        const {
            name,
            value
        } = event.target;
        that.setState({
            [objName]: {
                ...obj,
                [name]: value,
            },
            function() { }
        });
    },
    handelRemoveTextList(event, that) {
        const listName = event.target.id.split('_')[0] + 'List';
        const index = parseInt(event.target.id.split('_')[1]);
        var attributeObj = {
            ...that.state.attributeObj
        };
        attributeObj[listName].splice(index, 1);
        that.setState({
            attributeObj: {
                ...attributeObj,
            },
            function() { }
        })
    },
    handleTextList(event, that) {
        const id = event.target.id;
        var attributeObj = {
            ...that.state.attributeObj
        };
        var tempName = id + 'List';
        if (that.state.attributeObj[id] != "") {
            attributeObj[tempName].push(that.state.attributeObj[id])
            that.setState({
                attributeObj: {
                    ...attributeObj,
                },
                function() { }
            })
        }
    },
    handleTextBoxChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: value
            },
            formDirty: true
        })
    },
    handleFixedTextBoxChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: value
            },
            formDirty: true
        })
    },
    handleFixedTextBoxChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        const re = /^[A-Za-z0-9_-]*$/;
        if (value === '' || re.test(value)) {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value,
                },
                formDirty: true
            })
        } else {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value.substring(0, value.length - 1),
                },
                formDirty: true
            })
        }
    },
    handleTextBoxBlur(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: value.trim()
            },
            formDirty: true
        })
    },
    handleNumberChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        const re = /^\d+(\.\d{0,2})?$/;
        if (value === '' || re.test(value)) {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value,
                },
                formDirty: true
            })
        } else {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: CommonUtil.getFloatValue(value.substring(0, value.length - 1), true),
                },
                formDirty: true
            })
        }
    },
    handleDecimalNumberChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        const re = /^\d+(\.\d{0,3})?$/;
        if (value === '' || re.test(value)) {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value,
                },
                formDirty: true
            })
        } else {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: CommonUtil.getFloatValueWithFixedPrecision(value.substring(0, value.length - 1), 3),
                },
                formDirty: true
            })
        }
    },
    handleFixedNumberChange(event, that) {
        const {
            name,
            value
        } = event.target;
        const {
            attributeObj
        } = that.state;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value,
                },
                formDirty: true
            })
        } else {
            that.setState({
                attributeObj: {
                    ...attributeObj,
                    [name]: value.substring(0, value.length - 1),
                },
                formDirty: true
            })
        }
    },
    handleNumberPaste(event, that) {
        event.preventDefault();
        return false
    },
    handleButtonStatusChange(event, that) {
        const {
            name,
            id
        } = event.target;
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: id
            },
            formDirty: true
        })
    },
    handleRadioButtonsChange(event, that) {
        const { name, value } = event.target;
        const { attributeObj } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: value
            },
            formDirty: true
        })
    },
    handleCheckBoxChange(event, that) {
        const {
            id,
            checked
        } = event.target;
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [id]: checked
            },
            formDirty: true
        })
    },
    handleDropDownChange(event, obj, that) {
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [obj.name]: event.value,
            },
            formDirty: true,
            function() { }
        })
    },
    handleDropDownChangeHeader(event, obj, that) {
        const {
            attributeHeaderObj
        } = that.state;
        that.setState({
            attributeHeaderObj: {
                ...attributeHeaderObj,
                [obj.name]: event.value,
            },
            formDirty: true,
            function() { }
        })
    },
    handleMultiDropDownChangeHeader(value, obj, that) {
        const {
            attributeHeaderObj
        } = that.state;
        that.setState({
            attributeHeaderObj: {
                ...attributeHeaderObj,
                [obj.name]: value
            },
            formDirty: true,
            function() { }
        })
    },
    handleMultiDropDownChange(value, obj, that) {
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [obj.name]: value
            },
            formDirty: true,
            function() { }
        })
    },
    handleSuggestionTextChange(event, name, that) {
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: event.value
            },
            formDirty: true,
            function() { }
        })
    },
    handleDateChange(event, eventId, that) {
        var name = eventId.split('_')[0];
        const {
            attributeObj
        } = that.state;
        that.setState({
            attributeObj: {
                ...attributeObj,
                [name]: event.toDate ? this.getFormattedDate(event.toDate()) : ''
            },
            formDirty: true,
            function() { }
        })
    },
    overlay(event) {
        var overlayTd = document.getElementsByClassName("action");
        for (var i = 0, len = overlayTd.length; i < len; i++) {
            overlayTd[i].style.zIndex = 2
        }
        document.getElementById(event.target.id).closest(".rt-td").style.zIndex = 3;
    },
    getSupplierFacilityLocationList: function (tableDataList) {
        var locationList = [];
        if (tableDataList) {
            for (var i = 0; i < tableDataList.length; i++) {
                for (var j = 0; j < tableDataList[i].locations.length; j++) {
                    locationList.push({
                        ...tableDataList[i].locations[j]
                    });
                }
            }
        }
        return locationList;
    },
    getFacilityLocationList: function (tableDataList) {
        var locationList = [];
        if (tableDataList) {
            for (var i = 0; i < tableDataList.length; i++) {
                locationList.push({
                    facilityName: tableDataList[i].facilityName,
                    facilityId: tableDataList[i].facilityId
                });
            }
        }
        return locationList;
    },
    getAppCurrencyCode: function (dataDictionaryList) {
        let validParams = dataDictionaryList && dataDictionaryList.length > 0 && dataDictionaryList.filter(item => item.dataType == "currencyCode")
        let currencyCode = "";
        if (validParams && validParams.length) {
            currencyCode = validParams[0].label;
        }
        return currencyCode;
    },
    getCommission: function (dataDictionaryList) {
        let validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;;
        let commission = "";
        if (validParams) {
            commission = dataDictionaryList.commission;
        }
        return commission;
    },
    getStateGSTPercentage: function (dataDictionaryList) {
        let validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;;
        let stateGST = null;
        if (validParams) {
            stateGST = dataDictionaryList.stateGST;
        }
        return stateGST;
    },
    getCentralGSTPercentage: function (dataDictionaryList) {
        let validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;;
        let centralGST = null;
        if (validParams) {
            centralGST = dataDictionaryList.centralGST;
        }
        return centralGST;
    },
    getOrgInformationReducer: function (dataDictionaryList) {
        let validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;
        let orgInformation = "";
        if (validParams) {
            orgInformation = dataDictionaryList.orgInformation[0];
        }
        return orgInformation;
    },
    getRowValues: function (data) {
        const rows = Object.keys(data)
            .filter(key => key.startsWith('row_'))
            .map(key => data[key]);

        // Flatten the array of arrays into a single array
        const values = rows.flat();

        // Convert 'null' string to actual null
        return values ? values : []
    },

    splitHeader: function (str) {
        if (str.trim() === "") {
            return [];
        }
        return str ? str.split(',').map(item => item.trim()) : [];
    },
    getOrgInformation: function (dataDictionaryList, facilityId) {
        let validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;
        let orgInformation = "";
        if (validParams && facilityId) {
            orgInformation = this.getFilteredObjFromArray(dataDictionaryList.orgInformation,
                'facility', facilityId);
        } else {
            orgInformation = dataDictionaryList.orgInformation[0];
        }
        return orgInformation;
    },
    getTrackInfo: function (dataDictionaryList) {
        var validParams = dataDictionaryList != null &&
            dataDictionaryList.dataDictionaryList != null;
        var trackInfo = "";
        if (validParams) {
            trackInfo = dataDictionaryList.track;
        }
        return trackInfo;
    },
    getDataDictionaryTermsCondtion: function (dataDictionaryList, facilityId) {
        let tempList = [];
        let orgInformation = this.getOrgInformation(dataDictionaryList, facilityId);
        if (orgInformation && orgInformation.termsDictionaryList) {
            tempList = CommonUtil.getOptionsFromList(orgInformation.termsDictionaryList,
                'termsContainerText', 'termsContainerHeader', null, true);
        }
        return tempList;
    },
    getDropDownOptionsFromDictionary: function (attributeList, dataDictionaryList) {
        var validParams = attributeList != null && attributeList.length > 0 &&
            dataDictionaryList != null && dataDictionaryList.length > 0;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                if (attributeList[i].dataDictionaryFlag) {
                    if (attributeList[i].isTermConditionFlag) {
                        tempAttributeList[i].options = this.getDataDictionaryTermsCondtion(dataDictionaryList);
                    } else {
                        tempAttributeList[i].options = dataDictionaryList.filter(item => item.dataType == attributeList[i].dataDictionaryId);
                    }
                }
            }
            return tempAttributeList;
        }
        return attributeList;
    },
    getDictionaryListByKey: function (dataDictionaryList, dataDictionaryId) {
        let validParams = dataDictionaryList != null && dataDictionaryList.length > 0;
        let tempAttributeList = [];
        if (validParams) {
            tempAttributeList = dataDictionaryList.filter(item => item.dataType == dataDictionaryId).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        }
        return tempAttributeList;
    },
    getMultipleDictionaryListByKey: function (dataDictionaryList, dataDictionaryIds) {
        let validParams = dataDictionaryList != null && dataDictionaryList.length > 0 && Array.isArray(dataDictionaryIds) && dataDictionaryIds.length > 0;
        let tempAttributeList = [];
        if (validParams) {
            tempAttributeList = dataDictionaryList
                .filter(item => dataDictionaryIds.includes(item.dataType))
                .sort((a, b) => a.label.localeCompare(b.label));
        }
        return tempAttributeList;
    },
    getXlsResponseDownloadFile: function (response, fileName) {
        let raw = window.atob(response);
        let uInt8Array = new Uint8Array(raw.length);
        for (let i = 0; i < raw.length; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        const link = document.createElement('a');
        const blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    buildLinkAndDownLoadFile: function (fileUrl) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    saveAs: function (blob, filename) {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
    },
    downloadPdf: async (pdfUrl, filename, saveAs) => {
        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            saveAs(blob, filename);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    },
    setDisabledPropertyInAttribute: function (attributeList, attributeNameList, value) {
        var validParams = attributeList != null && attributeList.length > 0 && value != null;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                for (let j = 0; j < attributeNameList.length; j++) {
                    if (attributeList[i].name == attributeNameList[j]) {
                        tempAttributeList[i].isCustomDisabled = value;
                    }
                }
            }
            return tempAttributeList;
        }
        return attributeList;
    },
    setPlaceholderPropertyInAttribute: function (attributeList, attributeNameList, value) {
        var validParams = attributeList != null && attributeList.length > 0 && value != null;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                for (let j = 0; j < attributeNameList.length; j++) {
                    if (attributeList[i].name == attributeNameList[j]) {
                        tempAttributeList[i].placeholder = value;
                    }
                }
            }
            return tempAttributeList;
        }
        return attributeList;
    },
    setTypePropertyInAttribute: function (attributeList, attributeNameList, value) {
        var validParams = attributeList != null && attributeList.length > 0 && value != null;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                for (let j = 0; j < attributeNameList.length; j++) {
                    if (attributeList[i].name == attributeNameList[j]) {
                        tempAttributeList[i]["type"] = value;
                    }
                }
            }
            return tempAttributeList;
        }
        return attributeList;
    },
    updateAttributePropertyValue: function (attributeList, attributeName, propertyKey, value) {
        var validParams = attributeList != null && attributeList.length > 0 && value != null;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                if (attributeList[i].name == attributeName) {
                    tempAttributeList[i][propertyKey] = value;
                }
            }
            return tempAttributeList;
        }
        return attributeList;
    },
    setDropDownOptionsInAttribute: function (attributeList, optionsList, attributeName) {
        var validParams = attributeList != null && attributeList.length > 0 && optionsList != null;
        if (validParams) {
            var tempAttributeList = [...attributeList];
            for (var i = 0; i < attributeList.length; i++) {
                if (attributeList[i].name == attributeName) {
                    tempAttributeList[i].options = optionsList;
                }
            }
            return tempAttributeList;
        }
    },
    setDropDownOptionsInAttributeList: function (attributeList, optionsList, attributeNameList) {
        let validParams = attributeList != null && attributeList.length > 0 && optionsList != null;
        if (validParams) {
            let tempAttributeList = [...attributeList];
            for (let i = 0; i < attributeList.length; i++) {
                for (let j = 0; j < attributeNameList.length; j++) {
                    if (attributeList[i].name == attributeNameList[j]) {
                        tempAttributeList[i].options = optionsList;
                    }
                }
            }
            return tempAttributeList;
        }
    },
    getDropDownOptionsFromList: function (responseArrList, optionValue, optionLabel) {
        let optionArray = []
        if (responseArrList && responseArrList.Items && responseArrList.Items.length > 0) {
            responseArrList.Items.forEach(item => {
                let obj = {
                    label: item[optionValue] + " | " + item[optionLabel],
                    value: item[optionLabel],
                }
                optionArray.push(obj)
            })
        }
        return optionArray
    },
    getDropDownOptionsFromTableList: function (responseArrList, optionValue, optionLabel) {
        let optionArray = []
        if (responseArrList && responseArrList.Items && responseArrList.Items.length > 0) {
            responseArrList.Items.forEach(item => {
                let obj = {
                    label: item[optionValue] + " | " + item[optionLabel],
                    value: item[optionValue] + " | " + item[optionLabel],
                }
                optionArray.push(obj)
            })
        }
        return optionArray
    },
    getDropDownOptionsFromModuleList: function (responseArrList, optionValue, optionLabel) {
        let optionArray = []
        if (responseArrList && responseArrList.Items && responseArrList.Items.length > 0) {
            responseArrList.Items.forEach(item => {
                let obj = {
                    label: item[optionValue] + " | " + item[optionLabel],
                    value: item[optionValue] + "-" + item[optionLabel],
                }
                optionArray.push(obj)
            })
        }
        return optionArray
    },
    getFilteredObjFromArray: function (tempArrayList, filterKey, value) {
        let filteredList = [];
        if (this.isNotNull(tempArrayList) && tempArrayList.length > 0) {
            filteredList = tempArrayList.filter(function (tempObj) {
                return tempObj[filterKey] == value;
            });
        }
        if (filteredList.length > 0) {
            return filteredList[0];
        }
        return null;
    },
    getFilteredListFromArray: function (tempArrayList, filterKey, value) {
        let filteredList = [];
        if (this.isNotNull(tempArrayList) && tempArrayList.length > 0) {
            filteredList = tempArrayList.filter(function (tempObj) {
                return tempObj[filterKey] == value;
            });
        }
        return filteredList;
    },
    removeObjFromArray: function (tempArrayList, filterKey, value) {
        let filteredList = [];
        if (this.isNotNull(tempArrayList) && tempArrayList.length > 0) {
            filteredList = tempArrayList.filter(function (tempObj) {
                return tempObj[filterKey] != value;
            });
        }
        return filteredList;
    },
    updateArrayListObjValue: function (tempArrayList, filterKey, filterValue, updateKey, updateValue) {
        let filteredList = [];
        if (this.isNotNull(tempArrayList) && tempArrayList.length > 0) {
            for (let i = 0; i < tempArrayList.length; i++) {
                let tempObj = tempArrayList[i];
                if (tempObj[filterKey] == filterValue) {
                    tempObj[updateKey] = updateValue;
                }
                filteredList.push(tempObj);
            }
        }

        return filteredList;
    },
    getFloatValue: function (value, fixedPointFlag) {
        if (fixedPointFlag) {
            return this.isNaNValue(+parseFloat(value).toFixed(2));
        } else {
            return this.isNaNValue(parseFloat(value));
        }
    },
    getFloatValueWithFixedPrecision: function (value, precision) {
        if (precision) {
            return this.isNaNValue(+parseFloat(value).toFixed(precision));
        } else {
            return this.isNaNValue(parseFloat(value));
        }
    },
    getTotalValue: function (firstValue, secondValue, fixedPointFlag) {
        return this.getFloatValue(this.getFloatValue(firstValue) +
            this.getFloatValue(secondValue), fixedPointFlag)
    },
    getOptionsFromList: function (responseArrList, optionValue, optionLabel, status, isOnlyLabel, prefixCode) {
        let optionArray = []
        if (responseArrList && responseArrList.length > 0) {
            responseArrList.forEach(item => {
                if (item[status] != null && item[status] == 'active') {
                    let obj = {
                        label: prefixCode + item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
                if (item[status] == null) {
                    let obj = {
                        label: isOnlyLabel ? (prefixCode ? prefixCode : "") + item[optionLabel] : (prefixCode ? prefixCode : "") + item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
            })
        }
        return optionArray
    },
    getOptionsFromListWithAll: function (responseArrList, optionValue, optionLabel, status, isOnlyLabel) {
        let optionArray = [{ "label": "All", "value": "all" }]
        if (responseArrList && responseArrList.length > 0) {
            responseArrList.forEach(item => {
                if (item[status] != null && item[status] == 'active') {
                    let obj = {
                        label: item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
                if (item[status] == null) {
                    let obj = {
                        label: isOnlyLabel ? item[optionLabel] : item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
            })
        }
        return optionArray
    },
    getOptionsFromListWithAll: function (responseArrList, optionValue, optionFirstName, optionLastName, status, isOnlyLabel) {
        let optionArray = [{ "label": "All", "value": "all" }];
        if (responseArrList && responseArrList.length > 0) {
            responseArrList.forEach(item => {
                if (item[status] != null && item[status] == 'active') {
                    let obj = {
                        label: item[optionFirstName] + " " + item[optionLastName],
                        value: item[optionValue]
                    };
                    optionArray.push(obj);
                }
                if (item[status] == null) {
                    let obj = {
                        label: isOnlyLabel ? item[optionFirstName] + " " + item[optionLastName] : item[optionFirstName] + " " + item[optionLastName],
                        value: item[optionValue]
                    };
                    optionArray.push(obj);
                }
            });
        }
        return optionArray;
    },
    getOptionsFromListWithAllUser: function (responseArrList, optionValue, optionLabel, optionLabel2, status, isOnlyLabel) {
        let optionArray = [{ "label": "All", "value": "all" }]
        if (responseArrList && responseArrList.length > 0) {
            responseArrList.forEach(item => {
                if (item[status] != null && item[status] == 'active') {
                    let obj = {
                        label: item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
                if (item[status] == null) {
                    let obj = {
                        label: isOnlyLabel ? item[optionLabel] + " " + item[optionLabel2] : item[optionValue] + " | " + item[optionLabel],
                        value: item[optionValue]
                    }
                    optionArray.push(obj)
                }
            })
        }
        return optionArray
    },
    uniqueArrayList: function (optionArray) {
        var uniqueOptionArray = [];
        optionArray.forEach(function (item) {
            var i = uniqueOptionArray.findIndex(x => x.value == item.value);
            if (i <= -1) {
                uniqueOptionArray.push({ label: item.value, value: item.value });
            }
        });
        return uniqueOptionArray
    },
    getSelectedOptionLabelForManagePage: function (dropDownOptions, selectedValue) {
        let selectedOption = null;
        if (this.isNotNull(dropDownOptions) && dropDownOptions.length > 0 && selectedValue) {
            selectedOption = dropDownOptions.filter(function (optionObj) {
                return optionObj["value"] == selectedValue;
            });
        }
        if (selectedOption && selectedOption.length > 0) {
            return selectedOption && selectedOption.length > 0 && selectedOption[0].label;
        } else {
            return selectedValue;
        }
    },
    getSelectedOptionLabel: function (dropDownOptions, selectedValue) {
        let selectedOption = null;
        if (this.isNotNull(dropDownOptions) && dropDownOptions.length > 0 && selectedValue) {
            selectedOption = dropDownOptions.filter(function (optionObj) {
                return optionObj["value"] == selectedValue;
            });
        }
        if (selectedOption && selectedOption.length > 0) {
            return selectedOption && selectedOption.length > 0 && { "label": selectedOption[0].label };
        } else {
            return { "label": selectedValue };
        }
    },
    getSelectedOptionTitle: function (dropDownOptions, selectedValue) {
        let selectedOption = null;
        if (this.isNotNull(dropDownOptions) && dropDownOptions.length > 0 && selectedValue) {
            selectedOption = dropDownOptions.filter(function (optionObj) {
                return optionObj["value"] == selectedValue;
            });
        }
        if (selectedOption && selectedOption.length > 0) {
            return selectedOption && selectedOption.length > 0 && selectedOption[0].label;
        } else {
            return selectedValue;
        }
    },
    isAddRowEnable: function (actualArrayList, actFilterKey, selectedArrayList, selFilterKey) {
        let enableAddRow = false;
        if (!this.isNullArray(actualArrayList) && !this.isNullArray(selectedArrayList)) {
            for (let j = 0; j < actualArrayList.length; j++) {
                let selectedObj = null;
                selectedObj = selectedArrayList.filter(function (tempObj) {
                    return tempObj[selFilterKey] == actualArrayList[j][actFilterKey];
                });
                if (this.isNullValue(selectedObj) || this.isNullArray(selectedObj)) {
                    enableAddRow = true;
                }
            }
        }
        if (selectedArrayList && selectedArrayList.length == 0) {
            enableAddRow = true;
        }
        return enableAddRow;
    },
    getDocumentIcon: function (fileType) {
        if (this.isBothEqual(fileType, "application/pdf")) {
            return pdf;
        }
        else if (this.isBothEqual(fileType, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            return excel;
        }
        else if (this.isBothEqual(fileType, "application/vnd.ms-excel")) {
            return excel;
        }
        else if (this.isBothEqual(fileType, "text/csv")) {
            return csv;
        }
        else if (this.isBothEqual(fileType, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            return docx;
        }
        else if (this.isBothEqual(fileType, "application/msword")) {
            return docx;
        }
        else if (this.isBothEqual(fileType, "image/jpeg")) {
            return image;
        }
        else if (this.isBothEqual(fileType, "image/jpg")) {
            return image;
        }
        else if (this.isBothEqual(fileType, "image/png")) {
            return image;
        } else {
            return file;
        }
    },
    maxLengthCheck: function (object, maxLength) {
        if (object.target.value.length > maxLength) {
            object.target.value = object.target.value.slice(0, maxLength)
        }
    },
    prepareBillCreatedSuccessPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Bill created successfully.";
        return popupConfig;
    },
    prepareInvoiceCreatedSuccessPopUpConfig: function () {
        var popupConfig = {};
        popupConfig.delayTime = 5;
        popupConfig.color = 1;
        popupConfig.position = "tc";
        popupConfig.message = "Invoice created successfully.";
        return popupConfig;
    },
    getImageBlobFromUrl: function (url) {
        const image = axios
            .get(url, {
                responseType: 'blob',
            })
            .then((response) => window.URL.createObjectURL(new Blob([response.data])))
            .catch(err => window.URL.createObjectURL(new Blob([''])));
        return image;
    },
    getSelectedOptionNameFromCode: function (dropDownOptions, selectedValue) {
        let selectedOption = null;
        if (this.isNotNull(dropDownOptions) && dropDownOptions.length > 0 && selectedValue) {
            selectedOption = dropDownOptions.filter(function (optionObj) {
                return optionObj["value"] == selectedValue;
            });
        }
        if (selectedOption && selectedOption.length > 0) {
            return selectedOption && selectedOption.length > 0 ? selectedOption[0].label : selectedValue;
        }
    },

};

export default CommonUtil;