import newAxios from "../../../axios/axios";
import * as billingActionTypes from "./billingActionTypes";

import {
    PLANS_CHECKOUT_URL,
    BILLING_PUBLISHABLE_KEY,
    GET_PRICES,
    USER_IS_ACTIVE
} from "../constants/billingConstants";

import {
    beginAjaxCall,
    endAjaxCall
} from "../../../actions/ajaxStatusActions";
import {
    loadStripe
} from "@stripe/stripe-js";
console.log("Stripe object");

export function checkoutPlans(priceId) {
    return (dispatch) => {
        dispatch(beginAjaxCall());

        loadStripe(BILLING_PUBLISHABLE_KEY).then(response => {
            let stripe = response;
            newAxios.post(PLANS_CHECKOUT_URL, {
                quantity: 1,
                price: priceId
            }).then(session => {
                console.log(session);
                stripe.redirectToCheckout({
                    sessionId: session.data.id
                });
                dispatch(endAjaxCall());
            }).catch(error => {
                console.log(error);
                dispatch(endAjaxCall());
            });
        })

    }
}

export function getPrices(interval) {
    return (dispatch) => {
        dispatch(beginAjaxCall());
        newAxios.get(`${GET_PRICES}?interval=${interval}`).then(response => {
            dispatch({
                type: billingActionTypes.PRICES_RECEIVED,
                payload: response.data.data
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: billingActionTypes.PRICES_ERROR,
                payload: error
            });
            dispatch(endAjaxCall());
        })
    }
}

export function isPlanActive() {
    return (dispatch) => {
        dispatch(beginAjaxCall());
        newAxios.get(USER_IS_ACTIVE).then(response => {
            dispatch({
                type: billingActionTypes.IS_PLAN_ACTIVE_RECEIVED,
                payload: response.data
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: billingActionTypes.IS_PLAN_ACTIVE_ERROR,
                payload: error
            });
            dispatch(endAjaxCall());
        })
    }
}