export const SET_SELECTED_STAGE_TYPE ="SET_SELECTED_STAGE_TYPE";

export const SET_SELECTED_SOURCING_SUB_STAGE_TYPE ="SET_SELECTED_SOURCING_SUB_STAGE_TYPE";

export const SET_SELECTED_SUB_STAGE_TYPE ="SET_SELECTED_SUB_STAGE_TYPE";
export const SET_SELECTED_STAGE_ID ="SET_SELECTED_STAGE_ID";
export const SET_PIPELINE_SAVE_FLAG ="SET_PIPELINE_SAVE_FLAG";
export const SET_PIPELINE_MODAL ="SET_PIPELINE_MODAL";
export const SET_PIPELINE_CLASSIFICATION ="SET_PIPELINE_CLASSIFICATION";
export const SET_PIPELINE_STAGE_LIST ="SET_PIPELINE_STAGE_LIST";
export const SET_PIPELINE_SUB_STAGE_LIST ="SET_PIPELINE_SUB_STAGE_LIST";

export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";

export const SET_SELECTED_PIPELINE_CODE = "SET_SELECTED_PIPELINE_CODE";
export const SET_PIPELINE_DETAILS = "SET_PIPELINE_DETAILS";
export const SET_PIPELINE_BATCH_ACTION_MODE = "SET_PIPELINE_BATCH_ACTION_MODE";

export const GET_PIPELINE_LIST = "GET_PIPELINE_LIST";
export const GET_PIPELINE_DETAILS = "GET_PIPELINE_DETAILS";
export const GET_GENERATED_PIPELINE_CODE = "GET_GENERATED_PIPELINE_CODE";
