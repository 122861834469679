import * as productActionTypes from "../actions/productActionTypes";
import * as supplierActionTypes from "../../supplierManagement/actions/supplierActionTypes";

const initialState = {
  productDetails: null,
  productList: null,
  skuNumber: null,
  productGrossList: null,
  selectedProductCode: null,
  productDetailList: null,
  mappedProductList: null,
  selectedCategoryCode: null,
  selectedFacilityId:null,
  toLocationProductList:null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case productActionTypes.SET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload
      };
    case productActionTypes.GET_SKU_DETAILS:
      return {
        ...state,
        skuNumber: action.payload
      };
    case productActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload
      };
    case productActionTypes.GET_PRODUCT_GROSS_LIST:
      return {
        ...state,
        productGrossList: action.payload
      };
    case productActionTypes.SET_SELECTED_PRODUCT_CODE:
      return {
        ...state,
        selectedProductCode: action.payload
      };
    case productActionTypes.GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload
      };
    case supplierActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierDetails: action.payload
      };
    case productActionTypes.GET_PRODUCT_DETAILS_LIST:
      return {
        ...state,
        productDetailList: action.payload
      }
    case productActionTypes.GET_MAPPED_PRODUCT_LIST:
      return {
        ...state,
        mappedProductList: action.payload
      };
    case productActionTypes.SET_SELECTED_CATEGORY_CODE:
      return {
        ...state,
        selectedCategoryCode: action.payload
      };
    case productActionTypes.SET_SELECTED_FACILITY_ID:{
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    }
    case productActionTypes.GET_PRODUCT_TO_LOCATION_LIST:
      return {
        ...state,
        toLocationProductList: action.payload
      };
    default:
      return state;
  }
};

export default productReducer;
