import {
    Auth
} from "aws-amplify";
import mixpanel from "../../analytics/mixpanel/mixpael";
import {
    beginAjaxCall,
    updateApiResponse,
    endAjaxCall,
    failedApiResponse,
    setAjaxCallStatus
} from "../../../actions/ajaxStatusActions";
import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    CLEAR_LOGIN_ERROR_MESSAGE,
    CLEAR_SIGNUP_ERROR_MESSAGE,
    CHECK_IF_TENANT_EXISTS_ERROR,
    CHECK_IF_TENANT_EXISTS,
} from "../../../actions/actionTypes";
import {
    updateUserStatus
} from "../../userManagement/actions/userActions";
import {
    JWT_TOKEN_URL,
    USER_CHECK_BASE_URL,
    TENANT_CHECK_BASE_URL,
    GET_CONFIG_BASE_URL,
    GET_SHOPIFY_DETAILS,
    GET_QUOTATION_STATUS_URL,
    CHARGE_CREDIT_CARD,
    GET_SO_DETAILS
} from "../constants/securityConstants";
import axios from 'axios';
import newAxios from "../../../axios/axios";
import * as userActionTypes from './userActionsType';
import jwt from 'jsonwebtoken';
import {
    client as Elevio
} from "elevio";
import dayjs from 'dayjs';
import {
    SESSION_TIMEOUT
} from 'constants/GlobalConstants';
import CommonUtil from "../../common/util/commonUtil";

export function getUIPermissionMapping() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        newAxios.get(`${GET_CONFIG_BASE_URL}/ui_components_permissions_mapping`).then(response => {
            dispatch({
                type: userActionTypes.GET_UI_COMPONENTS_PERMISSION_MAPPING,
                payload: response.data
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: userActionTypes.GET_UI_COMPONENTS_PERMISSION_MAPPING_ERROR,
                payload: error
            });
            dispatch(
                endAjaxCall()
            );
        })
    }
}
export function login(username, password) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        dispatch({
            type: CLEAR_LOGIN_ERROR_MESSAGE
        });
        mixpanel.identify(username);
        const signInResponse = Auth.signIn(username, password);
        signInResponse.then((response) => {
            console.log(response);
            dispatch({
                type: userActionTypes.COGNITO_AUTHENTICATION_SUCCESS,
                payload: response
            });
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                dispatch(endAjaxCall());
                dispatch({
                    type: userActionTypes.NEW_PASSWORD_CHALLENGE,
                    payload: response
                });
            } else if (!response.challengeName) {
                mixpanel.track('Login successfull');

                const jwtToken = response.signInUserSession.idToken.jwtToken;
                return axios.get(JWT_TOKEN_URL, {
                    headers: {
                        Authorization: jwtToken
                    }
                }).then(tokenApiResponse => {
                    console.log("set session timeout");
                    const now = dayjs();
                    const timeout = dayjs(now).add(SESSION_TIMEOUT, "hour");
                    localStorage.setItem('timeout', timeout);
                    localStorage.setItem('apiJwtToken', jwtToken);
                    const uiToken = jwt.decode(tokenApiResponse.data.token, {
                        complete: true
                    });
                    localStorage.setItem('uiToken', tokenApiResponse.data.token);
                    const userId = `${uiToken.payload.tenantid} ${username}`;
                    mixpanel.people.set({
                        $name: userId,
                        $email: username
                    });
                    mixpanel.identify(userId);
                    localStorage.setItem('isAuthenticated', 'true');
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: uiToken
                    });
                    dispatch(endAjaxCall());
                    // Elevio.enable();
                }).catch(error => {
                    const message = 'Access denied, please contact your administrator.';
                    localStorage.setItem('isAuthenticated', 'false');
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: message
                    });
                    dispatch(endAjaxCall());
                })
            }

        })
            .catch((error) => {
                let message = "";
                mixpanel.track('Login failed!');
                //error.message === 'Incorrect username or password.' ? 'Incorrect email or password.' : error.message;
                if (error.message === 'Incorrect username or password.') {
                    message = 'Incorrect email or password.';
                } else if (error.message === 'User is disabled.') {
                    message = 'Access denied, please contact your administrator.';
                } else {
                    message = error.message;
                }
                localStorage.setItem('isAuthenticated', 'false');
                dispatch({
                    type: LOGIN_ERROR,
                    payload: message
                });
                dispatch(endAjaxCall());
            });
    }
}


export function signUp(formValues) {
    const {
        username,
        firstName,
        lastName,
        phoneNumber,
        companyName,
        requestSource,
        shop,
        access_token,
    } = formValues;
    return function (dispatch) {
        dispatch(beginAjaxCall());
        dispatch({
            type: CLEAR_SIGNUP_ERROR_MESSAGE
        });
        dispatch({
            type: userActionTypes.CHECK_IF_TENANT_EXISTS
        });
        // axios.get(`${TENANT_CHECK_BASE_URL}/${companyName}`).then(tenantCheckresponse => {
        //     if (tenantCheckresponse.data.exists) {
        //         dispatch({
        //             type: userActionTypes.CHECK_IF_TENANT_EXISTS_ERROR
        //         });
        //         dispatch(endAjaxCall());
        //     } else {
        //     }
        // })
        const signUpResponse = Auth.signUp({
            ...formValues,
            attributes: {
                email: username,
            },
            clientMetadata: {
                username,
                firstName,
                lastName,
                companyName,
                phoneNumber,
                requestSource,
                shop,
                access_token
            },
            custom: companyName
        });
        signUpResponse.then((response) => {
            dispatch({
                type: SIGNUP_SUCCESS
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: SIGNUP_ERROR,
                payload: error.message
            });
            dispatch(endAjaxCall());
        });
    }
}

export function setNewPasswordWithSecurityCode({
    newPassword,
    securityCode,
    username
}) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        Auth.forgotPasswordSubmit(username, securityCode, newPassword).then(() => {
            dispatch({
                type: userActionTypes.FORGOT_PASSWORD_FLOW_SUCCESS
            });
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch({
                type: userActionTypes.FORGOT_PASSWORD_FLOW_ERROR,
                payload: error.message
            });
            dispatch(endAjaxCall());
        })
    }
}

export function forgotPassword(username) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        dispatch({
            type: userActionTypes.CLEAR_FORGOT_PASSWORD
        });
        axios.get(`${USER_CHECK_BASE_URL}/${username}`).then(response => {
            if (response.data.exists === true) {
                if (response.data.status === "confirmed") {
                    Auth.forgotPassword(username).then(() => {
                        dispatch({
                            type: userActionTypes.FORGOT_PASSWORD_CODE_SENT
                        });
                        dispatch(endAjaxCall());
                    }).catch((error) => {
                        dispatch({
                            type: userActionTypes.FORGOT_PASSWORD_CODE_ERROR,
                            payload: error.message
                        });
                        dispatch(endAjaxCall());
                    })
                } else {
                    dispatch({
                        type: userActionTypes.FORGOT_PASSWORD_CODE_ERROR,
                        payload: 'User is not confirmed'
                    });
                    dispatch(endAjaxCall());
                }
            } else {
                dispatch({
                    type: userActionTypes.FORGOT_PASSWORD_CODE_ERROR,
                    payload: 'Email address is not registered'
                });
                dispatch(endAjaxCall());
            }
        }).catch(error => {
            dispatch({
                type: userActionTypes.FORGOT_PASSWORD_CODE_ERROR,
                payload: 'Error occured while fetching user details'
            });
            dispatch(endAjaxCall());
        })

    }
}

export function clearForgotPassword() {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.CLEAR_FORGOT_PASSWORD
        });
    }
}

export function changePassword({
    oldPassword,
    newPassword
}) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        dispatch({
            type: userActionTypes.CLEAR_CHANGE_PASSWORD
        });
        Auth.currentAuthenticatedUser().then(user => {
            Auth.changePassword(user, oldPassword, newPassword).then(response => {

                dispatch({
                    type: userActionTypes.CHANGE_PASSWORD_SUCCESS
                });

                dispatch(endAjaxCall());
            }).catch(error => {
                if (error.message === 'Incorrect username or password.') {
                    dispatch({
                        type: userActionTypes.CHANGE_PASSWORD_FAILURE,
                        payload: 'Incorrect old password.'
                    });
                } else {
                    dispatch({
                        type: userActionTypes.CHANGE_PASSWORD_FAILURE,
                        payload: error.message
                    });
                }
                dispatch(endAjaxCall());
            })
        }).catch(error => {
            dispatch({
                type: userActionTypes.CHANGE_PASSWORD_FAILURE,
                payload: error.message
            });
            dispatch(endAjaxCall());
        })
    }
}

export function setNewPassword({
    newPassword
}, currentTempUser) {
    return function (dispatch) {
        const {
            requiredAttributes
        } = currentTempUser.challengeParam;
        clearNewPasswordMessages();
        dispatch(beginAjaxCall());
        const authPromise = Auth.completeNewPassword(currentTempUser, newPassword, requiredAttributes, {});
        authPromise.then(response => {
            dispatch(endAjaxCall());
            dispatch(updateUserStatus(null, currentTempUser, true));
            dispatch({
                type: userActionTypes.SET_NEW_PASSWORD_SUCCESS
            });
        }).catch(error => {
            dispatch(endAjaxCall());
            dispatch({
                type: userActionTypes.SET_NEW_PASSWORD_ERROR,
                payload: error.message
            });
        });
    }
}

export function clearNewPasswordMessages() {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.CLEAR_NEW_PASSWORD_MESSAGES
        });
    }
}

export function clearSignUpSuccessMessage() {
    return function (dispatch) {
        dispatch({
            type: 'CLEAR_SIGNUP_ERROR_MESSAGE'
        });
    }
}

export function clearChangePasswordMessages() {
    return function (dispatch) {
        dispatch({
            type: userActionTypes.CLEAR_CHANGE_PASSWORD
        });
    }
}

export function getShopifyDetails(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.post(GET_SHOPIFY_DETAILS, params).then(response => {
            if (response.status == 200) {
                // console.log("getShopifyDetails data :" + JSON.stringify(response.data))
                dispatch({
                    type: userActionTypes.GET_SHOPIFY_DETAILS,
                    payload: response.data
                });
            }
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch(endAjaxCall());
        });
    };
}
export function getQuotationStatus(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(GET_QUOTATION_STATUS_URL, { params: params }).then(response => {
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch(endAjaxCall());
            throw error;
        });
    };
}

export function chargeCreditCard(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.post(CHARGE_CREDIT_CARD,
            params).then(response => {
                console.log(response,"responseeee")
                if (response.status == 200) {
                    dispatch(setAjaxCallStatus(updateApiResponse(response)));
                    dispatch({
                        type: userActionTypes.CHARGE_CREDIT_CARD,
                        payload: response
                    });
                }
                dispatch(endAjaxCall());
            }).catch(error => {
                dispatch(endAjaxCall());
                dispatch(setAjaxCallStatus(failedApiResponse(error)));
            });
    };
}

export function getSoDetail(params) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        axios.get(GET_SO_DETAILS, { params: params }).then(response => {
            if (response.status == 200) {
                dispatch({
                    type: userActionTypes.GET_SO_DETAILS,
                    payload: response.data
                });
            }
            dispatch(endAjaxCall());
        }).catch(error => {
            dispatch(endAjaxCall());
            throw error;
        });
    };
}