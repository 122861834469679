import * as traceabilityActionTypes from "../actions/traceabilityActionTypes";

const initialState = {
  traceabilityDetails: [],
  traceabilityList: null,
  selectedTraceabilityCode: null,
  selectedUtiCode: null,
  selectedStatus: null,
  selectedBatch: null,
  selectedProduct: null,
  selectedOrderNumber: null,
  isRecallWithDraw: null,
};

const traceabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case traceabilityActionTypes.SET_TRACEABILITY_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case traceabilityActionTypes.SET_TRACEABILITY_DETAILS:
      return {
        ...state,
        traceabilityDetails: action.payload,
      };
    case traceabilityActionTypes.SET_RECALL_FLAG:
      return {
        ...state,
        isRecallWithDraw: action.payload,
      };
    case traceabilityActionTypes.GET_TRACEABILITY_LIST:
      return {
        ...state,
        traceabilityList: action.payload,
      };
    case traceabilityActionTypes.SET_SELECTED_TRACEABILITY_CODE:
      return {
        ...state,
        selectedTraceabilityCode: action.payload,
      };
    case traceabilityActionTypes.GET_TRACEABILITY_DETAILS:
      return {
        ...state,
        traceabilityDetails: action.payload,
      };

    case traceabilityActionTypes.SET_SELECTED_PROPERTIES: {
      return {
        ...state,
        selectedUtiCode: action.payload.utiCode,
        selectedStatus: action.payload.status,
        selectedBatch: action.payload.batch,
        selectedProduct: action.payload.product,
        selectedOrderNumber: action.payload.ordernumber
      };
    }

    default:
      return state;
  }
};

export default traceabilityReducer;
