/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

// dinamically create pages routes
import getRoutes from "routes.js";

import bgImage from "assets/img/full-screen-image.png";
import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader.jsx";
import NotificationSystem from "react-notification-system";
// style for notifications
import { style } from "variables/Variables.jsx";
import CommonUtil from "../modules/common/util/commonUtil";
import * as statusConstant from '../modules/common/constant/statusConstant';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
    };
  }

  componentDidMount = async() => {
    await this.setState({ _notificationSystem: this.refs.notificationSystem });
    if(this.props.actionMode == statusConstant.USER_INACTIVE_STATUS) {
      this.handleNotificationClick(CommonUtil.inActiveUserLogoutPopUpConfig());
    }
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  handleNotificationClick = (notificationConfig) => {
    var level;
    switch (notificationConfig.color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-check" />,
      message: <div>{notificationConfig.message}</div>,
      level: level,
      position: notificationConfig.position,
      autoDismiss: notificationConfig.delayTime,
    });
  };

  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/auth/login-page":
        pageClass = " login-page";
        break;
      case "/auth/register-page":
        pageClass = " login-page";
        break;
      case "/auth/forgotpassword":
        pageClass = " login-page";
        break;
      case "/auth/changepassword":
        pageClass = " login-page";
        break;
      case "/auth/newpassword":
        pageClass = " login-page";
        break;
        case "/auth/NewPassword":
        pageClass = " login-page";
        break;
      case "/auth/lock-screen-page":
        pageClass = " lock-page";
        break;
      case "/auth/confirmation":
        pageClass = " confirmation";
        break;
      case "/auth/quotation-confirmation":
        pageClass = " confirmation";
        break;
      case "/auth/cardpayment":
        pageClass = " confirmation";
        break;
      case "/auth/suspended":
        pageClass = " confirmation";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component
                {...routeProps}
                showNotification={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    let isLoading = this.props.ajaxCallsInProgress > 0;
    const track = this.props.track;
    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
        <AuthNavbar />
        <div className="wrapper wrapper-full-page">
          <div
            className={"full-page" + this.getPageClass()}
            data-color="white"
            data-image={bgImage}
          >
            <div className="content">
              <Suspense fallback={<CustomLoader />}>
                <Switch>{this.getRoutes(getRoutes({track}))}</Switch>
              </Suspense>
              {isLoading && <CustomLoader />}
            </div>
            {/*<Footer transparent />*/}
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ajaxCallsInProgress: state.ajaxStatusReducer.ajaxCallsInProgress,
    actionMode: state.app.actionMode,
    track: state.dataDictionary.track,
  };
}

export default connect(mapStateToProps)(Pages);
