import * as userActionTypes from "../actions/userActionTypes";

const initialState = {
  userDetails: null,
  userList: null,
  userFacilityList: null,
  selectedUserCode: null,
  userProfile: {},
  updateProfileError: null,
  userProfileImageUrl: null,
  userProfileImageUrlError: null,
  updateProfileSuccess: false,
  isUserExist: null,
  usersExausted: false,
  userFacilityIdWiseList: null,

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case userActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    case userActionTypes.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload
      };

    case userActionTypes.GET_USER_FACILITY_LIST:
      return {
        ...state,
        userFacilityList: action.payload
      };
    case userActionTypes.SET_SELECTED_USER_CODE:
      return {
        ...state,
        selectedUserCode: action.payload
      };
    case userActionTypes.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    case userActionTypes.GET_USER_PROFILE: {
        return {
            ...state,
            userProfile: action.payload,
            updateProfileSuccess: false
        }
    }
    case userActionTypes.UPDATE_USER_PROFILE: {
        return {
            ...state,
            updateProfileError: null,
            updateProfileSuccess: true,
        }
    }
    case userActionTypes.UPDATE_USER_PROFILE_ERROR: {
        return {
            ...state,
            updateProfileError: action.payload,
            updateProfileSuccess: false
        }
    }
    case userActionTypes.SET_USER_PROFILE_URL: {
        return {
            ...state,
            userProfileImageUrl: action.payload,
            userProfile: {
                ...state.userProfile,
                profileImage: action.payload.documentURL
            },
            updateProfileSuccess: true
        }
    }
    case userActionTypes.SET_USER_PROFILE_URL_ERROR: {
        return {
            ...state,
            userProfileImageUrl: null,
            userProfileImageUrlError: action.payload,
            updateProfileSuccess: false,
        }
    }
    case userActionTypes.CLEAR_USER_PROFILE_URL: {
        return {
            ...state,
            userProfileImageUrl: null,
            userProfileImageUrlError: null
        }
    }
    case userActionTypes.CHECK_USER_EXIST: {
        return {
            ...state,
            isUserExist: action.payload
        }
    }
    case userActionTypes.USERS_EXHAUSTED: {
        return {
            ...state,
            usersExausted: true
        }
    }
    case userActionTypes.GET_USER_FACILITY_NEW_LIST:
      return {
        ...state,
        userFacilityIdWiseList: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
