export const SET_SELECTED_PRODUCT_CODE = "SET_SELECTED_PRODUCT_CODE";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_LIST = "GET_PRODUCT_DETAILS_LIST";
export const GET_PRODUCT_GROSS_LIST = "GET_PRODUCT_GROSS_LIST";
export const GET_SKU_DETAILS = "GET_SKU_DETAILS";
export const GET_MAPPED_PRODUCT_LIST = "GET_MAPPED_PRODUCT_LIST";
export const SET_SELECTED_CATEGORY_CODE = "SET_SELECTED_CATEGORY_CODE";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";
export const GET_PRODUCT_TO_LOCATION_LIST = "GET_PRODUCT_TO_LOCATION_LIST";

