export const CREATE_MFG_ORDER_HEADER_TITLE = "Create Manufacturing Order";
export const EDIT_MFG_ORDER_HEADER_TITLE = "Edit Manufacturing Order";
export const VIEW_MFG_ORDER_HEADER_TITLE = "View Manufacturing Order";
export const MANAGE_MFG_ORDER_HEADER_TITLE = "Manufacturing Order";
export const MODULE_NAME = "MfgOrder";
export const SUBMODULE_NAME = "Ingredient/Operation.";
export const ING_OPT_VALIDATION_MSG = "Selected product does not have any ingredient and operations associated. Please add atleast one ingredient and one operation to the product OR choose manually from the table.";
export const ING_PRICE_VALIDATION_MSG = "One or All ingredients used in the manufacturing order data is incomplete, kindly update supplier and price details.";

export const INVACTIVE_ING_MSG = "Ingredient ( ING-LIST ) are inactive. Do you still want to continue with Manufacturing Order?";

export const CREATE_MFG_ORDER = "createMfgOrder";
export const DEATIVATE_MFG_ORDER = "deActivate";
export const ACTIVATE_MFG_ORDER = "activate";

export const CREATE_MFG_ORDER_PAGE_URL = '/admin/create-mfg-order';
export const MANAGE_MFG_ORDER_PAGE_LIST = '/admin/manage-mfg-order';
export const CREATE_PO_ORDER_PAGE_URL = '/admin/create-purchase-order';
export const CREATE_MO_ORDER_PAGE_URL = '/admin/create-mfgOrder';
export const MANAGE_DOCUMENT_PAGE_URL = "/admin/manage-documents";

export const CREATE_PO = 'createPurchaseOrder';
export const CREATE_MO = 'createManageOrder';
export const CREATE_PAGE = "createPage";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GET_ORDERS_VIEW_DATA = `${BASE_URL}/mfgorder/orders`;
export const GET_ETA_BASE_URL = `${BASE_URL}/resources`;
export const GET_RESOURCE_VIEW_DATA = `${BASE_URL}/mfgorder/resources`;
export const UPDATE_OPERATION_STTAUS_URL = `${BASE_URL}/mfgorder/operationstatus`;
export const GET_RECEIVED_STOCK_LIST_URL = `${BASE_URL}/mfgorder/stocks/`;
export const GET_MFG_ORDER_LIST_URL = `${BASE_URL}/mfgorder`;
export const GET_MFG_ORDER_DETAILS_URL = `${BASE_URL}/mfgorder/`;
export const GET_MFG_BATCH_DETAILS_URL = `${BASE_URL}/mfgorder/batch/`;
export const GET_MFG_ORDER_PRIORITY_DETAILS_URL = `${BASE_URL}/mfgorder/priority`;
export const SET_UPDATE_MFG_ORDER_PRIORITY_URL = `${BASE_URL}/mfgorder/priority`;
export const SET_CREATE_MFG_ORDER_DETAILS_URL = `${BASE_URL}/mfgorder/`;
export const SET_UPDATE_MFG_ORDER_DETAILS_URL = `${BASE_URL}/mfgorder/`;
export const SET_UPDATE_MFG_ORDER_STATUS_URL = `${BASE_URL}/mfgorder/`;
export const GET_MFG_ORDER_REPORT_LIST_URL = `${BASE_URL}/mfgorder/reports`;
export const GET_MFG_ORDER_OTIF_LIST_URL = `${BASE_URL}/mfgorder/otif`;
export const DOWNLOAD_XLS_FILE_URL = `${BASE_URL}/mfgorder/downloadxls`;
export const GET_MFG_ORDER_SEARCH_URL = BASE_URL + '/mfgorder/';
export const DOWNLOAD_INVOICE_URL = `${BASE_URL}/mfgorder/downloadInvoice/`;

export const dataTable = [];

export const INGREDIENT_TABLE_NAME = 'INGREDIENTS';
export const OPERATION_TABLE_NAME = 'OPERATIONS';

export const MANAGE_PAGE_TAB_LIST = [
    {
        key: "OPEN",
        title: "OPEN"
    },
    {
        key: "COMPLETED",
        title: "COMPLETED"
    },
    {
        key: "DRAFT",
        title: "DRAFT"
    },
];

export const MANAGE_PAGE_TAB_LIST_WITHOUT_DRAFT = [
    {
        key: "OPEN",
        title: "OPEN"
    },
    {
        key: "COMPLETED",
        title: "COMPLETED"
    },
];

export const MANAGE_PAGE_RESOURCE_TAB_LIST = [
    {
        key: "RESOURCEVIEW",
        title: "RESOURCE VIEW"
    },
    {
        key: "ORDERVIEW",
        title: "ORDER VIEW"
    },
]
