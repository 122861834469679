
export const CREATE_PRODUCT_PAGE_URL = "/admin/create-product";
export const CREATE_INGREDIENT_PAGE_URL = "/admin/create-ingredient";
export const CREATE_FACILITY_PAGE_URL = "/admin/create-facility";
export const CREATE_SUPPLIER_PAGE_URL = "/admin/create-supplier";
export const CREATE_SO_PAGE_URL = "/admin/create-sale-order";
export const CREATE_MO_PAGE_URL = "/admin/create-mfg-order";
export const CREATE_PO_PAGE_URL = "/admin/create-purchase-order";
export const CREATE_STO_PAGE_URL = "/admin/create-stock-transfer";
export const CREATE_KIT_PAGE_URL = "/admin/create-stock-kit";
export const CREATE_STA_PAGE_URL = "/admin/create-stock-adjustment";
export const CREATE_CUSTOMER_PAGE_URL = '/admin/create-customer';
export const CREATE_QUOTATION_PAGE_URL = 'admin/create-quotation-order';


export const MANAGE_SO_PAGE_URL = "/admin/manage-sale-order";
export const MANAGE_MO_PAGE_URL = "/admin/manage-mfg-order";
export const MANAGE_PO_PAGE_URL = "/admin/manage-purchase-order";
export const MANAGE_STO_PAGE_URL = "/admin/manage-stock-transfer";
export const MANAGE_KIT_PAGE_URL = "/admin/manage-stock-kit";
export const MANAGE_STA_PAGE_URL = "/admin/manage-stock-adjustment";
export const MANAGE_INVENTORY_PAGE_URL = "/admin/manage-inventory";
export const MANAGE_REPORT_PAGE_URL = "/admin/manage-report";
export const QUOTATION_PAGE_URL = "/admin/quotation";


export const NAVIGATION_URL_LIST_V1 = [{
    label: "Product",
    pageURL: "/admin/create-product",
    pageId: "PRODUCT",
    id: "newProduct"
},
{
    label: "Ingredient",
    pageURL: "/admin/create-ingredient",
    pageId: "INGREDIENT",
    id: "newIngredient"
},
{
    label: "SO Order",
    pageURL: "/admin/create-sale-order",
    pageId: "SO",
    id: "newSale"
},
{
    label: "MO Order",
    pageURL: "/admin/create-mfg-order",
    pageId: "MO",
    id: "newManufacturing"
},
{
    label: "PO Order",
    pageURL: "/admin/create-purchase-order",
    pageId: "PO",
    id: "newPurchase"
},
{
    label: "STO Order",
    pageURL: "/admin/create-stock-transfer",
    pageId: "STO",
    id: "newStockTransfer"
},
{
    label: "KIT Order",
    pageURL: "/admin/create-stock-kit",
    pageId: "KIT",
    id: "newKit"
},
{
    label: "Stock Adjustment",
    pageURL: "/admin/create-stock-adjustment",
    pageId: "SA",
    id: "newStockAdjustment"
},
]

export const NAVIGATION_URL_LIST_V2 = [
    {
        label: "Product",
        pageURL: "/admin/create-product",
        pageId: "PRODUCT",
        id: "newProduct"
    },
    {
        label: "SO Order",
        pageURL: "/admin/create-sale-order",
        pageId: "SO",
        id: "newSale"
    },
    {
        label: "PO Order",
        pageURL: "/admin/create-purchase-order",
        pageId: "PO",
        id: "newPurchase"
    },
    {
        label: "STO Order",
        pageURL: "/admin/create-stock-transfer",
        pageId: "STO",
        id: "newStockTransfer"
    },
]

export const INSIGHT_URL_LIST = [{
    label: "Product",
    pageURL: "/admin/manage-product",
    id: "insightProduct"
},
{
    label: "Channel",
    pageURL: "/admin/dashboard",
    id: "insightChannel"
},
{
    label: "Supplier",
    pageURL: "/admin/manage-supplier",
    id: "insightSupplier"
},
{
    label: "Customer",
    pageURL: "/admin/manage-customer",
    id: "insightCustomer"
},
{
    label: "Qrcode",
    pageURL: "/admin/manage-qrcode",
    id: "insightQrcode"
},
{
    label: "Manufacturing",
    pageURL: "/admin/manage-mfg-order",
    id: "insightManufacturing"
},
{
    label: "Document Management",
    pageURL: "/admin/manage-documents",
    id: "insightDocumentManagement"
},
]