const initialState = {
  tableData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "COLUMN_DATA": {
      return { ...state, tableData: action.payload.tableData };
    }

    default: {
      return state;
    }
  }
};
