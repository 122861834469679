import * as inventoryActionTypes from "../actions/inventoryActionTypes";

const initialState = {
  inventoryDetails: null,
  inventoryList: null,
  selectedInventoryCode: null,
  inventoryCount: null,
  inventorySearchList: null,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case inventoryActionTypes.SET_INVENTORY_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case inventoryActionTypes.GET_INVENTORY_LIST:
      return {
        ...state,
        inventoryList: action.payload
      };
    case inventoryActionTypes.GET_INVENTORY_DETAILS:
      return {
        ...state,
        inventoryDetails: action.payload
      };
    case inventoryActionTypes.SET_SELECTED_INVENTORY_CODE:
      return {
        ...state,
        selectedInventoryCode: action.payload
      };
    case inventoryActionTypes.GET_INVENTORY_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        inventoryCount: action.payload
      };
    case inventoryActionTypes.GET_INVENTORY_SEARCH_LIST:
      return {
        ...state,
        inventorySearchList: action.payload
      };
    default:
      return state;
  }
};

export default inventoryReducer;
