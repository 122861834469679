import * as roleActionTypes from '../actions/roleActionTypes';


const initialState = {
    roleList: null,
    permissions: null,
    roleDetails: null,
    selectedRoleCode: null,
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case roleActionTypes.GET_ROLE_LIST:
            return {
                ...state,
                roleList: action.payload
            };
        case roleActionTypes.GET_ALL_PERMISSIONS_LIST:
            return {
                ...state,
                permissions: action.payload
            };
        case roleActionTypes.CREATE_ROLE:
            return {
                ...state,
                roleDetails: action.payload
            };
        case roleActionTypes.SET_SELECTED_ROLE_CODE:
            return {
                ...state,
                selectedRoleCode: action.payload
            };
        case roleActionTypes.GET_ROLE_DETAILS:
            return {
                ...state,
                roleDetails: action.payload
            };
        default:
            return state;
    }
}

export default roleReducer;
