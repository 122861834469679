import * as supplierActionTypes from "../actions/supplierActionTypes";

const initialState = {
  supplierDetails: null,
  supplierList: null,
  selectedSupplierCode: null,
  documentURLObj: null,
  SupplierPerformanceList:null,
  bulkImportSuppliersDetails: null,
  supplierPaymentDetails: null

};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case supplierActionTypes.SET_SUPPLIER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case supplierActionTypes.SET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierDetails: action.payload
      };
    case supplierActionTypes.SET_UPLOADED_FILE_URL:
      return {
        ...state,
        documentURLObj: action.payload
      };
    case supplierActionTypes.GET_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: action.payload
      };
    case supplierActionTypes.SET_SELECTED_SUPPLIER_CODE:
      return {
        ...state,
        selectedSupplierCode: action.payload
      };
    case supplierActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierDetails: action.payload
      };
      case supplierActionTypes.GET_SUPPLIER_PERFORMANCE_DASHBOARD_LIST:
          return{
              ...state,
              SupplierPerformanceList:action.payload
          };
      case supplierActionTypes.SET_BULK_IMPORT_SUPPLIER_DETAILS:
        return {
          ...state,
          bulkImportSuppliersDetails: action.payload
      };  
      case supplierActionTypes.GET_SUPPLIER_PAYMENT_DETAILS:
        return {
          ...state,
          supplierPaymentDetails: action.payload
        };  
    default:
      return state;
  }
};

export default supplierReducer;
