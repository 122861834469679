import * as integrationActionTypes from "../actions/integrationActionTypes";

const initialState = {
  qboDetails: null,
  qboToken: null,
  qboAccount: null,
  qboDataCustomer: null,
  qboDataVendor: null,
  qboTaxCode: null,
  qborevoke: null,
  setQboTaxMapping: null,
  amazonAppkey: null,
  woocomToken: null,
  unishipperStatus: null,
  unishipperToken: null,
  woocomStatus: null,
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case integrationActionTypes.GET_QUICKBOOK_URI:
      return {
        ...state,
        qboDetails: action.payload
      };
    case integrationActionTypes.CREATETOKEN:
      return {
        ...state,
        qboToken: action.payload
      };
    case integrationActionTypes.SET_WOOCOM_ACCESS_TOKEN:
      return {
        ...state,
        woocomToken: action.payload
      };
    case integrationActionTypes.GET_QUICKBOOK_ACCOUNT:
      return {
        ...state,
        qboAccount: action.payload
      };
    case integrationActionTypes.SET_QUICKBOOK_ACCOUNT_SETTING:
      return {
        ...state,
        qboAccountSetting: action.payload
      };
    case integrationActionTypes.SET_QUICKBOOK_CUSTOMER_SYNC:
      return {
        ...state,
        qboDataCustomer: action.payload
      };
    case integrationActionTypes.SET_QUICKBOOK_VENDOR_SYNC:
      return {
        ...state,
        qboDataVendor: action.payload
      };
    case integrationActionTypes.GET_QUICKBOOK_TAX_CODE:
      return {
        ...state,
        qboTaxCode: action.payload
      };
    case integrationActionTypes.REVOKE_QUICKBOOK_ACCOUNT:
      return {
        ...state,
        qborevoke: action.payload
      };
    case integrationActionTypes.SET_QUICKBOOK_TAX:
      return {
        ...state,
        setQboTaxMapping: action.payload
      };
    case integrationActionTypes.SET_AMAZON_APP_KEY:
      return {
        ...state,
        amazonAppkey: action.payload
      };
    case integrationActionTypes.SET_UNISHIPPER_STATUS:
      return {
        ...state,
        unishipperStatus: action.payload
      };
    case integrationActionTypes.SET_UNISHIPPER_TOKEN:
      return {
        ...state,
        unishipperToken: action.payload
      };
    case integrationActionTypes.SET_WOOCOM_STATUS:
      return {
        ...state,
        woocomStatus: action.payload
      };
    default:
      return state;
  }
};

export default integrationReducer;
