import * as saleOrderActionTypes from "../actions/saleOrderActionTypes";

const initialState = {
  attributeObj : null,
  saleOrderDetails: null,
  saleOrderList: null,
  selectedSaleOrderCode: null,
  productStatusList: null,
  saleOrderStockList: null,
  topProductList: null,
  saleOrderCount: null,
  saleOTIFList: null,
  inventoryCoverageList: null,
  saleChannelCount: [],
  saleChannelCost: [],
  saleProfitOverTimeList: null,
  saleOrderPriorityDetails: null,
  shopifyCustomerDetails: null,
  qboInvoiceDetail: null,
  saleOrderPackingList: null,
  saleOrderRefDetails: null,
  saleOrderPaymentDetails: null,
  cartDetails: null,
  paymentLinks: null,
};

const saleOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case saleOrderActionTypes.SET_SALE_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case saleOrderActionTypes.SET_SALE_ORDER_DETAILS:
      return {
        ...state,
        saleOrderDetails: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_STOCK_LIST:
      return {
        ...state,
        saleOrderStockList: action.payload,
      };
    case saleOrderActionTypes.GET_SALE_ORDER_LIST:
      return {
        ...state,
        saleOrderList: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_PACKING_LIST:
      return {
        ...state,
        saleOrderPackingList: action.payload
      };
    case saleOrderActionTypes.SET_PRODUCT_STATUS_LIST:
      return {
        ...state,
        productStatusList: action.payload
      };
    case saleOrderActionTypes.SET_SELECTED_SALE_ORDER_CODE:
      return {
        ...state,
        selectedSaleOrderCode: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_DETAILS:
      return {
        ...state,
        saleOrderDetails: action.payload,
        attributeObj : action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_REFERENCE_DETAILS:
      return {
        ...state,
        saleOrderRefDetails: action.payload
      };
    case saleOrderActionTypes.GET_SHOPIFY_CUSTOMER_DETAILS:
      return {
        ...state,
        shopifyCustomerList: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_PRIORITY_DETAILS:
      return {
        ...state,
        saleOrderPriorityDetails: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_DASHBOARD_LIST_URL:
      return {
        ...state,
        topProductList: action.payload
      };
    case saleOrderActionTypes.GET_SALE_ORDER_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        saleOrderCount: action.payload
      };
    case saleOrderActionTypes.GET_SALE_OTIF_DASHBOARD_LIST:
      return {
        ...state,
        saleOTIFList: action.payload
      };
    case saleOrderActionTypes.GET_INVENTORY_COVERAGE_DASHBOARD_LIST:
      return {
        ...state,
        inventoryCoverageList: action.payload
      };
    case saleOrderActionTypes.GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST:
      return {
        ...state,
        saleChannelCount: action.payload
      };
    case saleOrderActionTypes.GET_SALE_CHANNEL_COST_DASHBOARD_LIST:
      return {
        ...state,
        saleChannelCost: action.payload
      }
    case saleOrderActionTypes.GET_SALE_PROFIT_DASHBOARD_LIST:
      return {
        ...state,
        saleProfitOverTimeList: action.payload
      };
    case saleOrderActionTypes.GET_QBO_INVOICE_DETAILS:
      return {
        ...state,
        qboInvoiceDetail: action.payload,
      };
    case saleOrderActionTypes.GET_SALE_ORDER_PAYMENT_DETAILS:
      return {
        ...state,
        saleOrderPaymentDetails: action.payload
      };
    case saleOrderActionTypes.GET_CART_DETAILS:
      return {
        ...state,
        cartDetails: action.payload
      };
    case saleOrderActionTypes.GET_DETAILS:
      return {
        ...state,
        attributeObj: action.payload
      };
    case saleOrderActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    case saleOrderActionTypes.GET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentLinks: action.payload
      };
    default:
      return state;
  }
};

export default saleOrderReducer;
