import mostpopular from "assets/img/most-popular.svg";

export const subscriptionPlans = [
	{
		title: "Starter",
		price: "100.00/mo",
		className: "starter",
		button: "Select",
		image: "",
		featuretitle: "Includes Features",
		features: [
			{ text: "Mak" },
	        { text: "Buy" },
	        { text: "Sell" },
	        { text: "Inventory Management" },
	        { text: "Warehouse/Facility Management" },
	        { text: "Product Management" },
	        { text: "Supplier Management" },
	        { text: "Ingredients Management" },
	        { text: "Operations Management" },
	        { text: "Batch/Lot level Traceability" },
	        { text: "Documents Management" },
	        { text: "Ecomm Integration" },
	        { text: "Accounting Integraiton" },
	        { text: "Basic Dashboards & Analytic" },
		]
	},
	{
		title: "Starter Plus",
		price: "100.00/mo",
		className: "starter-plus",
		button: "Select",
		image: mostpopular,
		featuretitle: "Includes Features",
		features: [
			{ text: "Everything from starter" },
		    { text: "Full Traceability (beyond Batch/Lot)" },
		    { text: "Withdrawal, Recall and Liquidate" },
		    { text: "Incident Management" },
		    { text: "Multiple ecomm channel integration" },
		    { text: "Multiple accounting integration" },
		    { text: "Demand Planning (powered by AI/ML)" },
		    { text: "Requirements Management" },
		    { text: "Advanced Automation" },
		    { text: "Advanced Dashboards and Analytics" },
		    { text: "Marketin" },
		]
	},
	{
		title: "Growth",
		price: "149.00/mo",
		className: "growth",
		button: "Select",
		image: "",
		featuretitle: "Includes Features",
		features: [
			{ text: "Mak" },
	        { text: "Buy" },
	        { text: "Sell" },
	        { text: "Inventory Management" },
	        { text: "Warehouse/Facility Management" },
	        { text: "Product Management" },
	        { text: "Supplier Management" },
	        { text: "Ingredients Management" },
	        { text: "Operations Management" },
	        { text: "Batch/Lot level Traceability" },
	        { text: "Documents Management" },
	        { text: "Ecomm Integration" },
	        { text: "Accounting Integraiton" },
	        { text: "Basic Dashboards & Analytic" },
		]
	},
	{
		title: "Growth Plus",
		price: "149.00/mo",
		className: "growth-plus",
		button: "Select",
		image: "",
		featuretitle: "Includes Features",
		features: [
			{ text: "Everything from starter" },
		    { text: "Full Traceability (beyond Batch/Lot)" },
		    { text: "Withdrawal, Recall and Liquidate" },
		    { text: "Incident Management" },
		    { text: "Multiple ecomm channel integration" },
		    { text: "Multiple accounting integration" },
		    { text: "Demand Planning (powered by AI/ML)" },
		    { text: "Requirements Management" },
		    { text: "Advanced Automation" },
		    { text: "Advanced Dashboards and Analytics" },
		    { text: "Marketin" },
		]
	},
]

export const faqLeft = [ 
	{
		question: "What is the monthly cost of GetResponse?",
		answer: "The monthly cost is based on the payment plan you select, with 12- & 24-month prepay plans, you get a corresponding 18% or 30% discount and pay for one years in advance. month-to-month plans aren't discounted.",
	},
	{
		question: "What if i go beyond my list-size limit?",
		answer: "We don't interrupt or restrict your service. No Contacts are lost. GetResponse adds the extra contacts & charges a list extension fee to your account for any month in which your list exceeds the maximum allowed in your chosen plan.",
	},
	{
		question: "Can i cancel my account?",
		answer: "You can cancel anytime. To cancel your paid account, please log in, click on my Account, & choose My Billing. Scroll down to the Cancel subscription section and click on it. Please note that when you cancel an account, all your account data will be lost",
	},
	{
		question: "What is the GetResponse refund Policy?",
		answer: "GetResponse is a pay-as-you-go service. This Means we dont's issue refunds, even if you cancel immediately after your credit card is charged for a new billing period, no subsequent charges are applied to your credit card, but the amounts already charged are not refunded.",
	},
	{
		question: "How do paid webinars works?",
		answer: "Paid webinars are only accessible after someone has paid to join. paid webinars are available within the conversion funnel feature when setting up a webinar funnel. this feature is included in the professional plan.",
	},
	{
		question: "What is the Social Ads Creator?",
		answer: "The Social Ads Creator is a free mobile app available for all paid accounts, it helps you promote your beand on social media with high-quality images and animations and is available for download from the App Store and Google Play. The app works well together with other GetResponse solutions like conversion funnel and facebook and instagram ads to increase the performance of your multichannel campaigns paid accounts can use this service by logging in with their account credentials.",
	},
]
export const faqRight = [ 
	{
		question: "I am a Nonprofit. Do you offer any discounts?",
		answer: "We offer an uo to 50% discount to nonprofits that qualify for the GetResponse for Nonprofits Program. To apply and learn more, Click here.",
	},
	{
		question: "How Does the MAX package work?",
		answer: "The MAX package is a custom offer that our sales team creates for you based on your business needs, goals, and capacities, it's governed by a custom agreement you sign for a minimum of one years when all the details of your package are confirmed.",
	},
	{
		question: "What is campaign consulting?",
		answer: "Campaign consulting comes with the Max package. You get expert consultation related to your marketing strategy, GetResponse account, campaign setup, and product training.",
	},
	{
		question: "What are account manager and deliverability consulting?",
		answer: "There are dedicated MAX services aimed at maximizing your campaign results. Thay include ongoing monitoring and reporting of yours results, advice and help on improving your deliverability, and day-to-day support with all aspects of your GetResponse campaigns.",
	},
	{
		question: "What do the ecommerce tolls include?",
		answer: "Ecommerce tools include advanced features to help you sell more-like automated product recommendations based on past purchase data; and a store and products in your accounr, These tools are available in both the Plus and professional plans.",
	},
	{
		question: "What are custom workflows and Automation builder?",
		answer: "These advanced automation tools, available in the professional plan, allow you to build marketing automation workflows by mixing and matching all of the different automation elements. The Automation builder is the tool that lets you create, Manage tweak, and view your automation flows, and where you combine actions, conditions, and filters to a create customer jpurnry based on your business needs and goals, Custom workflows are an advanced feature aimed at professional marketers, allowing you to build and pubmished workflows autoside of the pre-designed automation templates.",
	},
]