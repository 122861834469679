import * as actionTypes from "./actionTypes";
import axios from "../axios/axios";
import {beginAjaxCall, endAjaxCall,
  updateApiResponse, failedApiResponse,setTempAjaxCallStatus
} from "./ajaxStatusActions";
import * as commonConstant from '../modules/common/constant/commonConstant';

export function setActionMode(actionMode) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.APP_ACTION_MODE,
      payload: actionMode
    });
  }
}

export function resetUserDetails(isUpdateReponse) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(commonConstant.REST_USER_DETAILS_URL).then((response) => {
      if(isUpdateReponse){
       dispatch(setTempAjaxCallStatus(updateApiResponse(response)));
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
      dispatch(setTempAjaxCallStatus(failedApiResponse(error)));
    });
  };
}


