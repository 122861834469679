import * as tenantActionTypes from "../actions/tenantActionTypes";

const initialState = {
  tenantList: null,
  tenantDetail: null,
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case tenantActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case tenantActionTypes.GET_TENANT_LIST:
      return {
        ...state,
        tenantList: action.payload
      };
    case tenantActionTypes.TENANT_DETAIL:
      return {
        ...state,
        tenantDetail: action.payload
      };
    default:
      return state;
  }
};

export default tenantReducer;
