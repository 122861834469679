import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Button from "../CustomButton/CustomButton.jsx";
import { setActionMode, resetUserDetails } from "../../actions/appActions";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import CommonUtil from '../../modules/common/util/commonUtil';
import { setTempAjaxCallStatus } from "../../actions/ajaxStatusActions";
import { Redirect } from "react-router-dom";
import PopupUtil from '../../modules/common/util/popupUtil';
import * as commonConstant from '../../modules/common/constant/commonConstant';
import * as navigationURLConstants from '../../constants/navigationURLConstants';
import isAuthorized from "auth-plugin"
import { clickDrop } from "constants/dropdownevent.js";

class NewItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick = (event, url) => {
    var tempId = event.target.id
    this.props.setActionMode(commonConstant.CREATE_ACTION_MODE);
    let params = "new";
    CommonUtil.handlePageRedirection(url, this, params);
  }

  handleResetUser = () => {
    this.setState({ alert: null });
    this.props.resetUserDetails(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ajaxTempCallStatus != null && prevProps.ajaxTempCallStatus != this.props.ajaxTempCallStatus) {
      this.handleAjaxResponse();
    }
    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
    }
  }

  handleAjaxResponse = () => {
    if (this.props.ajaxTempCallStatus.status == "SUCCESS") {
      setTimeout(CommonUtil.handlePageRedirection(commonConstant.MANAGE_DASHBOARD_URL, this), 0);
    }
    if (this.props.ajaxTempCallStatus.status == "FAILED") {
      this.props.setTempAjaxCallStatus(null);
      PopupUtil.popupErrorResponse(this);
    }
  }

  resetUserPopup = () => {
    let popupActionButton = {};
    popupActionButton.onConfirmClick = this.handleResetUser;
    popupActionButton.onCancelClick = this.handlePopupCancel;
    let popupConfig = CommonUtil.prepareCustomErrorMsgPopUpConfig(
      popupActionButton, commonConstant.DELETE_DUMMY_DATA, true);
    this.setState({ alert: PopupUtil.confirmationPopup(popupConfig) });
  }

  handlePopupCancel = () => {
    this.setState({ alert: null });
  }

  render() {
    const track = this.props.track;
    const { userProfile } = this.props;
    let isAuthorizedNewItems = [];
    if (track == "uti") {
      isAuthorizedNewItems = navigationURLConstants.NAVIGATION_URL_LIST_V1.filter(item => isAuthorized(item.id));
    } else {
      isAuthorizedNewItems = navigationURLConstants.NAVIGATION_URL_LIST_V2.filter(item => isAuthorized(item.id));
    }

    return (
      <>
        <div>
          {this.state.alert}
          {this.state.redirect === true ?
            <Redirect push to={{pathname: this.state.redirectUrl, state: this.state.params}}></Redirect> : null
          }
          <Nav pullRight>
            <NavDropdown
              className="navbar-add-option"
              title={
                <div className="navbar-plus-icon">
                  <p>Add New</p>
                  <i className="fa fa-chevron-down"></i>
                </div>
              }
              onClick={clickDrop}
              noCaret id="add-new-item" bsClass="dropdown">
              {isAuthorizedNewItems.map((tempNavigationObj, index) => (
                <MenuItem key={index}>
                  <div
                    key={index}
                    onClick={(e) => this.handleLinkClick(e, tempNavigationObj.pageURL)}
                    id={tempNavigationObj.pageId}>
                    {tempNavigationObj.label}
                  </div>
                </MenuItem>
              ))}
            </NavDropdown>
          </Nav>
        </div>
        {isAuthorized("adminSettings") && userProfile && userProfile.isDummyDataDeleted == false ?
          <Button className="reset" onClick={this.resetUserPopup}>Reset Data</Button>
          : null}
      </>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    permissionMapping: state.security.uiComponentsPermissionMapping,
    userProfile: state.user.userProfile,
    ajaxTempCallStatus: state.ajaxStatusReducer.ajaxTempCallStatus,
    track: state.dataDictionary.track,
  };
}

const mapDispatchToProps = dispatch => ({
  setTempAjaxCallStatus: (ajaxCallStatus) => dispatch(setTempAjaxCallStatus(ajaxCallStatus)),
  setActionMode: actionMode => dispatch(setActionMode(actionMode)),
  resetUserDetails: additionalData => dispatch(resetUserDetails(additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewItem));
