import React, { Component } from "react";
import spinner from "assets/img/circle-260x260.png";
import spinnerlogo from "assets/img/logo.svg";

class CustomLoader extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<div className="loadercontainer">
				<div className="loaderspinner"><img src={spinner} alt="Origin Scale" className="spinner" /><img src={spinnerlogo} alt="Origin Scale" className="spinner-logo" /></div>
			</div>
		);
	}
}

export default CustomLoader;