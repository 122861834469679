export const SET_SELECTED_USER_CODE = "SET_SELECTED_USER_CODE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_FACILITY_LIST = "GET_USER_FACILITY_LIST";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const SET_USER_PROFILE_URL = "SET_USER_PROFILE_URL";
export const SET_USER_PROFILE_URL_ERROR = "SET_USER_PROFILE_URL_ERROR";
export const CLEAR_USER_PROFILE_URL = "CLEAR_USER_PROFILE_URL";
export const CHECK_USER_EXIST = "CHECK_USER_EXIST";
export const USERS_EXHAUSTED = "USERS_EXHAUSTED";
export const RESEND_USER_PASSWORD_URL = "RESEND_USER_PASSWORD_URL";
export const GET_USER_FACILITY_NEW_LIST = "GET_USER_FACILITY_NEW_LIST";
