import * as stockAdjustmentActionTypes from "../actions/stockAdjustmentActionTypes";

const initialState = {
  stockAdjustmentDetails: null,
  stockAdjustmentList: null,
  selectedStockAdjustmentCode: null,
  stockDetails: null,
  creditNoteDetails: null,
  cartDetails: null,
  selectedFacilityId:null
};

const stockAdjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case stockAdjustmentActionTypes.SET_STOCK_ADJUSTMENT_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case stockAdjustmentActionTypes.SET_STOCK_ADJUSTMENT_DETAILS:
      return {
        ...state,
        stockAdjustmentDetails: action.payload,
      };
    case stockAdjustmentActionTypes.SET_CREDIT_NOTE_DETAILS:
      return {
        ...state,
        creditNoteDetails: action.payload,
      };
    case stockAdjustmentActionTypes.GET_STOCK_DETAILS:
      return {
        ...state,
        stockDetails: action.payload,
      };
    case stockAdjustmentActionTypes.GET_STOCK_ADJUSTMENT_LIST:
      return {
        ...state,
        stockAdjustmentList: action.payload,
      };
    case stockAdjustmentActionTypes.SET_SELECTED_STOCK_ADJUSTMENT_CODE:
      return {
        ...state,
        selectedStockAdjustmentCode: action.payload,
      };
    case stockAdjustmentActionTypes.GET_STOCK_ADJUSTMENT_DETAILS:
      return {
        ...state,
        stockAdjustmentDetails: action.payload,
      };
    case stockAdjustmentActionTypes.GET_CART_DETAILS:
      return {
        ...state,
        cartDetails: action.payload
      };
    case stockAdjustmentActionTypes.GET_STA_PAYMENT_DETAILS:
      return {
        ...state,
        staPaymentDetails: action.payload
      };
    case stockAdjustmentActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    default:
      return state;
  }
};

export default stockAdjustmentReducer;
