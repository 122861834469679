import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "reducers/rootReducer";
import thunk from "redux-thunk";
import logger from "redux-logger";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(thunk, logger);

const store = createStore(rootReducer, composeEnhancers(middleware));

export default store;
