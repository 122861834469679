import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import ajaxStatusReducer from "./ajaxStatusReducer";
import authReducer from "./authReducer";
import appReducer from "./appReducer";
import pipeLineReducer from "../modules/configurePipeLine/reducers/pipeLineReducer";
import batchReducer from "../modules/configurePipeLine/reducers/batchReducer";
import productReducer from "../modules/productManagement/reducers/productReducer";
import facilityReducer from "../modules/facilityManagement/reducers/facilityReducer";
import automationReducer from "../modules/automation/reducers/automationReducer";
import supplierReducer from "../modules/supplierManagement/reducers/supplierReducer";
import customerReducer from "../modules/customer/reducers/customerReducer";
import qrcodeReducer from "../modules/CreateQRCode/reducers/qrCodeReducer";
import leadCaptureReducer from "../modules/leadCapture/reducers/leadCaptureReducers";
import securityReducer from "../modules/security/reducers/securityReducer";
import tableReducer from "./tableReducer";
import designConsumerPageReducer from "../modules/CreateQRCode/reducers/designConsumerPageReducer";
import dataDictionaryReducer from "../modules/dataDictionary/reducers/dataDictionaryReducer";
import userReducer from "../modules/userManagement/reducers/userReducer";
import tenantReducer from "../modules/osAdmin/tenant/reducers/tenantReducer";
import mfgOrderReducer from "../modules/manufacturingOrder/reducers/mfgOrderReducer";
import stockTransferReducer from "../modules/stockTransfer/reducers/stockTransferReducer";
import stockAdjustmentReducer from "../modules/stockAdjustment/reducers/stockAdjustmentReducer";
import reportReducer from "../modules/reporting/reducers/reportReducer";
import ingredientReducer from "../modules/ingredientManagement/reducers/ingredientReducer";
import purchaseOrderReducer from "../modules/purchaseOrderAMS/reducers/purchaseOrderReducer";
import saleOrderReducer from "../modules/saleOrderAMS/reducers/saleOrderReducer";
import repackOrderReducer from "../modules/repackOrder/reducers/repackOrderReducer";
import inventoryReducer from "../modules/inventory/reducers/inventoryReducer";
import incidentReducer from "../modules/incident/reducers/incidentReducer";
import operationReducer from "../modules/operation/reducers/operationReducer";
import kitOrderReducer from "../modules/stockKit/reducers/kitOrderReducer";
import documentDetailsReducer from "../modules/documentManagement/reducers/documentReducer";
import traceabilityReducer from "../modules/traceabilityManagement/reducers/traceabilityReducer";
import integrationReducer from "../modules/integrations/reducers/integrationReducer";
import billingReducer from "../modules/billing/reducers/billingReducer";
import mappingReducer from "../modules/productMapping/reducers/mappingReducer";
import financeReducer from "../modules/finance/reducers/financeReducer";
import campaignReducer from "../modules/campaign/reducers/campaignReducer";
import reportingReducer from "../modules/reporting/reducers/reportReducer";
import auditReducer from "../modules/audit/reducers/auditReducer";
import roleReducer from "../modules/roles/reducers/roleReducer";
import saleTaxReducer from "../modules/saleTax/reducers/saleTaxReducer";
import bulkReducer from "../modules/bulkimport/reducers/bulkReducer";
import timesheetReducer from "../modules/timesheet/reducers/timesheetReducer";
import stockOrderReducer from "../modules/stockCount/reducers/stockCountReducer";
import quotationOrderReducer from "../modules/quotation/reducers/quotationOrderReducer";
import salereportReducer from "../modules/posReports/reducer/salereportReducer";
import shipmentOrderReducer from "../modules/shipmentOrder/reducers/shipmentOrderReducer"

const rootReducer = combineReducers({
  ajaxStatusReducer,
  authReducer,
  pipeLine: pipeLineReducer,
  product: productReducer,
  facility: facilityReducer,
  automation: automationReducer,
  supplier: supplierReducer,
  customer: customerReducer,
  batch: batchReducer,
  qrcode: qrcodeReducer,
  form: formReducer,
  leadCapture: leadCaptureReducer,
  security: securityReducer,
  designConsumerPage: designConsumerPageReducer,
  user: userReducer,
  tenant: tenantReducer,
  table: tableReducer,
  dataDictionary: dataDictionaryReducer,
  mfgOrder: mfgOrderReducer,
  ingredient: ingredientReducer,
  stockTransfer: stockTransferReducer,
  stockAdjustment: stockAdjustmentReducer,
  report: reportReducer,
  purchaseOrder: purchaseOrderReducer,
  saleOrder: saleOrderReducer,
  repackOrder: repackOrderReducer,
  inventory: inventoryReducer,
  incident: incidentReducer,
  operation: operationReducer,
  kitOrder: kitOrderReducer,
  app: appReducer,
  documentDetails: documentDetailsReducer,
  traceability: traceabilityReducer,
  integration: integrationReducer,
  billing: billingReducer,
  mapping: mappingReducer,
  finance: financeReducer,
  campaign: campaignReducer,
  reporting: reportingReducer,
  audit: auditReducer,
  role: roleReducer,
  saleTax: saleTaxReducer,
  bulk: bulkReducer,
  timesheet: timesheetReducer,
  stockCount: stockOrderReducer,
  quotationOrder: quotationOrderReducer,
  salereport: salereportReducer,
  shipment: shipmentOrderReducer,
});

export default rootReducer;

