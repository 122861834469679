import * as mfgOrderActionTypes from "../actions/mfgOrderActionTypes";

const initialState = {
  mfgOrderDetails: null,
  mfgOrderList: null,
  selectedMfgOrderCode: null,
  ingredientStatusList: null,
  mfgOrderReportList: null,
  mfgOrderCostReportList: null,
  mfgOTIFList: null,
  receivedStockList: null,
  mfgOrderPriorityDetails: null,
  mfgBatchDetail:null,
  mfgOrdersResourceViewData: null,
  mfgOrdersResourceViewError: null,
  mfgOrdersOrderViewData: null,
  mfgOrdersOrderViewError: null,
  operationOrderStatusChangeStatus: null,
  eta: null,
  productionETADateError: null,
};

const mfgOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case mfgOrderActionTypes.SET_MFG_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case mfgOrderActionTypes.SET_MFG_ORDER_DETAILS:
      return {
        ...state,
        mfgOrderDetails: action.payload
      };
    case mfgOrderActionTypes.SET_INGREDIENT_STATUS_LIST:
      return {
        ...state,
        ingredientStatusList: action.payload
      };
    case mfgOrderActionTypes.GET_RECEIVED_STOCK_LIST:
      return {
        ...state,
        receivedStockList: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_LIST:
      return {
        ...state,
        mfgOrderList: action.payload
      };
      case mfgOrderActionTypes.GET_MFG_BATCH_DETAILS:
        return {
          ...state,
          mfgBatchDetail: action.payload
        };  
    case mfgOrderActionTypes.SET_SELECTED_MFG_ORDER_CODE:
      return {
        ...state,
        selectedMfgOrderCode: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_DETAILS:
      return {
        ...state,
        mfgOrderDetails: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_PRIORITY_DETAILS:
      return {
        ...state,
        mfgOrderPriorityDetails: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_REPORT_LIST:
      return {
        ...state,
        mfgOrderReportList: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_COST_REPORT_LIST:
      return {
        ...state,
        mfgOrderCostReportList: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_OTIF_DASHBOARD_LIST:
      return {
        ...state,
        mfgOTIFList: action.payload
      };
    case mfgOrderActionTypes.GET_MFG_ORDER_RESOURCE_VIEW_DATA: {
      return {
        ...state,
        mfgOrdersResourceViewData: action.payload,
        operationOrderStatusChangeStatus: null
      }
    }
    case mfgOrderActionTypes.GET_MFG_ORDER_RESOURCE_VIEW_DATA_ERROR: {
      return {
        ...state,
        mfgOrdersResourceViewError: action.payload,
        operationOrderStatusChangeStatus: null
      }
    }
    case mfgOrderActionTypes.GET_MFG_ORDER_ORDER_VIEW_DATA: {
      return {
        ...state,
        mfgOrdersOrderViewData: action.payload
      }
    }
    case mfgOrderActionTypes.GET_MFG_ORDER_ORDER_VIEW_DATA_ERROR: {
      return {
        ...state,
        mfgOrdersOrderViewError: action.payload
      }
    }
    case mfgOrderActionTypes.UPDATE_OPERATION_STATUS: {
      return {
        ...state,
        operationOrderStatusChangeStatus: action.payload
      }
    }
    case mfgOrderActionTypes.UPDATE_OPERATION_STATUS_ERROR: {
      return {
        ...state,
        operationOrderStatusChangeStatus: action.payload
      }
    }
    case mfgOrderActionTypes.GET_ETA: {
      return {
        ...state,
        eta: action.payload
      }
    }
    case mfgOrderActionTypes.GET_ETA_ERROR: {
      return {
        ...state,
        productionETADateError: action.payload
      }
    }
    default:
      return state;
  }
};

export default mfgOrderReducer;
