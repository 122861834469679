export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GET_SALE_REPORT = `${BASE_URL}/reporting/salesReport/`;
export const GET_LABOUR_SALES = `${BASE_URL}/reporting/labourSales/`;
export const GET_INVENTORY_REPORT = `${BASE_URL}/reporting/inventoryReport/`;
export const GET_REPORTING_TIME_FILTER = `${BASE_URL}/reporting/timeFilter/`;
export const DELETE_REPORTING_TIME_FILTER = `${BASE_URL}/reporting/deleteTimeFilter/`;

export const UPDATE_REPORTING_TIME_FILTER_URL = `${BASE_URL}/reporting/updateTimeFilter/`;
export const GET_REPORTING_TIME_ID_FILTER = `${BASE_URL}/reporting/getTimeFilterById/`;
export const GET_COGS_REPORT = `${BASE_URL}/reporting/cogsReport`;
export const GET_ITEM_SALE_REPORT = `${BASE_URL}/reporting/item-sale-report`;
export const GET_COMMISSION_REPORT = `${BASE_URL}/reporting/commission-report`;
export const GET_COMMISSION_REPORT_DETAIL = `${BASE_URL}/reporting/commissionById`;

export const GET_REPORT_LIST_URL = `${BASE_URL}/reporting/getPowerBiReports`;

export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";

export const GET_SALE_REPORT_LIST = "GET_SALE_REPORT_LIST";
export const GET_LABOUR_VS_SALES_LIST = "GET_LABOUR_VS_SALES_LIST";
export const GET_INVENTORY_REPORT_LIST = "GET_INVENTORY_REPORT_LIST";
export const GET_REPORTING_TIME_FILTER_LIST = "GET_REPORTING_TIME_FILTER_LIST";
export const GET_REPORTING_TIME_FILTER_ID_WISE = "GET_REPORTING_TIME_FILTER_ID_WISE";
export const GET_COGS_REPORT_LIST = "GET_COGS_REPORT_LIST";
export const GET_ITEM_SALE_REPORT_LIST = "GET_ITEM_SALE_REPORT_LIST";
export const GET_COMMISSION_REPORT_LIST = "GET_COMMISSION_REPORT_LIST";
export const GET_COMMISSION_REPORT_DETAILS = "GET_COMMISSION_REPORT_DETAILS";