import * as actionTypes from "./actionTypes";

export function beginAjaxCall() {
  return { type: actionTypes.BEGIN_AJAX_CALL };
}

export function ajaxCallError() {
  return { type: actionTypes.AJAX_CALL_ERROR };
}

export function endAjaxCall() {
  return { type: actionTypes.END_AJAX_CALL };
}

export function setAjaxCallStatus(ajaxCallStatus) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_AJAX_CALL_STATUS,
      payload: ajaxCallStatus
    });
  }
}

export function setTempAjaxCallStatus(ajaxCallStatus) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_TEMP_AJAX_CALL_STATUS,
      payload: ajaxCallStatus
    });
  }
}


export function setUploadImportFileStatus(status) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_UPLOAD_IMPORT_FILE_STATUS,
      payload: status
    });
  }
}

export function createApiResponse(response, additionalData) {
  var tempObj = {};
  if (response.status == 200 || response.status == 201) {
    tempObj.status = actionTypes.API_SUCCESS_RESPONSE;
  }
  else {
    tempObj.status = actionTypes.API_FAILED_RESPONSE;
  }
  tempObj.data = response.data;
  return tempObj;
}

export function updateApiResponse(response, additionalData) {
  var tempObj = {};
  if (response.status == 200 || response.status == 201) {
    tempObj.status = actionTypes.API_SUCCESS_RESPONSE;
  }
  else {
    tempObj.status = actionTypes.API_FAILED_RESPONSE;
  }
  tempObj.data = response.data;
  tempObj.additionalData = additionalData;
  return tempObj;
}

export function failedApiResponse(error) {
  var tempObj = {};
  tempObj.status = actionTypes.API_FAILED_RESPONSE;
  tempObj.data = error;
  return tempObj;
}