import * as reportActionTypes from "../actions/reportActionTypes";

const initialState = {
  reportDetails: null,
  reportList: null,
  timeFilterList:null,
  timeFilterIdDetails:null
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportActionTypes.SET_REPORT_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case reportActionTypes.GET_REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.payload,
      };
    case reportActionTypes.GET_REPORTING_TIME_FILTER_LIST:
      return {
        ...state,
        timeFilterList: action.payload,
      };
    case reportActionTypes.GET_REPORTING_TIME_FILTER_ID_WISE:
      return {
        ...state,
        timeFilterIdDetails: action.payload,
      };
    case reportActionTypes.GET_REPORT_LIST:
      return {
        ...state,
        reportList: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
