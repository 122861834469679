export const CREATE_KIT_ORDER_HEADER_TITLE = "Create Kit Order";
export const EDIT_KIT_ORDER_HEADER_TITLE = "Edit Kit Order";
export const VIEW_KIT_ORDER_HEADER_TITLE = "View Kit Order";
export const MANAGE_KIT_ORDER_HEADER_TITLE = "Kit - Component";
export const MODULE_NAME = "KitOrder";
export const CREATE_KIT_ORDER = "createKitOrder";

export const CREATE_KIT_ORDER_PAGE_URL = '/admin/create-stock-kit';
export const MANAGE_KIT_ORDER_PAGE_LIST = '/admin/manage-stock-kit';
export const CREATE_PAGE = "createPage";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GET_KIT_ORDER_LIST_URL = `${BASE_URL}/kitorder`;
export const GET_KIT_ORDER_DETAILS_URL = `${BASE_URL}/kitorder/`;
export const SET_CREATE_KIT_ORDER_DETAILS_URL = `${BASE_URL}/kitorder/`;
export const SET_UPDATE_KIT_ORDER_DETAILS_URL = `${BASE_URL}/kitorder/`;
export const SET_UPDATE_KIT_ORDER_STATUS_URL = `${BASE_URL}/kitorder/`;
export const GET_KIT_ORDER_SEARCH_URL = BASE_URL + '/kitorder/';

export const PRO_PRICE_VALIDATION_MSG = "One or All ingredients used in the manufacturing order data is incomplete, kindly update supplier and price details.";


export const dataTable = [];
export const PRODUCT_TABLE_NAME = 'PRODUCTS';

export const MANAGE_PAGE_TAB_LIST = [
    {
        key: "OPEN",
        title: "OPEN"
    },
    {
        key: "COMPLETED",
        title: "COMPLETED"
    },
    {
        key: "DRAFT",
        title: "DRAFT"
    },
];

export const MANAGE_PAGE_TAB_LIST_WITHOUT_DRAFT = [
    {
        key: "OPEN",
        title: "OPEN"
    },
    {
        key: "COMPLETED",
        title: "COMPLETED"
    },
]
