import * as stockCountActionTypes from "../actions/stockCountActionTypes";

const initialState = {
  stockCountDetails: null,
  stockCountList: null,
  stockCountGrossList: null,
  selectedStockCountCode: null,
  selectedFacilityId:null
};

const ingredientReducer = (state = initialState, action) => {
  switch (action.type) {
    case stockCountActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case stockCountActionTypes.SET_SELECTED_STOCKCOUNT_DETAILS:
      return {
        ...state,
        selectedIngredientDetails: { ...action.payload.stockCountDetailsObj }
      };
    case stockCountActionTypes.GET_STOCKCOUNT_LIST:
      return {
        ...state,
        stockCountList: action.payload
      };
    case stockCountActionTypes.GET_STOCKCOUNT_DETAILS_LIST:
      return {
        ...state,
        ingredientDetailList: action.payload
      }
    case stockCountActionTypes.GET_STOCKCOUNT_GROSS_LIST:
      return {
        ...state,
        stockCountGrossList: action.payload
      };
    case stockCountActionTypes.SET_SELECTED_STOCKCOUNT_CODE:
      return {
        ...state,
        selectedStockCountCode: action.payload
      };
    case stockCountActionTypes.GET_STOCKCOUNT_DETAILS:
      return {
        ...state,
        stockCountDetails: action.payload
      };
    case stockCountActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload
      };
    default:
      return state;
  }
};

export default ingredientReducer;
