export const SET_SELECTED_PURCHASE_ORDER_CODE = "SET_SELECTED_PURCHASE_ORDER_CODE";
export const SET_PURCHASE_ORDER_DETAILS = "SET_PURCHASE_ORDER_DETAILS";
export const SET_PURCHASE_ORDER_REDUCER_INIT_MODE = "SET_PURCHASE_ORDER_REDUCER_INIT_MODE";
export const GET_PURCHASE_ORDER_LIST = "GET_PURCHASE_ORDER_LIST";
export const GET_PURCHASE_ORDER_INCOMING_SHIPMENT_LIST = "GET_PURCHASE_ORDER_INCOMING_SHIPMENT_LIST";
export const GET_LIQUIDATION_DETAILS = "GET_LIQUIDATION_DETAILS";
export const GET_PURCHASE_ORDER_STOCK_LIST = "GET_PURCHASE_ORDER_STOCK_LIST";
export const GET_PURCHASE_ORDER_DETAILS = "GET_PURCHASE_ORDER_DETAILS";
export const GET_QBO_DETAILS = "GET_QBO_DETAILS";
export const GET_QBO_BILL_DETAILS = "GET_QBO_BILL_DETAILS";
export const GET_PURCHASE_ORDER_SEARCH_LIST = "GET_PURCHASE_ORDER_SEARCH_LIST";
export const GET_PURCHASE_ORDER_PAYMENT_DETAILS = "GET_PURCHASE_ORDER_PAYMENT_DETAILS";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";
export const SET_SELECTED_SUPPLIER_CODE = "SET_SELECTED_SUPPLIER_CODE";


