export const GET_JWT_TOKEN = 'GET_JWT_TOKEN';
export const GET_JWT_TOKEN_ERROR = 'GET_JWT_TOKEN_ERROR';
export const COGNITO_AUTHENTICATION_SUCCESS = 'COGNITO_AUTHENTICATION_SUCCESS';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';

export const NEW_PASSWORD_CHALLENGE = 'NEW_PASSWORD_CHALLENGE';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_ERROR = 'SET_NEW_PASSWORD_ERROR';
export const CLEAR_NEW_PASSWORD_MESSAGES = 'CLEAR_NEW_PASSWORD_MESSAGES';

export const FORGOT_PASSWORD_CODE_SENT = 'FORGOT_PASSWORD_CODE_SENT';
export const FORGOT_PASSWORD_CODE_ERROR = 'FORGOT_PASSWORD_CODE_ERROR';
export const CLEAR_FORGOT_PASSWORD = 'CLEAR_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FLOW_SUCCESS = 'FORGOT_PASSWORD_FLOW_SUCCESS';
export const FORGOT_PASSWORD_FLOW_ERROR = 'FORGOT_PASSWORD_FLOW_ERROR';
export const CHECK_IF_TENANT_EXISTS = "CHECK_IF_TENANT_EXISTS";
export const CHECK_IF_TENANT_EXISTS_ERROR = "CHECK_IF_TENANT_EXISTS_ERROR";
export const GET_UI_COMPONENTS_PERMISSION_MAPPING = "GET_UI_COMPONENTS_PERMISSION_MAPPING";
export const GET_UI_COMPONENTS_PERMISSION_MAPPING_ERROR = "GET_UI_COMPONENTS_PERMISSION_MAPPING_ERROR";
export const GET_SHOPIFY_DETAILS = "GET_SHOPIFY_DETAILS";
export const GET_SO_DETAILS = "GET_SO_DETAILS";
export const CHARGE_CREDIT_CARD = "CHARGE_CREDIT_CARD";
