import axios from '../../../axios/axios';
import * as kitOrderActionTypes from './kitOrderActionTypes';
import * as kitOrderConstant from '../constant/kitOrderConstant';
import {
  beginAjaxCall, endAjaxCall, setAjaxCallStatus, updateApiResponse,
  createApiResponse, failedApiResponse
} from "../../../actions/ajaxStatusActions";
import CommonUtil from '../../common/util/commonUtil';

export function setReducerInitMode() {
  return function (dispatch) {
    dispatch({
      type: kitOrderActionTypes.SET_KIT_ORDER_REDUCER_INIT_MODE,
      payload: null
    });
  }
}

export function setKitOrderDetails(kitOrderDetailsObj, actionMode, kitOrderId) {
  if (CommonUtil.isCreateOrCloneMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.post(kitOrderConstant.SET_CREATE_KIT_ORDER_DETAILS_URL,
        kitOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(createApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
  if (CommonUtil.isEditMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.put(kitOrderConstant.SET_UPDATE_KIT_ORDER_DETAILS_URL +
        kitOrderId, kitOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(updateApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
}

export function setSelectedKitOrderCode(selectedKitOrderCode) {
  return function (dispatch) {
    dispatch({
      type: kitOrderActionTypes.SET_SELECTED_KIT_ORDER_CODE,
      payload: selectedKitOrderCode
    });
  }
}
export function setSelectedFacilityId(selectedFacilityId) {
  return function (dispatch) {
    dispatch({
      type: kitOrderActionTypes.SET_SELECTED_FACILITY_ID,
      payload: selectedFacilityId
    });
  }
}

export function getKitOrderList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(kitOrderConstant.GET_KIT_ORDER_LIST_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: kitOrderActionTypes.GET_KIT_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getKitOrderDetails(selectedKitOrderCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(kitOrderConstant.GET_KIT_ORDER_DETAILS_URL + selectedKitOrderCode).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: kitOrderActionTypes.GET_KIT_ORDER_DETAILS,
          payload: response.data[0]
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function updateKitOrderDeleteStatus(kitOrderDetailsObj, kitCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(kitOrderConstant.SET_UPDATE_KIT_ORDER_STATUS_URL + kitCode,
      kitOrderDetailsObj).then(response => {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  };
}

export function getKitOrderSearchList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(kitOrderConstant.GET_KIT_ORDER_SEARCH_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: kitOrderActionTypes.GET_KIT_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}


