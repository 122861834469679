import axios from '../../../axios/axios';
import * as mfgOrderActionTypes from './mfgOrderActionTypes';
import * as mfgOrderConstant from '../constant/mfgOrderConstant';
import {
  beginAjaxCall, endAjaxCall, setAjaxCallStatus, updateApiResponse,
  createApiResponse, failedApiResponse
} from "../../../actions/ajaxStatusActions";
import CommonUtil from '../../common/util/commonUtil';
import PaginationUtil from '../../common/util/paginationUtil'

export function setReducerInitMode() {
  return function (dispatch) {
    dispatch({
      type: mfgOrderActionTypes.SET_MFG_ORDER_REDUCER_INIT_MODE,
      payload: null
    });
  }
}

export function setMfgOrderDetails(mfgOrderDetailsObj, actionMode, mfgOrderId) {
  if (CommonUtil.isCreateOrCloneMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.post(mfgOrderConstant.SET_CREATE_MFG_ORDER_DETAILS_URL,
        mfgOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(createApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
  if (CommonUtil.isEditMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.put(mfgOrderConstant.SET_UPDATE_MFG_ORDER_DETAILS_URL +
        mfgOrderId, mfgOrderDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(updateApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
}

export function getMfgOrderResourceViewData(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_RESOURCE_VIEW_DATA, {params: params}).then(response => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.GET_MFG_ORDER_RESOURCE_VIEW_DATA, payload: response.data});
    }).catch(error => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.GET_MFG_ORDER_RESOURCE_VIEW_DATA_ERROR, payload: error});
    });
  }
}

export function editOperationStatus(request) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(mfgOrderConstant.UPDATE_OPERATION_STTAUS_URL, request).then(response => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.UPDATE_OPERATION_STATUS, payload: response.data});
    }).catch(error => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.UPDATE_OPERATION_STATUS_ERROR, payload: error});
    });
  }
}

export function getMfgOrderOrderViewData() {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_ORDERS_VIEW_DATA).then(response => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.GET_MFG_ORDER_ORDER_VIEW_DATA, payload: response.data});
    }).catch(error => {
      dispatch(endAjaxCall());
      dispatch({ type: mfgOrderActionTypes.GET_MFG_ORDER_ORDER_VIEW_DATA_ERROR, payload: error});
    });
  }
}


export function setSelectedMfgOrderCode(selectedMfgOrderCode) {
  return function (dispatch) {
    dispatch({
      type: mfgOrderActionTypes.SET_SELECTED_MFG_ORDER_CODE,
      payload: selectedMfgOrderCode
    });
  }
}

export function setIngredientStatusList(ingredientStatusList) {
  return function (dispatch) {
    dispatch({
      type: mfgOrderActionTypes.SET_INGREDIENT_STATUS_LIST,
      payload: ingredientStatusList
    });
  }
}

export function getMfgOrderList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_LIST_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getMfgOrderDetails(selectedMfgOrderCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_DETAILS_URL + selectedMfgOrderCode).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_DETAILS,
          payload: response.data[0]
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getMFGBatchDetails(selectedBatchCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_BATCH_DETAILS_URL + selectedBatchCode).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_BATCH_DETAILS,
          payload: response.data.Items[0]
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getPriorityDetails() {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_PRIORITY_DETAILS_URL).then(response => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_PRIORITY_DETAILS,
          payload: response.data.Item
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function updateMfgOrderStatus(mfgOrderDetailsObj, mfgOrderId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(mfgOrderConstant.SET_UPDATE_MFG_ORDER_STATUS_URL + mfgOrderId,
      mfgOrderDetailsObj).then(response => {
        if (response.status == 200) {
          axios.get(mfgOrderConstant.GET_MFG_ORDER_LIST_URL,
            { params: PaginationUtil.getOpenStatusParams() }).then(response => {
              if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
                dispatch({
                  type: mfgOrderActionTypes.GET_MFG_ORDER_LIST,
                  payload: response.data
                })
              }
            }
            )
        }
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function getReceivedStockList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_RECEIVED_STOCK_LIST_URL +
      params.entityCode + "/" + params.isProduct).then((response) => {
        if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
          dispatch({
            type: mfgOrderActionTypes.GET_RECEIVED_STOCK_LIST,
            payload: response.data,
          });
        }
        dispatch(endAjaxCall());
      }).catch((error) => {
        dispatch(endAjaxCall());
      });
  };
}


export function updateMfgOrderDeleteStatus(mfgOrderDetailsObj, mfgOrderId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(mfgOrderConstant.SET_UPDATE_MFG_ORDER_STATUS_URL + mfgOrderId,
      mfgOrderDetailsObj).then(response => {
        dispatch(setAjaxCallStatus(updateApiResponse(response)));
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  };
}

export function updateMfgOrderPriority(priorityList) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(mfgOrderConstant.SET_UPDATE_MFG_ORDER_PRIORITY_URL, priorityList).then(response => {
      if (response.status == 200) {
        axios.get(mfgOrderConstant.GET_MFG_ORDER_PRIORITY_DETAILS_URL).then(response => {
          if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
            dispatch({
              type: mfgOrderActionTypes.GET_MFG_ORDER_PRIORITY_DETAILS,
              payload: response.data.Item
            });
          }
          dispatch(endAjaxCall());
        }).catch((error) => {
          dispatch(endAjaxCall());
        });
      }
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function getMfgOrderReportList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_REPORT_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_REPORT_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getMfgOrderCostReportList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_REPORT_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_COST_REPORT_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getMfgOTIFList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_OTIF_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_OTIF_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function downLoadXLSFile(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.DOWNLOAD_XLS_FILE_URL, { params }).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        const filename = params.status === 'completed' ? "COMPLETED_MAKE_ORDERS.xlsx" : "OPEN_MAKE_ORDERS.xlsx";
        CommonUtil.getXlsResponseDownloadFile(response.data, filename);
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

export function getMfgOrderSearchList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(mfgOrderConstant.GET_MFG_ORDER_SEARCH_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: mfgOrderActionTypes.GET_MFG_ORDER_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}

export function getETAForResource({facilityId, resourceCode, requiredHours}) {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    axios.post(`${mfgOrderConstant.GET_ETA_BASE_URL}/${facilityId}/${resourceCode}`, {requiredHours, requestedDate: new Date()}).then(response => {
      dispatch({type: mfgOrderActionTypes.GET_ETA, payload: response.data.etaObject});
    }).catch(error => {
      dispatch({type: mfgOrderActionTypes.GET_ETA_ERROR, payload: error});
    }).finally(() => {
      dispatch(endAjaxCall());
    });
  }
}
export function downloadInvoice(id, key) {
  return function (dispatch) {
    let downloadURL = '';
    downloadURL = `${mfgOrderConstant.DOWNLOAD_INVOICE_URL}${id}?downloadType=${key}`

    dispatch(beginAjaxCall());
    axios.get(downloadURL).then((response) => {
      if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
        CommonUtil.downloadPdf(response.data.Location, response.data.key, CommonUtil.saveAs);
      }
      dispatch(endAjaxCall());
    }).catch((error) => {
      dispatch(endAjaxCall());
    });
  };
}

// export function downloadSelectedInvoice(params) {
//   return function (dispatch) {
//     // let downloadURL = '';
//     // downloadURL = mfgOrderConstant.DOWNLOAD_INVOICE_URL,{ params: params }

//     dispatch(beginAjaxCall());
//     axios.get(mfgOrderConstant.DOWNLOAD_INVOICE_URL,{ params: params }).then((response) => {
//       if (response.status == 200 && CommonUtil.isNotNull(response.data)) {
//         CommonUtil.downloadPdf(response.data.Location, response.data.key, CommonUtil.saveAs);
//       }
//       dispatch(endAjaxCall());
//     }).catch((error) => {
//       dispatch(endAjaxCall());
//     });
//   };
// }



export function downloadSelectedInvoice(ids) {
  return function (dispatch) {
    const payload = {
      ids: ids
    };
    dispatch(beginAjaxCall());
    axios.post(mfgOrderConstant.DOWNLOAD_INVOICE_URL, 
     {...payload})
      .then((response) => {
        if (response.status === 200 && CommonUtil.isNotNull(response.data)) {
          CommonUtil.downloadPdf(response.data.Location, response.data.key, CommonUtil.saveAs);
        }
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        console.error("Error downloading invoices:", error);
        dispatch(endAjaxCall());
      });
  };
}

