import * as auditActionTypes from "../actions/auditActionTypes";

const initialState = {
  auditDetails: null,
  auditList: null,
};

const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case auditActionTypes.SET_AUDIT_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case auditActionTypes.GET_AUDIT_DETAILS:
      return {
        ...state,
        auditDetails: action.payload,
      };
    case auditActionTypes.GET_AUDIT_LIST:
      return {
        ...state,
        auditList: action.payload,
      };
    default:
      return state;
  }
};

export default auditReducer;
