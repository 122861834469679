import * as actionTypes from '../actions/actionTypes';
import * as barCodeActionTypes from '../actions/barCodeActionTypes';

const initialState = {
    qrcodes: [],
    qrcode: {},
    qrCodeDetails: null,
    createSucessMessage: false,
    createErrorMessage: false,
    selectedUTICode: null,
    products: [],
    batches: [],
    barCodeDetails: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case barCodeActionTypes.SET_REDUCER_INIT_MODE:
            return {
                ...initialState,
            };
        case actionTypes.GET_QRCODE_DETAILS: {
            return {
                ...state,
                qrCodeDetails: action.payload
            }
        }
        case actionTypes.CLEAR_ALL_QRCODES: {
            return {
                ...state,
                qrcodes: []
            }
        }
        case actionTypes.CLEAR_POPUP_MESSAGES: {
            return {
                ...state,
                createErrorMessage: false,
                createSucessMessage: false
            }
        }
        case barCodeActionTypes.SET_SELECTED_UTI_CODE: {
            return {
                ...state,
                selectedUTICode: action.payload
            }
        }
        case barCodeActionTypes.SET_BARCODE_DETAILS: {
            return {
                ...state,
                barCodeDetails: action.payload
            }
        }
        case barCodeActionTypes.GET_BARCODE_DETAILS: {
            return {
                ...state,
                barCodeDetails: action.payload
            }
        }
        default: {
            return state;
        }
    }
}