/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Dashboard from "views/Dashboard.jsx";
import dashboard from "assets/img/dashboard.svg";
import sell from "assets/img/sell.svg";
import make from "assets/img/make.svg";
import list from "assets/img/list1.svg";
import scan from "assets/img/scan.svg";
import source from "assets/img/source.svg";
import insights from "assets/img/insights.svg";
import trace from "assets/img/trace.svg";
import inventory from "assets/img/inventory.svg";
import flour from "assets/img/flour.svg";
import product from "assets/img/product.svg";
import patient from "assets/img/patient.svg";
import setting from "assets/img/setting.svg";
import info from "assets/img/info.svg";
import operations from "assets/img/Operations.svg";
import users from "assets/img/Users.svg";
import documentmangment from "assets/img/Document-Mangment.svg";
import integrations from "assets/img/integrations.svg";
import facility from "assets/img/facility.svg";
import supplier from "assets/img/supplier.svg";
import customer from "assets/img/customer.svg";
import kit from "assets/img/kit.svg";
import stockadjustment from "assets/img/stockadjustment.svg";
import report from "assets/img/reporting icon-02.png";
import stocktransfer from "assets/img/stocktransfer.svg";
import automation from "assets/img/automation.svg";
import requirement from "assets/img/requirement.svg";
import demandPlanning from "assets/img/demand-planning.svg";
import mapping from "assets/img/mapping.svg";
import repack from "assets/img/repack.svg";
import stocktransfernew from "assets/img/stock-transfer.svg";
import campaign from "assets/img/campaign.svg";
import audit from "assets/img/audit.svg";
import creditcard from "assets/img/credit-card.svg";
import time from "assets/img/time.svg";
import quotation from "assets/img/request-quote.svg";
import shipment from "assets/img/tracking.svg";

import {
  leftPanel,
  topPanel,
  participant,
  productOwner,
  superUser,
} from "./constants/GlobalConstants";

import { lazy } from "react";

const CreateProduct = lazy(() =>
  import("./modules/productManagement/views/CreateProduct")
);
const ManageProduct = lazy(() =>
  import("./modules/productManagement/views/ManageProduct")
);
const ManageStockTransfer = lazy(() =>
  import("./modules/stockTransfer/views/ManageStockTransfer")
);
const CreateStockTransfer = lazy(() =>
  import("./modules/stockTransfer/views/CreateStockTransfer")
);
const ManageStockKit = lazy(() =>
  import("./modules/stockKit/views/ManageStockKit")
);
const CreateStockKit = lazy(() =>
  import("./modules/stockKit/views/CreateStockKit")
);
const ManageInventory = lazy(() =>
  import("./modules/inventory/views/ManageInventory")
);
const ManageStockCount = lazy(() =>
  import("./modules/stockCount/views/ManageStockCount")
);
const CreateStockCount = lazy(() =>
  import("./modules/stockCount/views/CreateStockCount")
);
const Settings = lazy(() => import("./modules/settings/views/settings"));
const BulkExecution = lazy(() =>
  import("./modules/bulkimport/views/BulkExecution")
);
const ManageSupplier = lazy(() =>
  import("./modules/supplierManagement/views/ManageSupplier")
);
const CreateSupplier = lazy(() =>
  import("./modules/supplierManagement/views/CreateSupplier")
);
const Dashboard = lazy(() => import("./modules/dashboard/views/Dashboard.js"));
const ConfigurePipeline = lazy(() =>
  import("./modules/configurePipeLine/views/ConfigurePipeLine")
);
const ManageQRCode = lazy(() =>
  import("./modules/CreateQRCode/views/ManageQRCode")
);
const ManageQRCodeFp = lazy(() =>
  import("./modules/CreateQRCodeFp/views/ManageQRCode")
);

const ManageTenant = lazy(() =>
  import("./modules/osAdmin/tenant/views/ManageTenant")
);
const UpdateTenant = lazy(() =>
  import("./modules/osAdmin/tenant/views/UpdateTenant")
);
const ManageFacility = lazy(() =>
  import("./modules/facilityManagement/views/ManageFacility")
);
const CreateFacility = lazy(() =>
  import("./modules/facilityManagement/views/CreateFacility")
);
const ManageCustomer = lazy(() =>
  import("./modules/customer/views/ManageCustomer")
);
const CreateCustomer = lazy(() =>
  import("./modules/customer/views/CreateCustomer")
);
const QRCode = lazy(() => import("./modules/CreateQRCode/views/QRCode"));
const BarCode = lazy(() =>
  import("./modules/CreateQRCode/views/CreateBarCode")
);
const EditQRCodePage = lazy(() =>
  import("./modules/CreateQRCode/views/EditQRCodePage")
);
const QRCodeFp = lazy(() => import("./modules/CreateQRCodeFp/views/QRCode"));
const BarCodeFp = lazy(() =>
  import("./modules/CreateQRCodeFp/views/CreateBarCode")
);
const EditQRCodePageFp = lazy(() =>
  import("./modules/CreateQRCodeFp/views/EditQRCodePage")
);
const PreviewConsumerPage = lazy(() =>
  import("./modules/CreateQRCode/views/PreviewConsumerPage")
);
const PreviewConsumerPageFp = lazy(() =>
  import("./modules/CreateQRCodeFp/views/PreviewConsumerPage")
);
const ManageBatch = lazy(() =>
  import("./modules/configurePipeLine/views/ManageBatch")
);
const LeadCapture = lazy(() =>
  import("./modules/leadCapture/views/ManageLeadCapture")
);
const ManagePageVisit = lazy(() =>
  import("./modules/leadCapture/views/ManagePageVisit")
);
const CmsPage = lazy(() => import("./modules/cms/views/CmsPage"));
const ManageDocument = lazy(() =>
  import("./modules/dashboard/views/ManageDocument")
);
const ManageDocuments = lazy(() =>
  import("./modules/documentManagement/views/ManageDocument")
);
const LoginPage = lazy(() => import("./modules/security/views/LoginPage"));
const SignUpPage = lazy(() => import("./modules/security/views/SignUpForm"));
const ChangePassword = lazy(() =>
  import("./modules/security/views/ChangePassword")
);
const ForgotPassword = lazy(() =>
  import("./modules/security/views/ForgotPassword")
);
const Confirmation = lazy(() =>
  import("./modules/security/views/Confirmation")
);
const Suspended = lazy(() => import("./modules/security/views/Suspended"));
const NewPassword = lazy(() => import("./modules/security/views/NewPassword"));
const ManageUser = lazy(() =>
  import("./modules/userManagement/views/ManageUser")
);
const CreateUser = lazy(() =>
  import("./modules/userManagement/views/CreateUser")
);
const MyProfile = lazy(() =>
  import("./modules/userManagement/views/MyProfile.jsx")
);
const OrderConfirmation = lazy(() =>
  import("./modules/userManagement/views/OrderConfirmation.jsx")
);
const PaymentsSuccess = lazy(() =>
  import("./modules/userManagement/views/PaymentsSuccess.jsx")
);
const PaymentsFailure = lazy(() =>
  import("./modules/userManagement/views/PaymentsFailure.jsx")
);
const CreateDataDictionary = lazy(() =>
  import("./modules/dataDictionary/views/createDataDictionary")
);
const CreateMfgOrder = lazy(() =>
  import("./modules/manufacturingOrder/views/CreateMfgOrder")
);
const ManageMfgOrder = lazy(() =>
  import("./modules/manufacturingOrder/views/ManageMfgOrder")
);
const CreateIngredient = lazy(() =>
  import("./modules/ingredientManagement/views/CreateIngredient")
);
const ManageIngredient = lazy(() =>
  import("./modules/ingredientManagement/views/ManageIngredient")
);
const ManageOperation = lazy(() =>
  import("./modules/operation/views/ManageOperation")
);
const ManageFinance = lazy(() =>
  import("./modules/finance/view/ManageFinance")
);
const ProductMapping = lazy(() =>
  import("./modules/productMapping/views/ProductMapping")
);
const ManageAutomation = lazy(() =>
  import("./modules/automation/views/ManageAutomation")
);
const CreateAutomation = lazy(() =>
  import("./modules/automation/views/CreateAutomation")
);
const ManageRequirement = lazy(() =>
  import("./modules/requirement/views/ManageRequirement")
);
const DemandPlanning = lazy(() =>
  import("./modules/demandPlanning/views/DemandPlanning")
);
const CreateStockAdjustment = lazy(() =>
  import("./modules/stockAdjustment/views/CreateStockAdjustment")
);
const ManageStockAdjustment = lazy(() =>
  import("./modules/stockAdjustment/views/ManageStockAdjustment")
);
const ManageReport = lazy(() =>
  import("./modules/reporting/views/ManageReport")
);
const ManageAudit = lazy(() => import("./modules/audit/views/ManageAudit"));
const TimeSheet = lazy(() => import("./modules/timesheet/views/Timesheet"));
const ManageSaleTax = lazy(() =>
  import("./modules/saleTax/views/ManageSaleTax")
);
const CreateSaleTax = lazy(() =>
  import("./modules/saleTax/views/CreateSaleTax")
);
const ManageIncident = lazy(() =>
  import("./modules/incident/views/ManageIncident")
);
const CreateIncident = lazy(() =>
  import("./modules/incident/views/CreateIncident")
);
const ManageTraceability = lazy(() =>
  import("./modules/traceabilityManagement/views/ManageTraceability")
);
const Traceability = lazy(() =>
  import("./modules/traceabilityManagement/views/Traceability")
);
const Integrations = lazy(() =>
  import("./modules/integrations/views/Integrations")
);
const ManagePurchaseOrderAMS = lazy(() =>
  import("./modules/purchaseOrderAMS/views/ManagePurchaseOrder")
);
const CreatePurchaseOrderAMS = lazy(() =>
  import("./modules/purchaseOrderAMS/views/CreatePurchaseOrder")
);
const ManageSaleOrderAMS = lazy(() =>
  import("./modules/saleOrderAMS/views/ManageSaleOrder")
);
const CreateSaleOrderAMS = lazy(() =>
  import("./modules/saleOrderAMS/views/CreateSaleOrder")
);
const ManageShipmentOrder = lazy(() =>
  import("./modules/shipmentOrder/views/ManageShipmentOrder")
);
const CreateShipmentOrder = lazy(() =>
  import("./modules/shipmentOrder/views/CreateShipmentOrder")
);
const Quotation = lazy(() => import("./modules/quotation/views/Quotation.js"));
const CreateQuotationOrder = lazy(() =>
  import("./modules/quotation/views/CreateQuotationOrder")
);
const QuotationConfirmation = lazy(() =>
  import("./modules/security/views/QuotationConfirmation")
);
const Cardpayment = lazy(() => import("./modules/security/views/CardPayment"));
const ManageRepackOrder = lazy(() =>
  import("./modules/repackOrder/views/ManageRepackOrder")
);
const CreateRepackOrder = lazy(() =>
  import("./modules/repackOrder/views/CreateRepackOrder")
);
const ManageCampaign = lazy(() =>
  import("./modules/campaign/views/ManageCampaign")
);
const CreateCampaign = lazy(() =>
  import("./modules/campaign/views/CreateCampaign")
);
const ManageRoles = lazy(() => import("./modules/roles/views/ManageRoles"));
const CreateRole = lazy(() => import("./modules/roles/views/CreateRole"));

const SaleSummary = lazy(() =>
  import("./modules/posReports/saleSummary/views/SaleSummaryReport")
);
const ReportsDetail = lazy(() =>
  import("./modules/posReports/ReportsDetail")
);
const SaleTrends = lazy(() =>
  import("./modules/posReports/saleTrends/views/SaleTrends")
);
const CategorySales = lazy(() =>
  import("./modules/posReports/categorySales/views/CategorySales")
);
const CostOfGoodsSold = lazy(() =>
  import("./modules/posReports/costOfGoodsSold/views/CostOfGoodsSold")
);
const Fees = lazy(() => import("./modules/posReports/fees/views/Fees"));
const InventorySell = lazy(() =>
  import("./modules/posReports/invertorySell/views/InventorySell")
);
const ItemSalesReport = lazy(() =>
  import("./modules/posReports/itemSalesReport/views/ItemSalesReport")
);
const DiscountReport = lazy(() =>
  import("./modules/posReports/discountReport/views/DiscountReport")
);
const TaxReports = lazy(() =>
  import("./modules/posReports/taxesReport/views/TaxReports")
);
const TeamSalesReport = lazy(() =>
  import("./modules/posReports/teamSalesReport/views/TeamSalesReport")
);
const PaymentMethods = lazy(() =>
  import("./modules/posReports/paymentMethods/views/PaymentMethods")
);
const LaborVsSales = lazy(() =>
  import("./modules/posReports/labourVsSales/views/LaborVsSales")
);
const InventoryByCategory = lazy(() =>
  import("./modules/posReports/inventoryByCategory/views/InventoryByCategory")
);
const CommissionReport = lazy(() =>
  import("./modules/posReports/commissionReport/views/CommissionReport")
);
const SalesByCustomer = lazy(() =>
  import("./modules/erpReports/salesByCustomer/views/SalesByCustomer")
);
const SalesItems = lazy(() =>
  import("./modules/erpReports/salesItems/views/SalesItems")
);
const SalesReturnHistory = lazy(() =>
  import("./modules/erpReports/salesReturnsHistory/views/SalesReturnHistory")
);
const SalesBySales = lazy(() =>
  import("./modules/erpReports/salesBySales/views/SalesBySales")
);
const PackingHistory = lazy(() =>
  import("./modules/erpReports/packingHistory/views/PackingHistory")
);
const InventorySummary = lazy(() =>
  import("./modules/erpReports/inventorySummary/views/InventorySummary")
);
const CommittedStocks = lazy(() =>
  import(
    "./modules/erpReports/committedStockDetails/views/CommittedStockDetails"
  )
);
const InventoryValuationSummary = lazy(() =>
  import(
    "./modules/erpReports/inventoryValuationSummary/views/inventoryValuationSummary"
  )
);
const ErpReports = lazy(() => import("./modules/erpReports/ErpReports"));
const PosReports = lazy(() => import("./modules/posReports/PosReports"));
const InventoryAgingSummary = lazy(() =>
  import(
    "./modules/erpReports/inventoryAgingSummary/views/inventoryAgingSummary"
  )
);
const ProductSalesReport = lazy(() =>
  import("./modules/erpReports/productSalesReport/views/ProductSalesReport")
);
const WarehouseReport = lazy(() =>
  import("./modules/erpReports/warehouseReport/views/warehouseReport")
);
const StockSummaryReport = lazy(() =>
  import("./modules/erpReports/stockSummaryReport/views/StockSummaryReport")
);
const CustomerBalance = lazy(() =>
  import("./modules/erpReports/customerBalance/views/CustomerBalance")
);
const InvoiceDetails = lazy(() =>
  import("./modules/erpReports/invoiceDetails/views/InvoiceDetails")
);
const SalesOrderDetails = lazy(() =>
  import("./modules/erpReports/salesOrderDetails/views/SalesOrderDetails")
);
const EstimateDetails = lazy(() =>
  import("./modules/erpReports/estimateDetails/views/EstimateDetails")
);
const CustomerBalanceSummary = lazy(() =>
  import(
    "./modules/erpReports/customerBalanceSummary/views/CustomerBalanceSummary"
  )
);
const ReceivableSummary = lazy(() =>
  import("./modules/erpReports/receivableSummary/views/ReceivableSummary")
);
const ReceivableDetails = lazy(() =>
  import("./modules/erpReports/receivableDetails/views/ReceivableDetails")
);
const PaymentsReceived = lazy(() =>
  import("./modules/erpReports/paymentsReceived/views/PaymentsReceived")
);
const TimeToGetPaid = lazy(() =>
  import("./modules/erpReports/timeToGetPaid/views/TimeToGetPaid")
);
const RefundHistory = lazy(() =>
  import("./modules/erpReports/refundHistory/views/RefundHistory")
);
const CreditNoteDetails = lazy(() =>
  import("./modules/erpReports/creditNoteDetails/views/CreditNoteDetails")
);

export default function getRoutes({ track }) {
  // if(track == "uti") {
  var routes = [
    {
      path: "/dashboard",
      layout: "/admin",
      name: "Dashboard",
      state: "openDashboard",
      icon: dashboard,
      collapse: true,
      component: Dashboard,
      positions: [topPanel],
      topPanelName: "Dashboard",
      id: "dashboard",
      views: [
        {
          path: "/dashboard",
          layout: "/admin",
          name: "Dashboard",
          mini: "D",
          component: Dashboard,
        },
        {
          path: "/manage-page-visit",
          layout: "/admin",
          name: "Page Visits",
          mini: "MP",
          component: ManagePageVisit,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-user",
      name: "Users",
      layout: "/admin",
      state: "openUser",
      icon: users,
      component: ManageUser,
      positions: [leftPanel],
      id: "manageUser",
      views: [
        {
          path: "/manage-user",
          layout: "/admin",
          name: "Manage User",
          mini: "MU",
          component: ManageUser,
        },
        {
          path: "/create-user",
          layout: "/admin",
          name: "Create User",
          mini: "CU",
          component: CreateUser,
        },
        {
          path: "/my-profile",
          layout: "/admin",
          name: "My Profile",
          mini: "MP",
          component: MyProfile,
        },
        {
          path: "/confirmation",
          layout: "/admin",
          name: "Comfirmation",
          mini: "C",
          component: OrderConfirmation,
        },
        {
          path: "/paymentssuccess",
          layout: "/admin",
          name: "PaymentSuccess",
          mini: "C",
          component: PaymentsSuccess,
        },
        {
          path: "/paymentsfailure",
          layout: "/admin",
          name: "PaymentFailure",
          mini: "C",
          component: PaymentsFailure,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-product",
      name: "Product",
      layout: "/admin",
      state: "openProducts",
      icon: product,
      positions: [leftPanel],
      id: "manageProduct",
      views: [
        {
          path: "/manage-product",
          layout: "/admin",
          name: "Manage Product",
          mini: "MP",
          component: ManageProduct,
        },
        {
          path: "/create-product",
          layout: "/admin",
          name: "Create Product",
          mini: "CP",
          component: CreateProduct,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-tenant",
      name: "Tenant",
      layout: "/admin",
      state: "openTenant",
      icon: product,
      positions: [leftPanel],
      id: "manageTenant",
      views: [
        {
          path: "/manage-tenant",
          layout: "/admin",
          name: "Manage Tenant",
          mini: "MT",
          component: ManageTenant,
        },
      ],
    },
    {
      collapse: true,
      path: "/update-tenant",
      name: "Update Tenant",
      layout: "/admin",
      state: "openTenant",
      icon: product,
      positions: [leftPanel],
      id: "updateTenant",
      views: [
        {
          path: "/update-tenant",
          layout: "/admin",
          name: "Update Tenant",
          mini: "UT",
          component: UpdateTenant,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-cms",
      name: "CMS",
      layout: "/admin",
      state: "openCMS",
      icon: users,
      redirect: true,
      positions: [leftPanel],
      id: "manageCms",
      views: [
        {
          path: "/manage-cms",
          layout: "/admin",
          name: "Manage CMS",
          mini: "MP",
          component: CmsPage,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-ingredient",
      name: "Ingredient",
      layout: "/admin",
      state: "openIngredient",
      icon: flour,
      positions: [leftPanel],
      id: "ingredient",
      views: [
        {
          path: "/manage-ingredient",
          layout: "/admin",
          name: "Manage ingredient",
          mini: "MP",
          component: ManageIngredient,
        },
        {
          path: "/create-ingredient",
          layout: "/admin",
          name: "Create ingredient",
          mini: "CP",
          component: CreateIngredient,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-supplier",
      name: "Supplier",
      layout: "/admin",
      state: "openSupplier",
      icon: supplier,
      positions: [leftPanel],
      id: "supplier",
      views: [
        {
          path: "/manage-supplier",
          layout: "/admin",
          name: "Manage Supplier",
          mini: "MS",
          component: ManageSupplier,
        },
        {
          path: "/create-supplier",
          layout: "/admin",
          name: "Create Supplier",
          mini: "CS",
          component: CreateSupplier,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-customer",
      name: "Customer",
      layout: "/admin",
      state: "openCustomer",
      icon: customer,
      positions: [leftPanel],
      id: "customer",
      views: [
        {
          path: "/manage-customer",
          layout: "/admin",
          name: "Manage Customer",
          mini: "MC",
          component: ManageCustomer,
        },
        {
          path: "/create-customer",
          layout: "/admin",
          name: "Create Customer",
          mini: "CC",
          component: CreateCustomer,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-facility",
      name: "Facility",
      layout: "/admin",
      state: "openFacilities",
      icon: facility,
      positions: [leftPanel],
      id: "facility",
      views: [
        {
          path: "/manage-facility",
          layout: "/admin",
          name: "Manage Facility",
          mini: "MF",
          component: ManageFacility,
        },
        {
          path: "/create-facility",
          layout: "/admin",
          name: "Create Facility",
          mini: "CF",
          component: CreateFacility,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-qrcode",
      name: "Market - QR Code",
      layout: "/admin",
      state: "openQRCode",
      icon: scan,
      positions: [leftPanel],
      id: "qrcode",
      views: [
        {
          path: "/manage-qrcode",
          layout: "/admin",
          name: "Manage QR Code",
          mini: "MQ",
          component: ManageQRCode,
        },
        {
          path: "/create-qrcode",
          layout: "/admin",
          name: "Create QR Code",
          mini: "CQ",
          component: QRCode,
        },
        {
          path: "/edit-qrcode",
          layout: "/admin",
          name: "Edit QR Code",
          mini: "EQ",
          component: EditQRCodePage,
        },
        {
          path: "/preview",
          layout: "/admin",
          name: "Preview Consumer Page",
          mini: "PCP",
          component: PreviewConsumerPage,
        },
        {
          path: "/create-barcode",
          layout: "/admin",
          name: "Create Bar Code",
          mini: "CB",
          component: BarCode,
        },
      ],
    },
    // {
    //     collapse: true,
    //     path: "/demandplanning",
    //     name: "Demand Planning",
    //     layout: "/admin",
    //     state: "openDemandPlanning",
    //     icon: demandPlanning,
    //     positions: [leftPanel],
    //     id: "automation",
    //     views: [{
    //             path: "/demandplanning",
    //             layout: "/admin",
    //             name: "Demand Planning",
    //             mini: "MA",
    //             component: DemandPlanning,
    //         },
    //     ],
    // },
    // {
    //     collapse: true,
    //     path: "/manage-requirement",
    //     name: "Requirement",
    //     layout: "/admin",
    //     state: "openRequirement",
    //     icon: requirement,
    //     positions: [leftPanel],
    //     id: "automation",
    //     views: [{
    //             path: "/manage-requirement",
    //             layout: "/admin",
    //             name: "Requirement",
    //             mini: "MA",
    //             component: ManageRequirement,
    //         },
    //     ],
    // },
    // {
    // collapse: true,
    // path: "/batch-detail",
    // name: "Batch Detail",
    // layout: "/admin",
    // state: "openPipelines",
    // icon: list,
    // positions: [leftPanel],
    // id: "bathDetails",
    //     views: [{
    //             path: "/batch-detail",
    //             layout: "/admin",
    //             name: "Batch Detail",
    //             mini: "MP",
    //             component: ManageBatch,
    //         },
    //         {
    //             path: "/configure-pipeline",
    //             layout: "/admin",
    //             name: "Configure Pipeline",
    //             mini: "CP",
    //             component: ConfigurePipeline,
    //         },
    //     ],
    // },
    {
      collapse: true,
      path: "/manage-sale-order",
      name: "Sale Order",
      layout: "/admin",
      state: "openSaleOrder",
      icon: sell,
      positions: [topPanel],
      topPanelName: "Sell",
      id: "salesOrder",
      views: [
        {
          path: "/manage-sale-order",
          layout: "/admin",
          name: "Manage Sale Order",
          mini: "MSO",
          component: ManageSaleOrderAMS,
        },
        {
          path: "/create-sale-order",
          layout: "/admin",
          name: "Create Sale Order",
          mini: "CSO",
          component: CreateSaleOrderAMS,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-shipment-order",
      name: "Shipment",
      layout: "/admin",
      state: "openShipmentOrder",
      icon: shipment,
      positions: [topPanel],
      topPanelName: "Shipment",
      id: "shipmentView",
      views: [
        {
          path: "/manage-shipment-order",
          layout: "/admin",
          name: "Manage Shipment Order",
          mini: "MSO",
          component: ManageShipmentOrder,
        },
        {
          path: "/create-shipment-order",
          layout: "/admin",
          name: "Create Shipment Order",
          mini: "CSO",
          component: CreateShipmentOrder,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-quotation-order",
      name: "Quotation",
      layout: "/admin",
      state: "openQuotationOrder",
      icon: quotation,
      positions: [topPanel],
      topPanelName: "Quotation",
      id: "QuotationView",
      views: [
        {
          path: "/manage-quotation-order",
          layout: "/admin",
          name: "Manage Quotation Order",
          mini: "MQO",
          component: Quotation,
        },
        {
          path: "/create-quotation-order",
          layout: "/admin",
          name: "Create Quotation Order",
          mini: "CQO",
          component: CreateQuotationOrder,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-incident",
      name: "Incident",
      layout: "/admin",
      state: "openIncident",
      icon: info,
      positions: [leftPanel],
      id: "incident",
      views: [
        {
          path: "/manage-incident",
          layout: "/admin",
          name: "Manage Incident",
          mini: "MI",
          component: ManageIncident,
        },
        {
          path: "/create-incident",
          layout: "/admin",
          name: "Create Incident",
          mini: "CI",
          component: CreateIncident,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-documents",
      layout: "/admin",
      name: "Document Management",
      icon: documentmangment,
      component: ManageDocuments,
      positions: [leftPanel],
      id: "managedocuments",
      views: [
        {
          path: "/manage-documents",
          layout: "/admin",
          name: "Document Management",
          mini: "DM",
          component: ManageDocuments,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-stock-adjustment",
      name: "Return / Adjustment",
      layout: "/admin",
      state: "openStockAdjustment",
      icon: stockadjustment,
      positions: [leftPanel],
      id: "stockAdjustmentView",
      views: [
        {
          path: "/create-stock-adjustment",
          layout: "/admin",
          name: "Create Return / Adjustment",
          mini: "MS",
          component: CreateStockAdjustment,
        },
        {
          path: "/manage-stock-adjustment",
          layout: "/admin",
          name: "Manage Return / Adjustment",
          mini: "MS",
          component: ManageStockAdjustment,
        },
      ],
    },
    // {
    //   collapse: true,
    //   path: "/manage-report",
    //   name: "Reporting",
    //   layout: "/admin",
    //   state: "openReport",
    //   icon: report,
    //   positions: [leftPanel],
    //   id: "reporting",
    //   views: [
    //     {
    //       path: "/manage-report",
    //       layout: "/admin",
    //       name: "Manage Report",
    //       mini: "MR",
    //       component: ManageReport,
    //     },
    //   ],
    // },
    {
      collapse: true,
      path: "/manage-audit",
      name: "Activity Log",
      layout: "/admin",
      state: "openAudit",
      icon: audit,
      positions: [leftPanel],
      id: "activity",
      views: [
        {
          path: "/manage-audit",
          layout: "/admin",
          name: "Manage Audit",
          mini: "MR",
          component: ManageAudit,
        },
      ],
    },
    {
      collapse: true,
      path: "/timesheet",
      name: "Timesheet",
      layout: "/admin",
      state: "openTimesheet",
      icon: time,
      positions: [leftPanel],
      id: "timesheet",
      views: [
        {
          path: "/timesheet",
          layout: "/admin",
          name: "Timesheet",
          mini: "TS",
          component: TimeSheet,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-sale-tax",
      name: "Taxes",
      layout: "/admin",
      state: "openAudit",
      icon: creditcard,
      positions: [leftPanel],
      id: "taxView",
      views: [
        {
          path: "/manage-sale-tax",
          layout: "/admin",
          name: "Manage Sale Tax",
          mini: "MST",
          component: ManageSaleTax,
        },
        {
          path: "/create-sale-tax",
          layout: "/admin",
          name: "Create Sale Tax",
          mini: "MST",
          component: CreateSaleTax,
        },
      ],
    },
    {
      collapse: true,
      path: "/product-mapping",
      layout: "/admin",
      name: "Mapping",
      icon: mapping,
      component: ProductMapping,
      positions: [leftPanel],
      id: "productMapping",
      views: [
        {
          path: "/product-mapping",
          layout: "/admin",
          name: "Mapping",
          mini: "M",
          component: ProductMapping,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-campaign",
      name: "Campaign",
      layout: "/admin",
      state: "openCampaign",
      icon: campaign,
      positions: [leftPanel],
      id: "campaign",
      views: [
        {
          path: "/manage-campaign",
          layout: "/admin",
          name: "Manage Campaign",
          mini: "MS",
          component: ManageCampaign,
        },
        {
          path: "/create-campaign",
          layout: "/admin",
          name: "Create Campaign",
          mini: "CS",
          component: CreateCampaign,
        },
      ],
    },
    {
      collapse: true,
      path: "/pos-reports",
      name: "Reports",
      layout: "/admin",
      state: "opeReports",
      icon: report,
      positions: [leftPanel],
      id: "salesOrder",
      views: [
        {
          path: "/erp-reports",
          layout: "/admin",
          name: "ERP Reports",
          mini: "ERP",
          id: "erp-reports",
          component: ErpReports,
        },
        {
          path: "/pos-reports",
          layout: "/admin",
          name: "POS Reports",
          mini: "POS",
          id: "pos-reports",
          component: PosReports,
        },
        {
          path: "/sale-summary",
          layout: "/admin",
          name: "Sale Summary",
          mini: "SS",
          component: SaleSummary,
        },
        {
          path: "/report-detail",
          layout: "/admin",
          name: "Sale Summary",
          mini: "SS",
          component: ReportsDetail,
        },
        {
          path: "/sale-trends",
          layout: "/admin",
          name: "Sale Trends",
          mini: "ST",
          component: SaleTrends,
        },
        {
          path: "/category-sales",
          layout: "/admin",
          name: "Category Sales",
          mini: "CS",
          component: CategorySales,
        },
        {
          path: "/goods-sold-cost",
          layout: "/admin",
          name: "COGS",
          mini: "CGS",
          component: CostOfGoodsSold,
        },
        {
          path: "/fees-report",
          layout: "/admin",
          name: "Fees",
          mini: "FR",
          component: Fees,
        },
        {
          path: "/inventory-sell",
          layout: "/admin",
          name: "Inventory Sell",
          mini: "IS",
          component: InventorySell,
        },
        {
          path: "/item-sales-report",
          layout: "/admin",
          name: "Item Sales Report",
          mini: "ISR",
          component: ItemSalesReport,
        },
        {
          path: "/discount-report",
          layout: "/admin",
          name: "Discount Report",
          mini: "DR",
          component: DiscountReport,
        },
        {
          path: "/tax-report",
          layout: "/admin",
          name: "Tax Report",
          mini: "TR",
          component: TaxReports,
        },
        {
          path: "/team-sales-report",
          layout: "/admin",
          name: "Team Sales Report",
          mini: "TSR",
          component: TeamSalesReport,
        },
        {
          path: "/labor-vs-sales",
          layout: "/admin",
          name: "Labor Vs Sales",
          mini: "LVS",
          component: LaborVsSales,
        },
        {
          path: "/payment-methods-report",
          layout: "/admin",
          name: "Payment Methods",
          mini: "PM",
          component: PaymentMethods,
        },
        {
          path: "/inventory-by-category",
          layout: "/admin",
          name: "Inventory By Category",
          mini: "PM",
          component: InventoryByCategory,
        },
        {
          path: "/commission-report",
          layout: "/admin",
          name: "Commission Report",
          mini: "CR",
          component: CommissionReport,
        },
        {
          path: "/sales-by-customer",
          layout: "/admin",
          name: "Sales By Customer",
          mini: "SBC",
          component: SalesByCustomer,
        },
        {
          path: "/sales-by-item",
          layout: "/admin",
          name: "Sales By Item",
          mini: "SBI",
          component: SalesItems,
        },
        {
          path: "/sales-return-history",
          layout: "/admin",
          name: "Sales Return History",
          mini: "SRH",
          component: SalesReturnHistory,
        },
        {
          path: "/sales-by-sales",
          layout: "/admin",
          name: "Sales By Sales",
          mini: "SRH",
          component: SalesBySales,
        },
        {
          path: "/packing-history",
          layout: "/admin",
          name: "Packing History",
          mini: "PH",
          component: PackingHistory,
        },
        {
          path: "/inventory-summary",
          layout: "/admin",
          name: "Inventory Summary",
          mini: "IS",
          component: InventorySummary,
        },
        {
          path: "/committed-stocks",
          layout: "/admin",
          name: "Committed Stocks",
          mini: "CS",
          component: CommittedStocks,
        },
        {
          path: "/inventory-valuation",
          layout: "/admin",
          name: "Inventory Valuation",
          mini: "IV",
          component: InventoryValuationSummary,
        },
        {
          path: "/inventory-aging",
          layout: "/admin",
          name: "Inventory Aging",
          mini: "IA",
          component: InventoryAgingSummary,
        },
        {
          path: "/product-sales-report",
          layout: "/admin",
          name: "Product Sales Report",
          mini: "PSR",
          component: ProductSalesReport,
        },
        {
          path: "/warehouse-report",
          layout: "/admin",
          name: "Warehouse Report",
          mini: "WR",
          component: WarehouseReport,
        },
        {
          path: "/stock-summary-report",
          layout: "/admin",
          name: "Stock Summary Report",
          mini: "SSR",
          component: StockSummaryReport,
        },
        {
          path: "/customer-balance",
          layout: "/admin",
          name: "Customer Balance",
          mini: "CB",
          component: CustomerBalance,
        },
        {
          path: "/invoice-details",
          layout: "/admin",
          name: "Invoice Details",
          mini: "ID",
          component: InvoiceDetails,
        },
        {
          path: "/sales-order-details",
          layout: "/admin",
          name: "Sales Order Details",
          mini: "SOD",
          component: SalesOrderDetails,
        },
        {
          path: "/estimate-details",
          layout: "/admin",
          name: "Estimate Details",
          mini: "ED",
          component: EstimateDetails,
        },
        {
          path: "/customer-balance-summary",
          layout: "/admin",
          name: "Customer Balance Summary",
          mini: "CBS",
          component: CustomerBalanceSummary,
        },
        {
          path: "/receivable-summary",
          layout: "/admin",
          name: "Receivable Summary",
          mini: "RS",
          component: ReceivableSummary,
        },
        {
          path: "/receivable-details",
          layout: "/admin",
          name: "Receivable Details",
          mini: "RD",
          component: ReceivableDetails,
        },
        {
          path: "/payments-received",
          layout: "/admin",
          name: "Payments Received",
          mini: "PR",
          component: PaymentsReceived,
        },
        {
          path: "/time-to-get-paid",
          layout: "/admin",
          name: "Time To Get Paid",
          mini: "TTGP",
          component: TimeToGetPaid,
        },
        {
          path: "/refund-history",
          layout: "/admin",
          name: "Refund History",
          mini: "RH",
          component: RefundHistory,
        },
        {
          path: "/credit-note-details",
          layout: "/admin",
          name: "Credit Note Details",
          mini: "CND",
          component: CreditNoteDetails,
        },
      ],
    },
    {
      collapse: true,
      path: "/setting",
      layout: "/admin",
      name: "Settings",
      icon: setting,
      component: Settings,
      positions: [leftPanel],
      id: "adminSettings",
      views: [
        {
          path: "/setting",
          layout: "/admin",
          name: "Setting",
          mini: "S",
          component: Settings,
        },
        {
          path: "/bulk-execution",
          layout: "/admin",
          name: "Bulk Execution",
          mini: "BE",
          component: BulkExecution,
        },
      ],
    },
    {
      path: "/login-page",
      layout: "/auth",
      name: "Login Page",
      icon: users,
      component: LoginPage,
      redirect: true,
      id: "login",
    },
    {
      path: "/register-page",
      layout: "/auth",
      name: "Register Page",
      icon: users,
      component: SignUpPage,
      redirect: true,
      id: "register",
    },
    {
      path: "/changepassword",
      layout: "/auth",
      name: "Change Password",
      icon: users,
      component: ChangePassword,
      redirect: true,
      id: "changepassword",
    },
    {
      path: "/forgotpassword",
      layout: "/auth",
      name: "Forgot Password",
      icon: users,
      component: ForgotPassword,
      redirect: true,
      id: "forgotpassword",
    },
    {
      path: "/confirmation",
      layout: "/auth",
      name: "Confirmation",
      icon: users,
      component: Confirmation,
      redirect: true,
      id: "confirmation",
    },
    {
      path: "/quotation-confirmation",
      layout: "/auth",
      name: "Quotation Confirmation",
      mini: "QC",
      redirect: true,
      component: QuotationConfirmation,
    },
    {
      path: "/cardpayment",
      layout: "/auth",
      name: "Card Payment",
      mini: "CP",
      redirect: true,
      component: Cardpayment,
    },
    {
      path: "/suspended",
      layout: "/auth",
      name: "Suspended",
      icon: users,
      component: Suspended,
      redirect: true,
      id: "suspended",
    },
    {
      path: "/newpassword",
      layout: "/auth",
      name: "Set New Password",
      icon: users,
      component: NewPassword,
      redirect: true,
      id: "newpassword",
    },
    {
      collapse: true,
      path: "/manage-mfg-order",
      name: "Manufacture",
      layout: "/admin",
      state: "openMfgOrder",
      icon: make,
      positions: [topPanel],
      topPanelName: "Make",
      id: "manufacture",
      views: [
        {
          path: "/manage-mfg-order",
          layout: "/admin",
          name: "",
          mini: "MP",
          component: ManageMfgOrder,
        },
        {
          path: "/create-mfg-order",
          layout: "/admin",
          name: "",
          mini: "CP",
          component: CreateMfgOrder,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-purchase-order",
      name: "Purchase Order",
      layout: "/admin",
      state: "openPurchases",
      icon: source,
      positions: [topPanel],
      topPanelName: "Procure",
      id: "purchaseOrder",
      views: [
        {
          path: "/manage-purchase-order",
          layout: "/admin",
          name: "Manage Purchase Order",
          mini: "MPO",
          component: ManagePurchaseOrderAMS,
        },
        {
          path: "/create-purchase-order",
          layout: "/admin",
          name: "Create Purchase Order",
          mini: "CPO",
          component: CreatePurchaseOrderAMS,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-finance",
      name: "Finances",
      layout: "/admin",
      state: "openFinances",
      icon: trace,
      positions: [topPanel],
      topPanelName: "Finance",
      id: "viewFinance",
      views: [
        {
          path: "/manage-finance",
          layout: "/admin",
          name: "Manage Finance",
          mini: "MF",
          component: ManageFinance,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-inventory",
      name: "Inventory",
      layout: "/admin",
      state: "openInventory",
      icon: inventory,
      positions: [topPanel],
      topPanelName: "Inventory",
      component: ManageInventory,
      id: "inventory",
      views: [
        {
          path: "/manage-inventory",
          layout: "/admin",
          name: "Inventory",
          mini: "I",
          component: ManageInventory,
        },
        {
          path: "/manage-stock-count",
          layout: "/admin",
          name: "Manage Stock Count",
          mini: "MSC",
          component: ManageStockCount,
        },
        {
          path: "/create-stock-count",
          layout: "/admin",
          name: "Create Stock Count",
          mini: "CSC",
          component: CreateStockCount,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-automation",
      name: "Inventory",
      layout: "/admin",
      state: "openAutomation",
      icon: automation,
      positions: [topPanel],
      topPanelName: "Automation",
      id: "automation",
      views: [
        {
          path: "/manage-automation",
          layout: "/admin",
          name: "Manage Automatiom",
          mini: "MA",
          component: ManageAutomation,
        },
        {
          path: "/create-automation",
          layout: "/admin",
          name: "Create Automation",
          mini: "CA",
          component: CreateAutomation,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-traceability",
      name: "Trace",
      layout: "/admin",
      state: "openTrace",
      icon: trace,
      positions: [topPanel],
      topPanelName: "Trace",
      id: "trace",
      views: [
        {
          path: "/manage-traceability",
          layout: "/admin",
          name: "Manage Traceability",
          mini: "MT",
          component: ManageTraceability,
        },
        {
          path: "/traceability",
          layout: "/admin",
          name: "Traceability",
          mini: "T",
          component: Traceability,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-stock-transfer",
      name: "Stock Transfer",
      layout: "/admin",
      state: "openStockTransfer",
      icon: stocktransfer,
      positions: [topPanel],
      topPanelName: "Stock Transfer",
      id: "stockTransfer",
      views: [
        {
          path: "/manage-stock-transfer",
          layout: "/admin",
          name: "Manage Stock",
          mini: "MS",
          component: ManageStockTransfer,
        },
        {
          path: "/create-stock-transfer",
          layout: "/admin",
          name: "Create Stock",
          mini: "CS",
          component: CreateStockTransfer,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-stock-kit",
      name: "Bundle",
      layout: "/admin",
      state: "openBundle",
      icon: kit,
      positions: [topPanel],
      topPanelName: "Bundle",
      id: "stockKit",
      views: [
        {
          path: "/manage-stock-kit",
          layout: "/admin",
          name: "Manage Kit",
          mini: "MK",
          component: ManageStockKit,
        },
        {
          path: "/create-stock-kit",
          layout: "/admin",
          name: "Stock Kit",
          mini: "SK",
          component: CreateStockKit,
        },
      ],
    },
    {
      collapse: true,
      path: "/manage-role",
      name: "Roles",
      layout: "/admin",
      state: "openRoles",
      icon: users,
      component: ManageRoles,
      positions: [leftPanel],
      id: "ManageRoles",
      views: [
        {
          path: "/manage-role",
          layout: "/admin",
          name: "Manage Roles",
          mini: "MR",
          component: ManageRoles,
        },
        {
          path: "/create-role",
          layout: "/admin",
          name: "Create Roles",
          mini: "CR",
          component: CreateRole,
        },
      ],
    },
  ];
  // } else {
  //     var routes = [{
  //             path: "/dashboard",
  //             layout: "/admin",
  //             name: "Dashboard",
  //             state: "openDashboard",
  //             icon: dashboard,
  //             collapse: true,
  //             component: Dashboard,
  //             positions: [],
  //             topPanelName: "Dashboard",
  //             id: "dashboard",
  //             views: [{
  //                     path: "/dashboard",
  //                     layout: "/admin",
  //                     name: "Dashboard",
  //                     mini: "D",
  //                     component: Dashboard,
  //                 },
  //                 {
  //                     path: "/manage-page-visit",
  //                     layout: "/admin",
  //                     name: "Page Visits",
  //                     mini: "MP",
  //                     component: ManagePageVisit,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-user",
  //             name: "Users",
  //             layout: "/admin",
  //             state: "openUser",
  //             icon: users,
  //             component: ManageUser,
  //             positions: [leftPanel],
  //             id: "manageUser",
  //             views: [{
  //                     path: "/manage-user",
  //                     layout: "/admin",
  //                     name: "Manage User",
  //                     mini: "MU",
  //                     component: ManageUser,
  //                 },
  //                 {
  //                     path: "/create-user",
  //                     layout: "/admin",
  //                     name: "Create User",
  //                     mini: "CU",
  //                     component: CreateUser,
  //                 },
  //                 {
  //                     path: "/my-profile",
  //                     layout: "/admin",
  //                     name: "My Profile",
  //                     mini: "MP",
  //                     component: MyProfile,
  //                 },
  //                 {
  //                     path: "/confirmation",
  //                     layout: "/admin",
  //                     name: "Comfirmation",
  //                     mini: "C",
  //                     component: OrderConfirmation,
  //                 },
  //                 {
  //                     path: "/paymentssuccess",
  //                     layout: "/admin",
  //                     name: "PaymentSuccess",
  //                     mini: "C",
  //                     component: PaymentsSuccess,
  //                 },
  //                 {
  //                     path: "/paymentsfailure",
  //                     layout: "/admin",
  //                     name: "PaymentFailure",
  //                     mini: "C",
  //                     component: PaymentsFailure,
  //                 }
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-product",
  //             name: "Product",
  //             layout: "/admin",
  //             state: "openProducts",
  //             icon: product,
  //             positions: [leftPanel],
  //             id: "manageProduct",
  //             views: [{
  //                     path: "/manage-product",
  //                     layout: "/admin",
  //                     name: "Manage Product",
  //                     mini: "MP",
  //                     component: ManageProductFp,
  //                 },
  //                 {
  //                     path: "/create-product",
  //                     layout: "/admin",
  //                     name: "Create Product",
  //                     mini: "CP",
  //                     component: CreateProductFp,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-tenant",
  //             name: "Tenant",
  //             layout: "/admin",
  //             state: "openTenant",
  //             icon: product,
  //             positions: [leftPanel],
  //             id: "manageTenant",
  //             views: [{
  //                     path: "/manage-tenant",
  //                     layout: "/admin",
  //                     name: "Manage Tenant",
  //                     mini: "MT",
  //                     component: ManageTenant,
  //                 },

  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/update-tenant",
  //             name: "Update Tenant",
  //             layout: "/admin",
  //             state: "openTenant",
  //             icon: product,
  //             positions: [leftPanel],
  //             id: "updateTenant",
  //             views: [{
  //                     path: "/update-tenant",
  //                     layout: "/admin",
  //                     name: "Update Tenant",
  //                     mini: "UT",
  //                     component: UpdateTenant,
  //                 },

  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-cms",
  //             name: "CMS",
  //             layout: "/admin",
  //             state: "openCMS",
  //             icon: users,
  //             redirect: true,
  //             positions: [leftPanel],
  //             id: "manageCms",
  //             views: [{
  //                 path: "/manage-cms",
  //                 layout: "/admin",
  //                 name: "Manage CMS",
  //                 mini: "MP",
  //                 component: CmsPage,
  //             }, ],
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-ingredient",
  //         //     name: "Ingredient",
  //         //     layout: "/admin",
  //         //     state: "openIngredient",
  //         //     icon: flour,
  //         //     positions: [leftPanel],
  //         //     id: "ingredient",
  //         //     views: [{
  //         //             path: "/manage-ingredient",
  //         //             layout: "/admin",
  //         //             name: "Manage ingredient",
  //         //             mini: "MP",
  //         //             component: ManageIngredient,
  //         //         },
  //         //         {
  //         //             path: "/create-ingredient",
  //         //             layout: "/admin",
  //         //             name: "Create ingredient",
  //         //             mini: "CP",
  //         //             component: CreateIngredient,
  //         //         },
  //         //     ],
  //         // },
  //         {
  //             collapse: true,
  //             path: "/manage-supplier",
  //             name: "Supplier",
  //             layout: "/admin",
  //             state: "openSupplier",
  //             icon: supplier,
  //             positions: [leftPanel],
  //             id: "supplier",
  //             views: [{
  //                     path: "/manage-supplier",
  //                     layout: "/admin",
  //                     name: "Manage Supplier",
  //                     mini: "MS",
  //                     component: ManageSupplierFp,
  //                 },
  //                 {
  //                     path: "/create-supplier",
  //                     layout: "/admin",
  //                     name: "Create Supplier",
  //                     mini: "CS",
  //                     component: CreateSupplierFp,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-customer",
  //             name: "Customer",
  //             layout: "/admin",
  //             state: "openCustomer",
  //             icon: customer,
  //             positions: [leftPanel],
  //             id: "customer",
  //             views: [{
  //                     path: "/manage-customer",
  //                     layout: "/admin",
  //                     name: "Manage Customer",
  //                     mini: "MC",
  //                     component: ManageCustomer,
  //                 },
  //                 {
  //                     path: "/create-customer",
  //                     layout: "/admin",
  //                     name: "Create Customer",
  //                     mini: "CC",
  //                     component: CreateCustomer,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-facility",
  //             name: "Facility",
  //             layout: "/admin",
  //             state: "openFacilities",
  //             icon: facility,
  //             positions: [leftPanel],
  //             id: "facility",
  //             views: [{
  //                     path: "/manage-facility",
  //                     layout: "/admin",
  //                     name: "Manage Facility",
  //                     mini: "MF",
  //                     component: ManageFacility,
  //                 },
  //                 {
  //                     path: "/create-facility",
  //                     layout: "/admin",
  //                     name: "Create Facility",
  //                     mini: "CF",
  //                     component: CreateFacility,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-qrcode",
  //             name: "Label/Barcode",
  //             layout: "/admin",
  //             state: "openQRCode",
  //             icon: scan,
  //             positions: [leftPanel],
  //             id: "qrcode",
  //             views: [{
  //                     path: "/manage-qrcode",
  //                     layout: "/admin",
  //                     name: "Manage QR Code",
  //                     mini: "MQ",
  //                     component: ManageQRCodeFp,
  //                 },
  //                 {
  //                     path: "/create-qrcode",
  //                     layout: "/admin",
  //                     name: "Create QR Code",
  //                     mini: "CQ",
  //                     component: QRCodeFp,
  //                 },
  //                 {
  //                     path: "/edit-qrcode",
  //                     layout: "/admin",
  //                     name: "Edit QR Code",
  //                     mini: "EQ",
  //                     component: EditQRCodePageFp,
  //                 },
  //                 {
  //                     path: "/preview",
  //                     layout: "/admin",
  //                     name: "Preview Consumer Page",
  //                     mini: "PCP",
  //                     component: PreviewConsumerPageFp
  //                 },
  //                 {
  //                     path: "/create-barcode",
  //                     layout: "/admin",
  //                     name: "Create Bar Code",
  //                     mini: "CB",
  //                     component: BarCodeFp,
  //                 },
  //             ],
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/demandplanning",
  //         //     name: "Demand Planning",
  //         //     layout: "/admin",
  //         //     state: "openDemandPlanning",
  //         //     icon: demandPlanning,
  //         //     positions: [leftPanel],
  //         //     id: "automation",
  //         //     views: [{
  //         //             path: "/demandplanning",
  //         //             layout: "/admin",
  //         //             name: "Demand Planning",
  //         //             mini: "MA",
  //         //             component: DemandPlanning,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-requirement",
  //         //     name: "Requirement",
  //         //     layout: "/admin",
  //         //     state: "openRequirement",
  //         //     icon: requirement,
  //         //     positions: [leftPanel],
  //         //     id: "automation",
  //         //     views: [{
  //         //             path: "/manage-requirement",
  //         //             layout: "/admin",
  //         //             name: "Requirement",
  //         //             mini: "MA",
  //         //             component: ManageRequirement,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/batch-detail",
  //         //     name: "Batch Detail",
  //         //     layout: "/admin",
  //         //     state: "openPipelines",
  //         //     icon: list,
  //         //     positions: [leftPanel],
  //         //     id: "bathDetails",
  //         //     views: [{
  //         //             path: "/batch-detail",
  //         //             layout: "/admin",
  //         //             name: "Batch Detail",
  //         //             mini: "MP",
  //         //             component: ManageBatch,
  //         //         },
  //         //         {
  //         //             path: "/configure-pipeline",
  //         //             layout: "/admin",
  //         //             name: "Configure Pipeline",
  //         //             mini: "CP",
  //         //             component: ConfigurePipeline,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-sale-order",
  //         //     name: "Sale Order",
  //         //     layout: "/admin",
  //         //     state: "openSaleOrder",
  //         //     icon: sell,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Sell",
  //         //     id: "salesOrder",
  //         //     views: [{
  //         //             path: "/manage-sale-order",
  //         //             layout: "/admin",
  //         //             name: "Manage Sale Order",
  //         //             mini: "MSO",
  //         //             component: ManageSaleOrderFp,
  //         //         },
  //         //         {
  //         //             path: "/create-sale-order",
  //         //             layout: "/admin",
  //         //             name: "Create Sale Order",
  //         //             mini: "CSO",
  //         //             component: CreateSaleOrderFp,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-incident",
  //         //     name: "Incident",
  //         //     layout: "/admin",
  //         //     state: "openIncident",
  //         //     icon: info,
  //         //     positions: [leftPanel],
  //         //     id: "incident",
  //         //     views: [{
  //         //             path: "/manage-incident",
  //         //             layout: "/admin",
  //         //             name: "Manage Incident",
  //         //             mini: "MI",
  //         //             component: ManageIncident,
  //         //         },
  //         //         {
  //         //             path: "/create-incident",
  //         //             layout: "/admin",
  //         //             name: "Create Incident",
  //         //             mini: "CI",
  //         //             component: CreateIncident,
  //         //         }
  //         //     ],
  //         // },
  //         {
  //             collapse: true,
  //             path: "/manage-documents",
  //             layout: "/admin",
  //             name: "Document Management",
  //             icon: documentmangment,
  //             component: ManageDocuments,
  //             positions: [leftPanel],
  //             id: "managedocuments",
  //             views: [{
  //                     path: "/manage-documents",
  //                     layout: "/admin",
  //                     name: "Document Management",
  //                     mini: "DM",
  //                     component: ManageDocuments,
  //                 },
  //             ],
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-stock-adjustment",
  //         //     name: "Stock Adjustment",
  //         //     layout: "/admin",
  //         //     state: "openStockAdjustment",
  //         //     icon: stockadjustment,
  //         //     positions: [leftPanel],
  //         //     id: "stockAdjustmentView",
  //         //     views: [{
  //         //             path: "/create-stock-adjustment",
  //         //             layout: "/admin",
  //         //             name: "Create Stock Adjustment",
  //         //             mini: "MS",
  //         //             component: CreateStockAdjustment,
  //         //         },
  //         //         {
  //         //             path: "/manage-stock-adjustment",
  //         //             layout: "/admin",
  //         //             name: "Manage Stock Adjustment",
  //         //             mini: "MS",
  //         //             component: ManageStockAdjustment,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/product-mapping",
  //         //     layout: "/admin",
  //         //     name: "Mapping",
  //         //     icon: mapping,
  //         //     component: ProductMapping,
  //         //     positions: [leftPanel],
  //         //     id: "inventory",
  //         //     views: [{
  //         //             path: "/product-mapping",
  //         //             layout: "/admin",
  //         //             name: "Mapping",
  //         //             mini: "M",
  //         //             component: ProductMapping,
  //         //         },
  //         //     ],
  //         // },
  //         {
  //             collapse: true,
  //             path: "/manage-repack-order",
  //             name: "Repack Order",
  //             layout: "/admin",
  //             state: "openRepackOrder",
  //             icon: repack,
  //             positions: [leftPanel],
  //             topPanelName: "Repack",
  //             id: "salesOrder",
  //             views: [{
  //                     path: "/manage-repack-order",
  //                     layout: "/admin",
  //                     name: "Manage Repack Order",
  //                     mini: "MRO",
  //                     component: ManageRepackOrder,
  //                 },
  //                 {
  //                     path: "/create-repack-order",
  //                     layout: "/admin",
  //                     name: "Create Repack Order",
  //                     mini: "CRO",
  //                     component: CreateRepackOrder,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-report",
  //             name: "Report",
  //             layout: "/admin",
  //             state: "openReport",
  //             icon: stockadjustment,
  //             positions: [leftPanel],
  //             id: "report",
  //             views: [
  //                 {
  //                     path: "/manage-report",
  //                     layout: "/admin",
  //                     name: "Manage Report",
  //                     mini: "MR",
  //                     component: ManageReport,
  //                 },
  //             ],
  //         },
  //         {
  //             collapse: true,
  //             path: "/manage-stock-transfer",
  //             name: "Stock Transfer",
  //             layout: "/admin",
  //             state: "openStockTransfer",
  //             icon: stocktransfernew,
  //             positions: [leftPanel],
  //             topPanelName: "Stock Transfer",
  //             id: "stockTransfer",
  //             views: [{
  //                     path: "/manage-stock-transfer",
  //                     layout: "/admin",
  //                     name: "Manage Stock",
  //                     mini: "MS",
  //                     component: ManageStockTransferFp,
  //                 },
  //                 {
  //                     path: "/create-stock-transfer",
  //                     layout: "/admin",
  //                     name: "Create Stock",
  //                     mini: "CS",
  //                     component: CreateStockTransferFp,
  //                 },
  //             ],
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-stock-kit",
  //         //     name: "Repack",
  //         //     layout: "/admin",
  //         //     state: "openBundle",
  //         //     icon: repack,
  //         //     positions: [leftPanel],
  //         //     topPanelName: "Repack",
  //         //     id: "stockKit",
  //         //     views: [{
  //         //             path: "/manage-stock-kit",
  //         //             layout: "/admin",
  //         //             name: "Manage Kit",
  //         //             mini: "MK",
  //         //             component: ManageStockKitFp,
  //         //         },
  //         //         {
  //         //             path: "/create-stock-kit",
  //         //             layout: "/admin",
  //         //             name: "Stock Kit",
  //         //             mini: "SK",
  //         //             component: CreateStockKitFp,
  //         //         },
  //         //     ],
  //         // },
  //         {
  //             collapse: true,
  //             path: "/setting",
  //             layout: "/admin",
  //             name: "Settings",
  //             icon: setting,
  //             component: Settings,
  //             positions: [leftPanel],
  //             id: "adminSettings",
  //             views: [{
  //                     path: "/setting",
  //                     layout: "/admin",
  //                     name: "Setting",
  //                     mini: "S",
  //                     component: SettingsFp,
  //                 },
  //             ],
  //         },
  //         {
  //             path: "/login-page",
  //             layout: "/auth",
  //             name: "Login Page",
  //             icon: users,
  //             component: LoginPage,
  //             redirect: true,
  //             id: "login"
  //         },
  //         {
  //             path: "/register-page",
  //             layout: "/auth",
  //             name: "Register Page",
  //             icon: users,
  //             component: SignUpPage,
  //             redirect: true,
  //             id: "register"
  //         },
  //         {
  //             path: "/changepassword",
  //             layout: "/auth",
  //             name: "Change Password",
  //             icon: users,
  //             component: ChangePassword,
  //             redirect: true,
  //             id: "changepassword"
  //         },
  //         {
  //             path: "/forgotpassword",
  //             layout: "/auth",
  //             name: "Forgot Password",
  //             icon: users,
  //             component: ForgotPassword,
  //             redirect: true,
  //             id: "forgotpassword"
  //         },
  //         {
  //             path: "/confirmation",
  //             layout: "/auth",
  //             name: "Confirmation",
  //             icon: users,
  //             component: Confirmation,
  //             redirect: true,
  //             id: "confirmation"
  //         },
  //         {
  //             path: "/suspended",
  //             layout: "/auth",
  //             name: "Suspended",
  //             icon: users,
  //             component: Suspended,
  //             redirect: true,
  //             id: "suspended"
  //         },
  //         {
  //             path: "/newpassword",
  //             layout: "/auth",
  //             name: "Set New Password",
  //             icon: users,
  //             component: NewPassword,
  //             redirect: true,
  //             id: "newpassword"
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-mfg-order",
  //         //     name: "Manufacture",
  //         //     layout: "/admin",
  //         //     state: "openMfgOrder",
  //         //     icon: make,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Make",
  //         //     id: "manufacture",
  //         //     views: [{
  //         //             path: "/manage-mfg-order",
  //         //             layout: "/admin",
  //         //             name: "",
  //         //             mini: "MP",
  //         //             component: ManageMfgOrder,
  //         //         },
  //         //         {
  //         //             path: "/create-mfg-order",
  //         //             layout: "/admin",
  //         //             name: "",
  //         //             mini: "CP",
  //         //             component: CreateMfgOrder,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-purchase-order",
  //         //     name: "Purchase Order",
  //         //     layout: "/admin",
  //         //     state: "openPurchases",
  //         //     icon: source,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Procure",
  //         //     id: "purchaseOrder",
  //         //     views: [{
  //         //             path: "/manage-purchase-order",
  //         //             layout: "/admin",
  //         //             name: "Manage Purchase Order",
  //         //             mini: "MPO",
  //         //             component: ManagePurchaseOrderFp,
  //         //         },
  //         //         {
  //         //             path: "/create-purchase-order",
  //         //             layout: "/admin",
  //         //             name: "Create Purchase Order",
  //         //             mini: "CPO",
  //         //             component: CreatePurchaseOrderFp,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     path: "/manage-inventory",
  //         //     name: "Inventory",
  //         //     layout: "/admin",
  //         //     state: "openInventory",
  //         //     icon: inventory,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Inventory",
  //         //     component: ManageInventoryFp,
  //         //     id: "inventory"
  //         // },
  //         {
  //             collapse: true,
  //             path: "/manage-finance",
  //             name: "Finances",
  //             layout: "/admin",
  //             state: "openFinances",
  //             icon: trace,
  //             positions: [topPanel],
  //             topPanelName: "Finance",
  //             id: "inventory",
  //             views: [{
  //                     path: "/manage-finance",
  //                     layout: "/admin",
  //                     name: "Manage Finance",
  //                     mini: "MF",
  //                     component: ManageFinance,
  //                 },
  //             ],
  //         },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-automation",
  //         //     name: "Inventory",
  //         //     layout: "/admin",
  //         //     state: "openAutomation",
  //         //     icon: automation,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Automation",
  //         //     id: "automation",
  //         //     views: [{
  //         //             path: "/manage-automation",
  //         //             layout: "/admin",
  //         //             name: "Manage Automatiom",
  //         //             mini: "MA",
  //         //             component: ManageAutomation,
  //         //         },
  //         //         {
  //         //             path: "/create-automation",
  //         //             layout: "/admin",
  //         //             name: "Create Automation",
  //         //             mini: "CA",
  //         //             component: CreateAutomation,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-traceability",
  //         //     name: "Trace",
  //         //     layout: "/admin",
  //         //     state: "openTrace",
  //         //     icon: trace,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Trace",
  //         //     id: "trace",
  //         //     views: [{
  //         //             path: "/manage-traceability",
  //         //             layout: "/admin",
  //         //             name: "Manage Traceability",
  //         //             mini: "MT",
  //         //             component: ManageTraceability,
  //         //         },
  //         //         {
  //         //             path: "/traceability",
  //         //             layout: "/admin",
  //         //             name: "Traceability",
  //         //             mini: "T",
  //         //             component: Traceability,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-stock-transfer",
  //         //     name: "Stock Transfer",
  //         //     layout: "/admin",
  //         //     state: "openStockTransfer",
  //         //     icon: stocktransfer,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Stock Transfer",
  //         //     id: "stockTransfer",
  //         //     views: [{
  //         //             path: "/manage-stock-transfer",
  //         //             layout: "/admin",
  //         //             name: "Manage Stock",
  //         //             mini: "MS",
  //         //             component: ManageStockTransfer,
  //         //         },
  //         //         {
  //         //             path: "/create-stock-transfer",
  //         //             layout: "/admin",
  //         //             name: "Create Stock",
  //         //             mini: "CS",
  //         //             component: CreateStockTransfer,
  //         //         },
  //         //     ],
  //         // },
  //         // {
  //         //     collapse: true,
  //         //     path: "/manage-stock-kit",
  //         //     name: "Bundle",
  //         //     layout: "/admin",
  //         //     state: "openBundle",
  //         //     icon: kit,
  //         //     positions: [topPanel],
  //         //     topPanelName: "Bundle",
  //         //     id: "stockKit",
  //         //     views: [{
  //         //             path: "/manage-stock-kit",
  //         //             layout: "/admin",
  //         //             name: "Manage Kit",
  //         //             mini: "MK",
  //         //             component: ManageStockKit,
  //         //         },
  //         //         {
  //         //             path: "/create-stock-kit",
  //         //             layout: "/admin",
  //         //             name: "Stock Kit",
  //         //             mini: "SK",
  //         //             component: CreateStockKit,
  //         //         },
  //         //     ],
  //         // },
  //     ];
  // }
  // if(track == "uti") {
  return routes;
}
