import * as purchaseOrderActionTypes from "../actions/purchaseOrderActionTypes";

const initialState = {
  purchaseOrderDetails: null,
  purchaseOrderList: null,
  purchaseOrderStockList: null,
  selectedPurchaseOrderCode: null,
  qboDetail: null,
  qboBillDetail: null,
  purchaseOrderSearchList: null,
  incomingShipmentList: null,
  liquidationDetails: null,
  purchaseOrderPaymentDetails: null,
  selectedFacilityId: null,
  selectedSupplierId: null,
};

const purchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case purchaseOrderActionTypes.SET_PURCHASE_ORDER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case purchaseOrderActionTypes.SET_PURCHASE_ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderDetails: action.payload,
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_LIST:
      return {
        ...state,
        purchaseOrderList: action.payload,
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_INCOMING_SHIPMENT_LIST:
      return {
        ...state,
        incomingShipmentList: action.payload,
      };
    case purchaseOrderActionTypes.GET_LIQUIDATION_DETAILS:
      return {
        ...state,
        liquidationDetails: action.payload,
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_STOCK_LIST:
      return {
        ...state,
        purchaseOrderStockList: action.payload,
      };
    case purchaseOrderActionTypes.SET_SELECTED_PURCHASE_ORDER_CODE:
      return {
        ...state,
        selectedPurchaseOrderCode: action.payload,
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderDetails: action.payload,
      };
    case purchaseOrderActionTypes.GET_QBO_DETAILS:
      return {
        ...state,
        qboDetail: action.payload,
      };
    case purchaseOrderActionTypes.GET_QBO_BILL_DETAILS:
      return {
        ...state,
        qboBillDetail: action.payload,
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_SEARCH_LIST:
      return {
        ...state,
        purchaseOrderSearchList: action.payload
      };
    case purchaseOrderActionTypes.GET_PURCHASE_ORDER_PAYMENT_DETAILS:
      return {
        ...state,
        purchaseOrderPaymentDetails: action.payload
      };
    case purchaseOrderActionTypes.SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload,
      };
    case purchaseOrderActionTypes.SET_SELECTED_SUPPLIER_CODE:
      return {
        ...state,
        selectedSupplierId: action.payload,
      };
    default:
      return state;
  }
};

export default purchaseOrderReducer;
