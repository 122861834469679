import mixpanel from 'mixpanel-browser';
const MIX_PANEL_KEY = process.env.REACT_APP_MIX_PANEL_KEY;
mixpanel.init(MIX_PANEL_KEY);

const actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};

export default actions;