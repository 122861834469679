export const SET_SELECTED_SALE_ORDER_CODE = "SET_SELECTED_SALE_ORDER_CODE";
export const SET_SALE_ORDER_DETAILS = "SET_SALE_ORDER_DETAILS";
export const SET_SALE_ORDER_REDUCER_INIT_MODE = "SET_SALE_ORDER_REDUCER_INIT_MODE";
export const GET_SALE_ORDER_LIST = "GET_SALE_ORDER_LIST";
export const GET_SALE_ORDER_PACKING_LIST = "GET_SALE_ORDER_PACKING_LIST";
export const GET_SALE_ORDER_DETAILS = "GET_SALE_ORDER_DETAILS";
export const GET_SALE_ORDER_PRIORITY_DETAILS = "GET_SALE_ORDER_PRIORITY_DETAILS";
export const GET_SHOPIFY_CUSTOMER_DETAILS = "GET_SHOPIFY_CUSTOMER_DETAILS";
export const SET_PRODUCT_STATUS_LIST = "SET_PRODUCT_STATUS_LIST";
export const GET_SALE_ORDER_STOCK_LIST = "GET_SALE_ORDER_STOCK_LIST";
export const GET_SALE_ORDER_REFERENCE_DETAILS = "GET_SALE_ORDER_REFERENCE_DETAILS";
export const GET_SALE_ORDER_DASHBOARD_LIST_URL = "GET_SALE_ORDER_DASHBOARD_LIST_URL";
export const GET_SALE_ORDER_COUNT_DASHBOARD_LIST = "GET_SALE_ORDER_COUNT_DASHBOARD_LIST";
export const GET_SALE_OTIF_DASHBOARD_LIST = "GET_SALE_OTIF_DASHBOARD_LIST";
export const GET_INVENTORY_COVERAGE_DASHBOARD_LIST = "GET_INVENTORY_COVERAGE_DASHBOARD_LIST";
export const GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST = "GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST";
export const GET_SALE_CHANNEL_COST_DASHBOARD_LIST = "GET_SALE_CHANNEL_COST_DASHBOARD_LIST";
export const GET_SALE_ORDER_PAYMENT_DETAILS = "GET_SALE_ORDER_PAYMENT_DETAILS";
export const GET_CART_DETAILS = "GET_CART_DETAILS"
export const GET_DETAILS = "GET_DETAILS";

export const GET_SALE_PROFIT_DASHBOARD_LIST = "GET_SALE_PROFIT_DASHBOARD_LIST";
export const GET_QBO_INVOICE_DETAILS = "GET_QBO_INVOICE_DETAILS";
export const GET_QBO_DETAILS = "GET_QBO_DETAILS";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
