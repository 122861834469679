export const GET_QRCODE_DETAILS = "GET_QRCODE_DETAILS";
export const CLEAR_ALL_QRCODES = "CLEAR_ALL_QRCODES";
export const CLEAR_POPUP_MESSAGES = "CLEAR_POPUP_MESSAGES";

// Action Types For Consumer Page Design
export const CONSUMER_PAGE = "CONSUMER_PAGE";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const ERROR_UPDATE_LOGO = "ERROR_UPDATE_LOGO";
export const UPDATE_CAROUSEL = "UPDATE_CAROUSEL";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const UPDATE_CONTENT_DATA = "UPDATE_CONTENT_DATA";
export const SAVE_CONSUMER_PAGE_DATA = "SAVE_CONSUMER_PAGE_DATA";
export const UPDATE_CONSUMER_PAGE_DATA = "UPDATE_CONSUMER_PAGE_DATA";
export const ADD_CONSUMER_PAGE_DATA = "ADD_CONSUMER_PAGE_DATA";
export const UPDATE_ELEMENTS_ORDER = "UPDATE_ELEMENTS_ORDER";
export const UPLOAD_FILE = "UPLOAD_FILE";

export const GET_TRACEABILITY_LIST = "GET_TRACEABILITY_LIST";
