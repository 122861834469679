import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ajaxCallsInProgress: 0,
  ajaxCallStatus: null,
  ajaxTempCallStatus: null,
  uploadImportFileStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BEGIN_AJAX_CALL: {
      return { ...state, ajaxCallsInProgress: state.ajaxCallsInProgress + 1 };
    }
    case actionTypes.AJAX_CALL_ERROR: {
      return { ...state, ajaxCallsInProgress: state.ajaxCallsInProgress - 1 };
    }
    case actionTypes.END_AJAX_CALL: {
      return { ...state, ajaxCallsInProgress: state.ajaxCallsInProgress - 1 };
    }
    case actionTypes.SET_AJAX_CALL_STATUS:
      return { ...state, ajaxCallStatus: action.payload };
    case actionTypes.SET_TEMP_AJAX_CALL_STATUS:
      return { ...state, ajaxTempCallStatus: action.payload };
    case actionTypes.SET_UPLOAD_IMPORT_FILE_STATUS:
      return { ...state, uploadImportFileStatus: action.payload };
    default:
      return state;
  }
};
