import * as mappingActionTypes from "../actions/mappingActionTypes";

const initialState = {
  productMapDetails: null,
  similarProductList: null
};

const mappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case mappingActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case mappingActionTypes.SET_SIMILAR_PRODUCT_MAP_DETAILS:
      return {
        ...state,
        productMapDetails: action.payload
      };
    case mappingActionTypes.GET_SIMILAR_PRODUCT_LIST:
      return {
        ...state,
        similarProductList: action.payload
      };

    default:
      return state;
  }
};

export default mappingReducer;
