export const USER_DETAILS_HEADER_TITLE = "Create User";
export const MANAGE_USER_HEADER_TITLE = "Manage User";
export const ROLE_DETAILS_HEADER_TITLE = "Role Access";

export const INACTIVE_STATUS = "inactive";
export const ACTIVE_STATUS = "active";
export const PENDING_INVITATION = "unconfirmed"

export const CREATE_USER = "createUser";
export const DEATIVATE_USER = "deactivateUser";
export const ACTIVE_USER = "activateUser";
export const RESEND_EMAIL = "resend";
export const MODULE_NAME = "user";

export const CREATE_USER_PAGE_URL = '/admin/create-user';
export const MANAGE_USER_PAGE_URL = '/admin/manage-user';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GET_USER_LIST_URL = `${BASE_URL}/users`;
export const GET_USER_DETAILS_URL = `${BASE_URL}/users/`;
export const SET_CREATE_USER_DETAILS_URL = `${BASE_URL}/users/`;
export const SET_UPDATE_USER_DETAILS_URL = `${BASE_URL}/users/`;
export const RESEND_USER_PASSWORD_URL = `${BASE_URL}/users/adminsetpassword`;
export const SET_UPDATE_USER_STATUS_URL = `${BASE_URL}/users/status`;
export const USER_PROFILE = `${BASE_URL}/users/profile`;
export const UPDATE_USER_PROFILE_IMAGE = `${BASE_URL}/users/profile/image`;
export const UPLOAD_BASE_URL = process.env.REACT_APP_UPLOAD_API_BASE_URL;
export const UPLOAD_USER_IMAGE = `${UPLOAD_BASE_URL}/upload`;
export const USER_CHECK_BASE_URL = `${BASE_URL}/users/usercheck`;
export const GET_USER_SEARCH_URL = `${BASE_URL}/users/`;
export const SET_USER_DASHBOARD_URL = `${BASE_URL}/users/profile/dashboard`;
export const GET_USER_LIST_NEW_URL = `${BASE_URL}/usersByFacility`;

export const  roles = [
    { value: 'SuperUser', label: 'Super User' },
    { value: 'ProductOwner', label: 'Product Owner' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Production', label: 'Production' },
    { value: 'Procurement', label: 'Procurement' },
]
