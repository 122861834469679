import * as dataDictionaryActionTypes from "../actions/dataDictionaryActionTypes";
import CommonUtil from '../../common/util/commonUtil';

const initialState = {
  dataDictionaryList: null,
  currencyCode: null,
  commission: null,
  stateGST: null,
  centralGST: null,
  orgInformation: null,
  track: null,
  tierPricing: null,
  uomGroupList: null,
};

const dataDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case dataDictionaryActionTypes.SET_DATA_DICTIONARY_DETAILS:
      return {
        ...state,
        dataDictionaryList: action.payload,
      };
    case dataDictionaryActionTypes.GET_DATA_DICTIONARY_DETAILS:
      return {
        ...state,
        dataDictionaryList: action.payload,
        currencyCode: CommonUtil.getAppCurrencyCode(action.payload),
        // orgInformation: CommonUtil.getOrgInformationReducer(action.payload),
        // commission: CommonUtil.getCommission(action.payload),
        // stateGST: CommonUtil.getStateGSTPercentage(action.payload),
        // centralGST: CommonUtil.getCentralGSTPercentage(action.payload),
        // track: CommonUtil.getTrackInfo(action.payload),
      };
    case dataDictionaryActionTypes.GET_ORGANIZATION_DETAILS:
      return {
        ...state,
        orgInformation: action.payload,
      };
    case dataDictionaryActionTypes.GET_TIERPRICING_DETAILS:
      return {
        ...state,
        tierPricing: action.payload,
      };
    case dataDictionaryActionTypes.GET_UOM_DETAILS:
      return {
        ...state,
        uomGroupList: action.payload,
      };
    default:
      return state;
  }
};

export default dataDictionaryReducer;
