import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: {},
  defaultData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONSUMER_PAGE: {
      return {
        ...state,
        data: action.payload.data,
        defaultData: action.payload.defaultData,
      };
    }

    case actionTypes.ADD_CONSUMER_PAGE_DATA: {
      return {
        ...state,
        data: action.payload.data,
      };
    }

    case actionTypes.SAVE_CONSUMER_PAGE_DATA: {
      return state;
    }

    case actionTypes.UPDATE_CONSUMER_PAGE_DATA: {
      return state;
    }

    case actionTypes.UPDATE_LOGO: {
      return {
        ...state,
        data: {
          ...state.data,
          elements: {
            ...state.data.elements,
            header: {
              ...state.data.elements.header,
              logo: {
                ...state.data.elements.header.logo,
                logoUrl: action.payload.fileName,
              },
            },
          },
        },
      };
    }

    case actionTypes.UPDATE_CAROUSEL: {
      return {
        ...state,
        data: {
          ...state.data,
          elements: {
            ...state.data.elements,
            carousel: {
              ...state.data.elements.carousel,
              ...action.payload.carouselImagesList,
            },
          },
        },
      };
    }

    case actionTypes.DELETE_ELEMENT: {
      return {
        ...state,
        data: {
          ...state.data,
          elements: {
            ...state.data.elements,
            [action.payload.elementId]: null,
          },
        },
      };
    }

    case actionTypes.UPDATE_CONTENT_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          elements: {
            ...state.data.elements,
            [action.payload.id]: {
              ...state.data.elements[action.payload.id],
              content: {
                ...state.data.elements[action.payload.id].content,
                [action.payload.name]: action.payload.data,
              },
            },
          },
        },
      };
    }

    case actionTypes.UPDATE_ELEMENTS_ORDER: {
      return {
        ...state,
        data: {
          ...state.data,
          elements: {
            ...state.data.elements,
            moveableElementsOrder: action.payload.elementsOrder,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
