import * as billingActionTypes from "../actions/billingActionTypes";

const initialState = {
    prices: [],
    priceError: null,
    paymentStatus: {
        active: true
    },
    paymentStatusError: null
};

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case billingActionTypes.PRICES_RECEIVED: {
            return {
                ...state,
                prices: action.payload,
                priceError: null
            }
        }
        case billingActionTypes.PRICES_ERROR: {
            return {
                ...state,
                priceError: action.payload,
                prices: []
            }
        }
        case billingActionTypes.IS_PLAN_ACTIVE_RECEIVED: {
            return {
                ...state,
                paymentStatus: action.payload,
                paymentStatusError: null
            }
        }
        case billingActionTypes.IS_PLAN_ACTIVE_ERROR: {
            return {
                ...state,
                paymentStatus: {},
                paymentStatusError: action.payload
            }
        }
        default:
            return state;
    }
}

export default billingReducer;