const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_LOGIN_ID = process.env.REACT_APP_API_LOGIN_ID;
export const AUTH_CLIENT_KEY = process.env.REACT_APP_AUTH_CLIENT_KEY;
export const JWT_TOKEN_URL = `${BASE_URL}/users/tokenwithpermissions`;
export const USER_CHECK_BASE_URL = `${BASE_URL}/users/usercheck`;
export const TENANT_CHECK_BASE_URL = `${BASE_URL}/tenants`;
export const GET_CONFIG_BASE_URL = `${BASE_URL}/configs`;
export const GET_USER_DETAILS_URL = `${BASE_URL}/users`;
export const GET_SHOPIFY_DETAILS = `${BASE_URL}/shopify/registerShopifyStore`;
export const GET_QUOTATION_STATUS_URL = `${BASE_URL}/auth/quotation-confirmation`
export const CHARGE_CREDIT_CARD = `${BASE_URL}/authorize/chargeCreditCard`
export const GET_SO_DETAILS = `${BASE_URL}/authorize/getSoDetail`



export const SO_DETAILS_SHOW = (that) => ({
    attributeObj: {
        customerName: '',
        customerEmail:'',
        // amount:'',
    },
    attributeList: [
      {
        name: "customerName",
        type: "TEXTBOX",
        label: "Name",
        createMode: "disabled",
        cloneMode: "disabled",
        editMode: "disabled",
        viewMode: "disabled",
        showField: true,
        isCustomDisabled:true,
        percentagefields: true,
        volumefields: true,
        otherfields: true,
        createModeShowFlag: true,
        cloneModeShowFlag: true,
        editModeShowFlag: true,
        viewModeShowFlag: true,
        placeholder: "",
        numberOfRow: 0,
        maxLength: 100,
        minLength: 1,
        mandatoryMsgText: "Field can not be empty.",
      },
      {
        name: "customerEmail",
        type: "TEXTBOX",
        label: "Email",
        createMode: "disabled",
        cloneMode: "disabled",
        editMode: "disabled",
        isCustomDisabled:true,
        viewMode: "disabled",
        showField: true,
        percentagefields: true,
        volumefields: true,
        otherfields: true,
        createModeShowFlag: true,
        cloneModeShowFlag: true,
        editModeShowFlag: true,
        viewModeShowFlag: true,
        placeholder: "",
        numberOfRow: 0,
        maxLength: 100,
        minLength: 1,
        mandatoryMsgText: "Field can not be empty.",
      },
      // {
      //   name: "totalPaidAmount",
      //   type: "TEXTBOX",
      //   label: "Amount",
      //   createMode: "disabled",
      //   cloneMode: "disabled",
      //   editMode: "disabled",
      //   viewMode: "disabled",
      //   showField: true,
      //   percentagefields: true,
      //   volumefields: true,
      //   otherfields: true,
      //   createModeShowFlag: true,
      //   cloneModeShowFlag: true,
      //   editModeShowFlag: true,
      //   viewModeShowFlag: true,
      //   placeholder: "",
      //   numberOfRow: 0,
      //   maxLength: 100,
      //   minLength: 1,
      //   mandatoryMsgText: "Field can not be empty.",
      // },
    ],
  });