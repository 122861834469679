export const SET_SELECTED_INVENTORY_CODE = "SET_SELECTED_INVENTORY_CODE";
export const SET_INVENTORY_DETAILS = "SET_INVENTORY_DETAILS";
export const SET_INVENTORY_ACTION_MODE = "SET_INVENTORY_ACTION_MODE";
export const SET_INVENTORY_REDUCER_INIT_MODE = "SET_INVENTORY_REDUCER_INIT_MODE";
export const GET_INVENTORY_LIST = "GET_INVENTORY_LIST";
export const GET_INVENTORY_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_GENERATED_INVENTORY_CODE = "GET_GENERATED_INVENTORY_CODE";
export const GET_INVENTORY_COUNT_DASHBOARD_LIST = "GET_INVENTORY_COUNT_DASHBOARD_LIST";
export const GET_INVENTORY_COUNT_DASHBOARD_LIST_URL = "GET_INVENTORY_COUNT_DASHBOARD_LIST_URL";
export const GET_INVENTORY_SEARCH_LIST = "GET_INVENTORY_SEARCH_LIST";
export const GET_INVENTORY_XLXS_FILE_URL = "GET_INVENTORY_XLXS_FILE_URL";
