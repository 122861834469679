import { Auth } from "aws-amplify";
import * as types from "./actionTypes";
import { beginAjaxCall, endAjaxCall, } from "./ajaxStatusActions";
import { client as Elevio } from "elevio";

export function onLogoutSuccess() {
    return {
        type: types.LOGOUT_SUCCESS
    };
}

export function doLogout() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        return Auth.signOut().then(() => {
            localStorage.removeItem('apiJwtToken');
            localStorage.removeItem('uiToken');
            localStorage.removeItem('localSession');
            localStorage.removeItem('isAuthenticated');
            // localStorage.removeItem('topSellingProducts');
            // localStorage.removeItem('salesByChannelCount');
            // localStorage.removeItem('salesByChannelCost');
            // localStorage.removeItem('MfgOtif');
            // localStorage.removeItem('SaleOtif');
            // localStorage.removeItem('incidentReport');
            // localStorage.removeItem('profitOverTime');
            // localStorage.removeItem('mfgReportCost');
            // localStorage.removeItem('mfgReport');
            dispatch(endAjaxCall());
            dispatch(onLogoutSuccess());
            // Elevio.disable();
        }).catch(error => {
            throw error;
        });
    };
}

export function setAjaxStatusAsNull() {
    return function (dispatch) {
        dispatch({
            type: types.SET_AJAX_CALL_STATUS,
            payload: null
        });
    }
}

