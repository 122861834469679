import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Alert } from "react-bootstrap";
import React, { Component } from "react";
import CommonUtil from './commonUtil';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

const PopupUtil = {

    confirmationPopup: function (popupConfig) {
        return (
            <SweetAlert
                warning
                customClass="popup-header"
                style={{ display: "block", marginTop: "-150px" }}
                title={popupConfig.title}
                text={popupConfig.message}
                confirmBtnText={popupConfig.confirmButtonText ? popupConfig.confirmButtonText : ''}
                cancelBtnText={popupConfig.cancelButtonText ? popupConfig.cancelButtonText : ''}
                onConfirm={popupConfig.onConfirmClick}
                onCancel={popupConfig.onCancelClick}
                btnSize="sm"
                cancelBtnCssClass="btn-cancel"
                confirmBtnCssClass="btn-save btn-fill"
                focusConfirmBtn={false}
                focusCancelBtn={true}
                closeOnCancel={true}
                closeOnConfirm={true}
                showCancel={popupConfig.cancelButtonText ? true : false}
                showConfirm={popupConfig.confirmButtonText ? true : false}
            >
                {popupConfig.subHeading ? <div className="subheading">{popupConfig.subHeading}</div> : ''}
            </SweetAlert>
        )
    },
    deletePopup: function (popupConfig) {
        return (
            <SweetAlert
                showCancel
                warning
                customClass="popup-header"
                style={{ display: "block", marginTop: "-150px" }}
                title={popupConfig.title}
                text={popupConfig.message}
                confirmBtnText={popupConfig.confirmButtonText ? popupConfig.confirmButtonText : ''}
                cancelBtnText={popupConfig.cancelButtonText ? popupConfig.cancelButtonText : ''}
                onConfirm={popupConfig.onConfirmClick}
                onCancel={popupConfig.onCancelClick}
                btnSize="sm"
                cancelBtnCssClass="btn-cancel"
                confirmBtnCssClass="btn-save btn-fill"
                focusConfirmBtn={false}
                focusCancelBtn={true}
                closeOnCancel={true}
                closeOnConfirm={true}
                showConfirm={popupConfig.confirmButtonText ? true : false}
            >
                {popupConfig.message}
            </SweetAlert>
        )
    },
    automaticStocktransferPopup: function (popupConfig, that) {
        return (
            <SweetAlert
                showCancel
                warning
                customClass="popup-header"
                style={{ display: "block", marginTop: "-150px" }}
                title={popupConfig.title}
                text={popupConfig.message}
                confirmBtnText={popupConfig.confirmButtonText ? popupConfig.confirmButtonText : ''}
                cancelBtnText={popupConfig.cancelButtonText ? popupConfig.cancelButtonText : ''}
                onConfirm={popupConfig.onConfirmClick}
                onCancel={popupConfig.onCancelClick}
                btnSize="sm"
                cancelBtnCssClass="btn-cancel"
                confirmBtnCssClass="btn-save btn-fill"
                focusConfirmBtn={false}
                focusCancelBtn={true}
                closeOnCancel={true}
                closeOnConfirm={true}
                showConfirm={popupConfig.confirmButtonText ? true : false}
            >
                {popupConfig.subHeading.map((tempObj, index) => (
                    <div className="subheading" style={{ fontWeight: '400 !important' }}>
                        {tempObj.productId} : {tempObj.quantity}
                    </div>
                ))}

            </SweetAlert>
        )
    },
    popupCancel: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handlePopupContinue;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareCancelPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSave: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handlePopupContinue;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSavePopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveSupplier: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveSupplierPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveProductMake: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveProductMakePopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveProductMakeOperation: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveProductMakeOperationPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveProductMakeIngredients: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveProductMakeIngredientsPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveProductKitting: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveProductKittingPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupSaveProductRepack: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareSaveProductRepackPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupErrorResponse: function (that, errorMessage) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handlePopupContinue;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareAjaxCallErrorPopUpConfig(popActionButton, errorMessage);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupErrorResponseFunction: function (handlePopupCancel, handlePopupContinue, setAlert, errorMessage) {
        var popActionButton = {};
        popActionButton.onConfirmClick = handlePopupContinue;
        popActionButton.onCancelClick = handlePopupCancel;
        var popupConfig = CommonUtil.prepareAjaxCallErrorPopUpConfig(popActionButton, errorMessage);
        setAlert(this.confirmationPopup(popupConfig));
    },
    stockTransferPopup: function (that, errorMessage, data) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleStockTransferPopupContinue;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareAjaxCallErrorPopUpConfig(popActionButton, errorMessage);
        popupConfig.subHeading = data;
        that.setState({ alert: this.automaticStocktransferPopup(popupConfig, that) });
    },
    handlePopupCancel(that) {
        that.setState({ alert: null });
    },
    popupLotTracking: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handleSave;
        popActionButton.onCancelClick = that.handlePopupCancel;
        var popupConfig = CommonUtil.prepareTrackPopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    handleCustomErroMsg: function (that) {
        let errorMessage = that.props.ajaxCallStatus.data &&
            that.props.ajaxCallStatus.data.response &&
            that.props.ajaxCallStatus.data.response.data;
        let status = errorMessage && that.props.ajaxCallStatus.data.response.data.code;
        if (errorMessage && errorMessage.length) {

            var errorPhone = errorMessage && errorMessage.split('(phone_number)=')[1]
            var errorEmail = errorMessage && errorMessage.split('(email)=')[1]
            if (typeof errorMessage === 'object' && status == "409") {
                let customErrorObj = {};
                customErrorObj.customErrorFlag = true;
                customErrorObj.errorType = errorMessage.type;
                customErrorObj.errorMessage = errorMessage.msg;
                that.setState({ customErrorObj: customErrorObj });
            } else if (errorMessage && status == "409") {
                that.setState({ customErrorFlag: true });
            } else if (errorMessage && status == "408") {
                that.setState({ customErrorFlag: false, customEmailErrorFlag: true });
            } else if (errorPhone) {
                this.popupErrorResponse(that, "Phone no. " + errorPhone.replaceAll('"', ''));
            } else if (errorEmail) {
                this.popupErrorResponse(that, "Email " + errorEmail.replaceAll('"', ''));
            } else {
                this.popupErrorResponse(that, errorMessage.replaceAll('"', ''));
            }
        }
    },
    handleBackendErroMsgPopup: function (that) {
        let errorMessage = that.props.ajaxCallStatus.data &&
            that.props.ajaxCallStatus.data.response &&
            that.props.ajaxCallStatus.data.response.data;
        let status = that.props.ajaxCallStatus.data.response.data.code;
        if (status == "422") {
            let popupActionButton = {};
            popupActionButton.onCancelClick = that.handlePopupCancel;
            let popupConfig = CommonUtil.prepareCustomErrorMsgPopUpConfig(popupActionButton,
                errorMessage, false);
            that.setState({ alert: PopupUtil.confirmationPopup(popupConfig) });
        } else {
            this.popupErrorResponse(that);
        }
    },
    getCustomCustomErrorCode: function (that) {
        let errorMessage = that.props.ajaxCallStatus.data &&
            that.props.ajaxCallStatus.data.response &&
            that.props.ajaxCallStatus.data.response.data;
        if (errorMessage) {
            return that.props.ajaxCallStatus.data.response.data.code;
        }
        return null;
    },
    stockNotAvailablePopup: function (that) {
        let popupActionButton = {};
        popupActionButton.onCancelClick = that.handlePopupCancel;
        let popupConfig = CommonUtil.prepareStockNotAvailablePopUpConfig(popupActionButton);
        that.setState({ alert: PopupUtil.confirmationPopup(popupConfig) });
    },
    validateAddressPopup: function (that) {
        let popupActionButton = {};
        popupActionButton.onCancelClick = that.handlePopupCancel;
        let popupConfig = CommonUtil.prepareValidateAddressPopUpConfig(popupActionButton);
        that.setState({ alert: PopupUtil.confirmationPopup(popupConfig) });
    },
    popupSOReadyDone: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handlePopupContinue;
        var popupConfig = CommonUtil.prepareSOReadyDonePopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
    popupPOReadyDone: function (that) {
        var popActionButton = {};
        popActionButton.onConfirmClick = that.handlePopupContinue;
        var popupConfig = CommonUtil.preparePOReadyDonePopUpConfig(popActionButton);
        that.setState({ alert: this.confirmationPopup(popupConfig) });
    },
}
export default PopupUtil;
