export const SET_SELECTED_INGREDIENT_CODE = "SET_SELECTED_INGREDIENT_CODE";
export const SET_INGREDIENT_DETAILS = "SET_INGREDIENT_DETAILS";
export const SET_REDUCER_INIT_MODE = "SET_REDUCER_INIT_MODE";
export const GET_INGREDIENT_LIST = "GET_INGREDIENT_LIST";
export const GET_INACTIVE_INGREDIENT_LIST = "GET_INACTIVE_INGREDIENT_LIST";
export const GET_INGREDIENT_GROSS_LIST = "GET_INGREDIENT_GROSS_LIST";
export const GET_INGREDIENT_DETAILS = "GET_INGREDIENT_DETAILS";
export const SET_SELECTED_INGREDIENT_DETAILS = "SET_SELECTED_INGREDIENT_DETAILS";
export const SET_BULK_IMPORT_INGREDIENT_DETAILS = "SET_BULK_IMPORT_INGREDIENT_DETAILS";
export const GET_SKU_DETAILS = "GET_SKU_DETAILS";
export const GET_INGREDIENT_DETAILS_LIST = "GET_INGREDIENT_DETAILS_LIST";
export const SET_SELECTED_CATEGORY_CODE = "SET_SELECTED_CATEGORY_CODE";