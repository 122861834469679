import * as shipmentOrderActionTypes from "../actions/shipmentOrderActionTypes";
import * as supplierActionTypes from "../../supplierManagement/actions/supplierActionTypes";

const initialState = {
  shipmentDetails: null,
  shipmentList: null,
  selectedShipmentCode: null,
  saleOrderData: null,
  isAddressValidated: null,
  rateList: null,
  packagesList: null,
  allPackageList: null,
};

const shipmentOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case shipmentOrderActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case shipmentOrderActionTypes.GET_SHIPMENTORDER_LIST:
      return {
        ...state,
        shipmentList: action.payload
      };
    case shipmentOrderActionTypes.SET_SELECTED_SHIPMENTORDER_CODE:
      return {
        ...state,
        selectedShipmentCode: action.payload
      };
    case shipmentOrderActionTypes.GET_SHIPMENTORDER_DETAILS:
      return {
        ...state,
        shipmentDetails: action.payload
      };
    case shipmentOrderActionTypes.VALIDATE_ADDRESS:
      return {
        ...state,
        isAddressValidated: action.payload,
      };
    case shipmentOrderActionTypes.RATES_LIST:
      return {
        ...state,
        rateList: action.payload,
      };
    case shipmentOrderActionTypes.SET_PACKAGES_LIST:
      return {
        ...state,
        packagesList: action.payload,
      };
    case shipmentOrderActionTypes.GET_PACKAGES_LIST:
      return {
        ...state,
        allPackageList: action.payload,
      }
    default:
      return state;
  }
};

export default shipmentOrderReducer;
