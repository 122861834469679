import axios from '../../../axios/axios';
import * as incidentActionTypes from './incidentActionTypes';
import * as incidentConstant from '../constant/incidentConstant';
import {
  beginAjaxCall, endAjaxCall, setAjaxCallStatus, updateApiResponse,
  createApiResponse, failedApiResponse
} from "../../../actions/ajaxStatusActions";
import CommonUtil from '../../common/util/commonUtil';

export function setReducerInitMode() {
  return function (dispatch) {
    dispatch({
      type: incidentActionTypes.SET_INCIDENT_REDUCER_INIT_MODE,
      payload: null
    });
  }
}

export function setIncidentDetails(incidentDetailsObj, actionMode) {
  if (CommonUtil.isCreateOrCloneMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.post(incidentConstant.SET_CREATE_INCIDENT_DETAILS_URL,
        incidentDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(createApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
  if (CommonUtil.isEditMode(actionMode)) {
    return function (dispatch) {
      dispatch(beginAjaxCall());
      axios.put(incidentConstant.SET_UPDATE_INCIDENT_DETAILS_URL +
        incidentDetailsObj.incidentCode, incidentDetailsObj).then(response => {
          dispatch(setAjaxCallStatus(updateApiResponse(response)));
          dispatch(endAjaxCall());
        }).catch(error => {
          dispatch(endAjaxCall());
          dispatch(setAjaxCallStatus(failedApiResponse(error)));
        });
    };
  }
}

export function setSelectedIncidentCode(selectedIncidentCode) {
  return function (dispatch) {
    dispatch({
      type: incidentActionTypes.SET_SELECTED_INCIDENT_CODE,
      payload: selectedIncidentCode
    });
  }
}

export function setSelectedBatchCode(selectedBatchCode) {
  return function (dispatch) {
    dispatch({
      type: incidentActionTypes.SET_SELECTED_BATCH_CODE,
      payload: selectedBatchCode
    });
  }
}


export function getIncidentList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(incidentConstant.GET_INCIDENT_LIST_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: incidentActionTypes.GET_INCIDENT_LIST,
          payload: response.data
        });
      }
      dispatch(endAjaxCall());
    }).catch(error => {
      dispatch(endAjaxCall());
    });
  };
}


export function getNotificationList(params) {
  return function (dispatch) {
    axios.get(incidentConstant.GET_INCIDENT_NOTIFICATION_LIST_URL, { params: params }).then(response => {
      if (response.status == 200) {
        dispatch({
          type: incidentActionTypes.GET_NOTIFICATION_INCIDENT_LIST,
          payload: response.data
        });
      }
    }).catch(error => {

    });
  };
}


export function getIncidentDetails(selectedBatchCode, selectedIncidentCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(incidentConstant.GET_BATCH_INCIDENT_DETAILS_URL + selectedBatchCode + "/incidents/" +
      selectedIncidentCode).then(response => {
        if (response.status == 200) {
          dispatch({
            type: incidentActionTypes.GET_INCIDENT_DETAILS,
            payload: response.data.Item
          });
        }
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function updateIncidentStatus(incidentDetailsObj, incidentCode) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(incidentConstant.SET_UPDATE_INCIDENT_STATUS_URL +
      incidentCode, incidentDetailsObj).then(response => {
        if (response.status == 200) {
          axios.get(incidentConstant.GET_INCIDENT_LIST_URL).then(response => {
            if (response.status == 200) {
              dispatch({
                type: incidentActionTypes.GET_INCIDENT_LIST,
                payload: response.data
              })
            }
            dispatch(endAjaxCall());
          })
        }
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function updateIncidentDeleteStatus(incidentDetailsObj, incidentCode,additionalData) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(incidentConstant.SET_UPDATE_INCIDENT_STATUS_URL +
      incidentCode + '?delete=true', incidentDetailsObj).then(response => {
        if (response.status == 200) {
          dispatch(setAjaxCallStatus(updateApiResponse(response,additionalData)));
          dispatch(endAjaxCall());
        }
      }).catch(error => {
        dispatch(endAjaxCall());
        dispatch(setAjaxCallStatus(failedApiResponse(error)));
      });
  };
}

export function updateIncidentReadStatus(incidentDetailsObj) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(incidentConstant.SET_UPDATE_INCIDENT_STATUS_URL +
      incidentDetailsObj.incidentCode, incidentDetailsObj).then(response => {
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function updateIncidentComment(incidentDetailsObj, params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.patch(incidentConstant.SET_UPDATE_INCIDENT_STATUS_URL + incidentDetailsObj.incidentCode + '?comment=true',
      incidentDetailsObj).then(response => {
        dispatch(endAjaxCall());
      }).catch(error => {
        dispatch(endAjaxCall());
      });
  };
}

export function getincidentCount(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(incidentConstant.GET_INCIDENT_DASHBOARD_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: incidentActionTypes.GET_INCIDENT_COUNT_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function getIncidentReportList(params) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    axios.get(incidentConstant.GET_INCIDENT_DASHBOARD_LIST_URL, { params: params }).then((response) => {
      if (response.status == 200) {
        dispatch({
          type: incidentActionTypes.GET_INCIDENT_REPORT_DASHBOARD_LIST,
          payload: response.data,
        });
      }
      dispatch(endAjaxCall());
    })
      .catch((error) => {
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

