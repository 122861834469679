export const SET_SELECTED_KIT_ORDER_CODE = "SET_SELECTED_KIT_ORDER_CODE";
export const SET_KIT_ORDER_DETAILS = "SET_KIT_ORDER_DETAILS";
export const SET_KIT_ORDER_REDUCER_INIT_MODE = "SET_KIT_ORDER_REDUCER_INIT_MODE";
export const GET_KIT_ORDER_LIST = "GET_KIT_ORDER_LIST";
export const GET_KIT_ORDER_DETAILS = "GET_KIT_ORDER_DETAILS";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";







