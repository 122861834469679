import * as facilityActionTypes from "../actions/facilityActionTypes";

const initialState = {
  facilityDetails: null,
  facilityList: null,
  selectedFacilityCode: null,
  documentURLObj: null,
  bulkImportFacilitiesDetails: null,
  capacityReportList: null,
};

const facilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case facilityActionTypes.SET_FACILITY_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case facilityActionTypes.SET_FACILITY_DETAILS:
      return {
        ...state,
        facilityDetails: action.payload
      };
    case facilityActionTypes.SET_UPLOADED_FILE_URL:
      return {
        ...state,
        documentURLObj: action.payload
      };
    case facilityActionTypes.GET_FACILITY_LIST:
      return {
        ...state,
        facilityList: action.payload
      };
    case facilityActionTypes.SET_SELECTED_FACILITY_CODE:
      return {
        ...state,
        selectedFacilityCode: action.payload
      };
    case facilityActionTypes.GET_FACILITY_DETAILS:
      return {
        ...state,
        facilityDetails: action.payload
      };
    case facilityActionTypes.SET_BULK_IMPORT_FACILITY_DETAILS:
      return {
        ...state,
        bulkImportFacilitiesDetails: action.payload
    };
    case facilityActionTypes.GET_CAPACITY_REPORT:
      return {
        ...state,
        capacityReportList: action.payload
    };
    default:
      return state;
  }
};

export default facilityReducer;
