import * as batchActionTypes from "../actions/batchActionTypes";

const initialState = {
  batchDetails: null,
  batchList: null,
  selectedBatchCode: null,
  generatedBatchCode: null,
  documentURLObj: null,
  incompleteBatchCount: null,
  batchWithoutQRCodeCount: null,
  batchWithoutCMSCount: null
};

const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case batchActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case batchActionTypes.SET_UPLOADED_FILE_URL:
      return {
        ...state,
        documentURLObj: action.payload
      };
    case batchActionTypes.SET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: action.payload
      };
    case batchActionTypes.GET_BATCH_LIST:
      return {
        ...state,
        batchList: action.payload
      };
    case batchActionTypes.GET_INCOMPLETE_BATCH_COUNT:
      return {
        ...state,
        incompleteBatchCount: action.payload
      };

    case batchActionTypes.GET_BATCH_WITHOUT_QR_COUNT:
      return {
        ...state,
        batchWithoutQRCodeCount: action.payload
      };
    case batchActionTypes.GET_BATCH_WITHOUT_CMS_COUNT:
      return {
        ...state,
        batchWithoutCMSCount: action.payload
      };
    case batchActionTypes.SET_SELECTED_BATCH_CODE:
      return {
        ...state,
        selectedBatchCode: action.payload
      };
    case batchActionTypes.GET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: action.payload
      };
    case batchActionTypes.GET_GENERATED_BATCH_CODE:
      return {
        ...state,
        generatedBatchCode: action.payload
      };
    default:
      return state;
  }
};

export default batchReducer;
