
export const MANAGE_DASHBOARD_URL = "/dashboard"
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const GET_DATA_DICTIONARY_LIST_URL = `${BASE_URL}/dictionaries/`;
export const GET_DATA_DICTIONARY_DETAILS_URL = `${BASE_URL}/dictionaries`;
export const SET_CREATE_DATA_DICTIONARY_URL = `${BASE_URL}/dictionaries/`;
export const GET_ORGANIZATION_LIST_URL = `${BASE_URL}/dictionaries/organization/`;
export const SET_CREATE_ORGANIZATION_URL = `${BASE_URL}/dictionaries/organization/`;
export const GET_TIERPRICING_LIST_URL = `${BASE_URL}/tierPrices`;
export const SET_TIERPRICING_URL = `${BASE_URL}/tierPrices`;
export const GET_UOM_LIST_URL = `${BASE_URL}/dictionaries/uomDetails`;

export const dataTable = [];

