export const SALE_ORDER_DETAILS_HEADER_TITLE = "Sale Order";
export const MANAGE_SALE_ORDER_HEADER_TITLE = "Sale Order";
export const CREATE_SALE_HEADER_TITLE = "Create Sale Order";
export const EDIT_SALE_HEADER_TITLE = "Edit Sale Order";
export const VIEW_SALE_HEADER_TITLE = "View Sale Order";
export const MODULE_NAME = "Sale Order";
export const SHIP_CONSTANT = "shipping";

export const CREATE_SALE_ORDER = "createSaleOrder";
export const VIEW_PAYMENT_DETAILS = "viewPayment";
export const ORDER_QUOTATION = "quotation";
export const DEATIVATE_SALE_ORDER = "deActivate";
export const ACTIVATE_SALE_ORDER = "activate";
export const SEND_RECEIPT = "sendReceipt";
export const SEND_RECEIPT_N_PAYMENT = "sendReceiptPayment";

export const CREATE_SALE_ORDER_PAGE_URL = '/admin/create-sale-order';
export const MANAGE_SALE_ORDER_PAGE_URL = '/admin/manage-sale-order';
export const CREATE_PO_ORDER_PAGE_URL = '/admin/create-purchase-order';
export const CREATE_MO_ORDER_PAGE_URL = '/admin/create-mfg-order';
export const MANAGE_DOCUMENT_PAGE_URL = "/admin/manage-documents";
export const QUOTATION_PAGE_URL = "/admin/manage-quotation-order";

export const CREATE_PO = 'createPurchaseOrder';
export const CREATE_MO = 'createManageOrder';
export const CREATE_PAGE = "createPage";

export const IS_QBO = process.env.REACT_APP_QBO_ACTIVE;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const POS_BASE_URL = process.env.REACT_APP_POS_API_BASE_URL;
export const GET_SALE_ORDER_LIST_URL = `${BASE_URL}/saleorders`;
export const GET_SALE_ORDER_STOCK_LIST_URL = `${BASE_URL}/saleorders/stocks/`;
export const GET_SALE_ORDER_DETAILS_URL = `${BASE_URL}/saleorders/`;
export const GET_SALE_ORDER_PRIORITY_DETAILS_URL = `${BASE_URL}/saleorders/priority`;
export const GET_SHOPIFY_CUSTOMER_DETAILS_URL = `${BASE_URL}/customer/getAllCustomers`;
export const SET_CREATE_SALE_ORDER_DETAILS_URL = `${BASE_URL}/saleorders/`;
export const SET_UPDATE_SALE_ORDER_DETAILS_URL = `${BASE_URL}/saleorders/`;
export const SET_UPDATE_SALE_ORDER_STATUS_URL = `${BASE_URL}/saleorders/`;
export const GET_SALE_ORDER_PAYMENT_DETAILS_URL = `${BASE_URL}/saleorders/payments/`;

export const SET_UPDATE_SALE_ORDER_AMEND_STATUS_URL = `${BASE_URL}/saleorders/amendStatus/`;

export const SET_UPDATE_SALE_ORDER_PAYMENT_URL = `${BASE_URL}/saleorders/updatepayment`;
export const SET_UPDATE_SALE_ORDER_PRIORITY_URL = `${BASE_URL}/saleorders/priority`;
export const GET_SALE_ORDER_COUNT_DASHBOARD_LIST_URL = `${BASE_URL}/saleorders/counts`;
export const GET_SALE_OTIF_DASHBOARD_LIST = `${BASE_URL}/saleorders/salesotif`;
export const GET_INVENTORY_COVERGAE_DASHBOARD_LIST = `${BASE_URL}/inventory/dashboard/inventorycoverage`;
export const GET_SALE_TOPSELL_DASHBOARD_LIST = `${BASE_URL}/saleorders/topsellproducts`;
export const GET_SALE_CHANNEL_COUNT_DASHBOARD_LIST = `${BASE_URL}/saleorders/channelcount`;
export const GET_SALE_CHANNEL_COST_DASHBOARD_LIST = `${BASE_URL}/saleorders/channelcost`;
export const SET_CREATE_RECALL_WITHDRAW_DETAILS_URL = `${BASE_URL}/saleorders/recallwithdraw/`;
export const DOWNLOAD_XLS_FILE_URL = `${BASE_URL}/saleorders/downloadxls`;
export const DOWNLOAD_DRIVER_XLS_FILE_URL = `${BASE_URL}/saleorders/downloaddriverxls`;
export const DOWNLOAD_INVOICE_URL = `${BASE_URL}/saleorders/downloadInvoice/`;
export const SEND_PAYMENT_EMAIL = `${BASE_URL}/authorize/sendPaymentEmail/`;
export const DOWNLOAD_PACKINGSLIP_URL = `${BASE_URL}/saleorders/downLoadPackingSlip/`;
export const GET_SALE_PROFIT_DASHBOARD_LIST = `${BASE_URL}/saleorders/profitovertime`;
export const SEND_RECEIPT_OVER_SMS = `${POS_BASE_URL}/saleorders/notifications/sms/`;
export const GET_SALE_ORDER_SEARCH_URL = BASE_URL + '/saleorders';
export const dataTable = [];
export const VALID_RECEIVABLE_PAYABLE_AMOUNT = "Please enter amount!";
export const VALID_ACCOUNT_DESCRIPTION_COMBINATION = "Expense account and description already exist.";

export const GET_QBO_CREATE_DETAILS_URL = `${BASE_URL}/qbo/createQBOInvoice`;
export const GET_QBO_DETAILS_URL = `${BASE_URL}/qbo/checkQBOConnection`;
export const GET_CART_DETAILS_URL = `${POS_BASE_URL}/app/cart`;


export const AMEND_STATUS_ERROR_MSG = "Are you sure, you want to amend order Status! ";
export const AUTO_STO_ERROR_MSG = "Automatic stock transfer of below products will be done! ";

export const INVALID_CREDIT_AMOUNT_ERROR_MSG = "Credit Amount Applied can't be greater than available credit or total amount!";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";
