import * as pipeLineActionTypes from "../actions/pipeLineActionTypes";

const initialState = {
  selectedStageType: null,
  selectedSubStageType: null,
  selectedStageId: null,
  pipeLineClassification: null,
  pipeLineStageList: null,
  pipeLineSubStageList: null,
  pipeLineDetails: null,
  pipeLineList: null,
  selectedPipeLineCode: null,
  pipeLineBatchActionMode: null,
  generatedPipeLineCode: null,
  sourcingSubStageType: null,
  pipeLineModal: null,
  submitted: false
};

const pipeLineReducer = (state = initialState, action) => {
  switch (action.type) {
    case pipeLineActionTypes.SET_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case pipeLineActionTypes.SET_SELECTED_STAGE_TYPE:
      return {
        ...state,
        selectedStageType: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_MODAL:
      return {
        ...state,
        pipeLineModal: action.payload
      }
    case pipeLineActionTypes.SET_SELECTED_SOURCING_SUB_STAGE_TYPE:
      return {
        ...state,
        sourcingSubStageType: action.payload
      }
    case pipeLineActionTypes.SET_SELECTED_SUB_STAGE_TYPE:
      return {
        ...state,
        selectedSubStageType: action.payload
      }
    case pipeLineActionTypes.SET_SELECTED_STAGE_ID:
      return {
        ...state,
        selectedStageId: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_CLASSIFICATION:
      return {
        ...state,
        pipeLineClassification: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_STAGE_LIST:
      return {
        ...state,
        pipeLineStageList: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_SUB_STAGE_LIST:
      return {
        ...state,
        pipeLineSubStageList: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_DETAILS:
      return {
        ...state,
        pipeLineDetails: action.payload
      };
    case pipeLineActionTypes.GET_PIPELINE_LIST:
      return {
        ...state,
        pipeLineList: action.payload
      };
    case pipeLineActionTypes.SET_SELECTED_PIPELINE_CODE:
      return {
        ...state,
        selectedPipeLineCode: action.payload
      };
    case pipeLineActionTypes.SET_PIPELINE_SAVE_FLAG:
      return {
        ...state,
        submitted: action.payload
      }
    case pipeLineActionTypes.SET_PIPELINE_BATCH_ACTION_MODE:
      return {
        ...state,
        pipeLineBatchActionMode: action.payload
      }
    case pipeLineActionTypes.GET_PIPELINE_DETAILS:
      return {
        ...state,
        pipeLineDetails: action.payload
      };
    case pipeLineActionTypes.GET_GENERATED_PIPELINE_CODE:
      return {
        ...state,
        generatedPipeLineCode: action.payload
      };
    default:
      return state;
  }
};

export default pipeLineReducer;
