import * as customerActionTypes from "../actions/customerActionTypes";

const initialState = {
  customerDetails: null,
  customerList: null,
  selectedCustomerCode: null,
  bulkImportCustomerDetails: null,
  customerPaymentDetails: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerActionTypes.SET_CUSTOMER_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case customerActionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload
      };

    case customerActionTypes.GET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload
      };
    case customerActionTypes.SET_SELECTED_CUSTOMER_CODE:
      return {
        ...state,
        selectedCustomerCode: action.payload
      };
    case customerActionTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload
      };
    case customerActionTypes.SET_BULK_IMPORT_CUSTOMER_DETAILS:
      return {
        ...state,
        bulkImportCustomerDetails: action.payload
    };
    case customerActionTypes.GET_CUSTOMER_PAYMENT_DETAILS:
      return {
        ...state,
        customerPaymentDetails: action.payload
      };
    default:
      return state;
  }
};

export default customerReducer;
