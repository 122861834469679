import store from "../store/store";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";

const isAuthorized = (moduleIdentifier) => {
    const state = store.getState();
    const userInfo = state.authReducer.userInfo;
    const permissionMapping = state.security.uiComponentsPermissionMapping;
    if (userInfo && !isEmpty(permissionMapping)) {
        let userPermissions = userInfo.permissions && userInfo.permissions.length > 0 ? userInfo.permissions.filter(x => x.app === "web").map(y => y.permissionName) : [];
        const requiredPermissions = permissionMapping[moduleIdentifier] || [];
        return intersection(userPermissions, requiredPermissions).length > 0;
    }
    return false;
}

export default isAuthorized;