/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import image from "assets/img/full-screen-image-3.jpg";

// dinamically create dashboard routes
import getRoutes from "routes.js";

// style for notifications
import { style } from "variables/Variables.jsx";

import { connect } from "react-redux";
import CustomLoader from "components/CustomLoader/CustomLoader.jsx";
import { getUserProfile } from "../modules/userManagement/actions/userActions";
import { getUIPermissionMapping } from "../modules/security/actions/usersActions";
import { isPlanActive } from "../modules/billing/actions/billingActions";
import { client as Elevio } from "elevio";
import SubscriptionBanner from "components/Subscription/SubscriptionBanner.jsx";
import { doLogout } from "actions/authActions";
import { ELEVIO_ID, SESSION_TIMEOUT } from 'constants/GlobalConstants';
import dayjs from 'dayjs';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Cookies from 'js-cookie'
import { getDataDictionaryDetails } from '../modules/dataDictionary/actions/dataDictionaryActions';

var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      navbar: false,
      mini: false,
      fixedClasses: "dropdown show-dropdown open",
      hideVideo: true,
      closeVideo: false,
      localSession: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    // if (!nextProps.isAuthenticated || nextProps.location.pathname === '/admin/') {
    if (!localStorage.getItem('isAuthenticated')) {
      this.props.history.push("/");
    } else {
      var timeout = localStorage.getItem('timeout');
      const now = dayjs();
      if (dayjs(timeout) > now) {
        timeout = dayjs(now).add(SESSION_TIMEOUT, "hour");
        localStorage.setItem('timeout', timeout);
      } else {
        console.log("----session timedout------");
        localStorage.removeItem('apiJwtToken');
        localStorage.removeItem('uiToken');
        localStorage.removeItem('timeout');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('localSession');
        // localStorage.removeItem('topSellingProducts');
        // localStorage.removeItem('salesByChannelCount');
        // localStorage.removeItem('salesByChannelCost');
        // localStorage.removeItem('MfgOtif');
        // localStorage.removeItem('SaleOtif');
        // localStorage.removeItem('incidentReport');
        // localStorage.removeItem('profitOverTime');
        // localStorage.removeItem('mfgReportCost');
        // localStorage.removeItem('mfgReport');
        this.props.history.push("/");
      }
    }
  }

  closeVideo = () => {
    this.setState({ closeVideo: true });
    localStorage.setItem('localSession', true);
    // Elevio.open();
  }

  onUnload = e => {
    e.preventDefault();
    this.props.doLogout();
  };

  onKeyPress = e => {
    if (e.key === "F5") {
      window.removeEventListener("beforeunload", this.onUnload);
    }
  };
  componentDidMount() {
    //window.addEventListener("beforeunload", this.onUnload);
    //window.addEventListener("keydown", this.onKeyPress);
    this.props.getUserProfile();
    this.props.getUIPermissionMapping();
    // this.props.isPlanActive();
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    // Elevio.load(ELEVIO_ID).then(res => {
    //   console.log("Elevio is ready to roar");
    //   // Elevio.open();
    // });
    let video = Cookies.get('video') ? Cookies.get('video') : false;
    let localSession = localStorage.getItem('localSession') ? localStorage.getItem('localSession') : false;
    this.setState({ closeVideo: video, localSession: localSession });
    this.props.getDataDictionaryDetails();
    document.body.classList.toggle("sidebar-mini");


    document.body.addEventListener('click', () => {
      var dropdowns = document.querySelectorAll('.dropdown');
      dropdowns.forEach(function (dropdown) {
        dropdown.classList.remove('open')
      });
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      ps = null;
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        if (ps) {
          ps.update();
        }
      }, 350);
    }
    // if (e.history.action === "PUSH") {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.refs.mainPanel.scrollTop = 0;
    // }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = (notificationConfig) => {
    var level;
    switch (notificationConfig.color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-check" />,
      message: <div>{notificationConfig.message}</div>,
      level: level,
      position: notificationConfig.position,
      autoDismiss: notificationConfig.delayTime,
    });
  };
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleNavbarClick = (navbar) => {
    this.setState({ navbar: navbar });
  };
  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleChange = async () => {
    await this.setState({ hideVideo: !this.state.hideVideo })
    await Cookies.set('video', !this.state.hideVideo, { expires: 7 })
  }

  render() {
    let isLoading = this.props.ajaxCallsInProgress > 0;
    console.log(this.props.ajaxCallsInProgress);
    const { closeVideo, localSession } = this.state;
    const track = this.props.track;
    return (
      <Suspense fallback={<CustomLoader />}>
        <div className="wrapper">
          {/*{(track == "uti" && closeVideo === false && localSession === false) ? 
            <div className="full-page-video">
              <div className="close-button"><i className="fa fa-close" onClick={this.closeVideo}></i></div>
              <div className="video-container">
                <iframe title="video" src="https://youtube.com/embed/sL59ipfPZTw?rel=0;&autoplay=1&mute=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="video"></iframe>
                  <div className="video-checkbox">
                    <Checkbox 
                      inline
                      checked={!this.state.hideVideo}
                      onChange={this.handleChange}
                      key="hideVideo" 
                      number="hideVideo" 
                      label="Don't show video again"
                    />
                  </div>
              </div>
            </div>
          : null}*/}
          <NotificationSystem ref="notificationSystem" style={style} />
          {/* {this.props.paymentStatus.daysRemaining > 0 ? null : <div className="restrict-user"></div> } */}
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />
          <Sidebar
            {...this.props}
            image={this.state.image}
            color={this.state.color}
            hasImage={this.state.hasImage}
            mini={this.state.mini}
            handleMiniClick={this.handleMiniClick}
          />

          {/* <SubscriptionBanner /> */}
          <div
            className={
              "main-panel" +
              (this.props.location.pathname === "/maps/full-screen-maps"
                ? " main-panel-maps"
                : "")
            }
            ref="mainPanel"
          >
            <div className="content-body">
              <Suspense fallback={<CustomLoader />}>
                <Switch>{this.getRoutes(getRoutes({ track }))}</Switch>
                <Footer fluid />
              </Suspense>
            </div>
            {/* this is a temporary condition for testing redux store configuration */}
            {/* {!this.props.isAuthenticated && (
              <FixedPlugin
                handleImageClick={this.handleImageClick}
                handleColorClick={this.handleColorClick}
                handleHasImage={this.handleHasImage}
                handleNavbarClick={this.handleNavbarClick}
                handleMiniClick={this.handleMiniClick}
                bgColor={this.state["color"]}
                bgImage={this.state["image"]}
                mini={this.state["mini"]}
                handleFixedClick={this.handleFixedClick}
                fixedClasses={this.state.fixedClasses}
              />
            )} */}
          </div>
          {isLoading && <CustomLoader />}
        </div>
      </Suspense>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    ajaxCallsInProgress: state.ajaxStatusReducer.ajaxCallsInProgress,
    userProfile: state.user.userProfile,
    paymentStatus: state.billing.paymentStatus,
    track: state.dataDictionary.track,
    dataDictionaryList: state.dataDictionary.dataDictionaryList,
  };
}

export default connect(mapStateToProps, {
  getUserProfile,
  getUIPermissionMapping,
  isPlanActive,
  doLogout,
  getDataDictionaryDetails
})(Dashboard);
