import * as campaignActionTypes from "../actions/campaignActionTypes";

const initialState = {
  campaignDetails: null,
  campaignList: null,
  selectedCampaignCode: null,
  couponList: null,
  selectedCouponCode: null,
  couponDetails: null,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case campaignActionTypes.SET_CAMPAIGN_REDUCER_INIT_MODE:
      return {
        ...initialState,
      };
    case campaignActionTypes.SET_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload
      };
    case campaignActionTypes.GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload
      };
    case campaignActionTypes.SET_SELECTED_CAMPAIGN_CODE:
      return {
        ...state,
        selectedCampaignCode: action.payload
      };
    case campaignActionTypes.GET_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload
      }; 
    case campaignActionTypes.GET_COUPON_LIST:
      return {
        ...state,
        couponList: action.payload
      };  
    case campaignActionTypes.SET_SELECTED_COUPON_CODE:
      return {
        ...state,
        selectedCouponCode: action.payload
      }; 
    case campaignActionTypes.GET_COUPON_DETAILS:
      return {
        ...state,
        couponDetails: action.payload
      };
    default:
      return state;
  }
};

export default campaignReducer;
