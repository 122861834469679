export const GET_QUICKBOOK_URI = "GET_QUICKBOOK_URI";
export const SET_QUICKBOOK_REDUCER_INIT_MODE = "SET_QUICKBOOK_REDUCER_INIT_MODE";
export const CREATETOKEN = "CREATETOKEN";
export const GET_QUICKBOOK_ACCOUNT = "GET_QUICKBOOK_ACCOUNT";
export const SET_QUICKBOOK_ACCOUNT_SETTING = "SET_QUICKBOOK_ACCOUNT_SETTING";
export const GET_QUICKBOOK_TAX_CODE = "GET_QUICKBOOK_TAX_CODE";
export const REVOKE_QUICKBOOK_ACCOUNT = "REVOKE_QUICKBOOK_ACCOUNT";
export const SET_QUICKBOOK_TAX = "SET_QUICKBOOK_TAX";
export const SET_QUICKBOOK_CUSTOMER_SYNC = "SET_QUICKBOOK_CUSTOMER_SYNC";
export const SET_QUICKBOOK_VENDOR_SYNC = "SET_QUICKBOOK_DATVENDORNC";


//SET_SHOPIFY_ACCOUNT_SETTING_URL
export const GET_SHOPIFYKEY_URL = "GET_SHOPIFYKEY_URL";
export const SET_SHOPIFY_PRODUCT = "SET_SHOPIFY_PRODUCT";
export const GET_SHOPIFY_ORDER = "GET_SHOPIFY_ORDER";
export const GET_SHOPIFY_PRODUCTSTOCK = "GET_SHOPIFY_PRODUCTSTOCK";
export const GET_SHOPIFY_ACCOUNT_URL = "GET_SHOPIFY_ACCOUNT_URL";

export const SET_AMAZON_REFRESH_TOKEN = "SET_AMAZON_REFRESH_TOKEN";
export const SET_AMAZON_APP_KEY = "SET_AMAZON_APP_KEY";

export const SET_WOOCOM_ACCESS_TOKEN = "SET_WOOCOM_ACCESS_TOKEN";

export const SET_UNISHIPPER_STATUS = "SET_UNISHIPPER_STATUS"
export const SET_UNISHIPPER_TOKEN = "SET_UNISHIPPER_TOKEN"
export const SET_WOOCOM_STATUS = "SET_WOOCOM_STATUS"