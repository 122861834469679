export const BASE_URL = process.env.REACT_APP_UPLOAD_API_BASE_URL;
export const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const FILEUPLOAD_URL = `${BASE_URL}`;

export const DOWNLOAD_BASE_URL = process.env.REACT_APP_DOWNLOAD_API_BASE_URL;
export const FILEDOWNLOAD_URL = `${DOWNLOAD_BASE_URL}/templates/`;
export const REST_USER_DETAILS_URL = `${APP_BASE_URL}/users/resetuserdetails`;
export const MANAGE_DASHBOARD_URL = "/dashboard";
export const S3_TEMPLATE_BASE_FOLDER = process.env.REACT_APP_S3_TEMPLATE_FOLDER;
export const CREATE_ACTION_MODE = "createMode";
export const CLONE_ACTION_MODE = "cloneMode";
export const EDIT_ACTION_MODE = "editMode";
export const DELETE_ACTION_MODE = "deleteMode";
export const VIEW_ACTION_MODE = "viewMode";
export const QO_TO_SO_ACTION_MODE = "QoToSo";
export const DOWNLOAD_ACTION_MODE = "downloadMode";
export const MENU_ACTION_MODE = "MENU";
export const FILE_UPLOAD = "fileUpload";
export const DELETE_DUMMY_DATA = "This button is for deleting all the pre-installed demo data from your system. This action is allowed only once and then the button will disappear. Are you sure, you want to delete the demo data?";
export const PRINT_ACTION = 'Print';
export const CLONE_ACTION = 'Clone';
export const TRASH_ACTION = 'Trash';
export const ORIGINSCALE = 'originscale';
export const SHOPIFY = 'shopify';
export const WOOCOM = 'woocommerce';
export const AMAZON = 'amazon';
export const SEND_RECEIEPT = 'sendReceipt';
export const SEND_RECEIPT_N_PAYMENT = "sendReceiptPayment";
export const SHIPMENT = "shipment";
export const GENERATE_BARCODE = 'generateBarcode';

export const CREATE_URL = `${BASE_URL}`;
export const FILEUPLOAD_STAGE_LIST =
{
    attributeObj: {
        documentId: '',
        documentType: '',
        documentName: '',
        documentExpiryDate: '',
        fileType: 'application/pdf',
        documentURL: ''
    },
    attributeList: [
        {
            title: "Document Type",
            name: "documentType",
            type: "DROPDOWN",
            dataDictionaryFlag: true,
            dataDictionaryId: 'documentTypeList',
            options: [],
            inputType: "text",
            createMode: 'enable',
            required: true,
            mandatoryMsgText: "Field can not be empty.",
        },
        {
            title: "Document Name",
            name: "documentName",
            type: "TEXTBOX",
            inputType: "text",
            configurePipeLine: 'disabled',
            modifyPipeLine: 'disabled',
            clonePipeLine: 'disabled',
            createBatch: 'enable',
            modifyBatch: 'enable',
            maxLength: 55,
            minLength: 3,
            required: true,
            mandatoryMsgText: "Field can not be empty.",
        },
        {
            title: "Expiry Date",
            name: "documentExpiryDate",
            type: "TEXTBOX",
            inputType: "date",
            configurePipeLine: 'disabled',
            modifyPipeLine: 'disabled',
            clonePipeLine: 'disabled',
            createBatch: 'enable',
            modifyBatch: 'enable',
        },
        {
            title: "",
            name: "action",
            type: "BUTTON",
            downloadIcon: true,
            removeIcon: true,
        }
    ]
};

export const WORKING_DAYS = [
    { value: "Mon", label: "Mon" },
    { value: "Tue", label: "Tue" },
    { value: "Wed", label: "Wed" },
    { value: "Thu", label: "Thu" },
    { value: "Fri", label: "Fri" },
    { value: "Sat", label: "Sat" },
    { value: "Sun", label: "Sun" },
];