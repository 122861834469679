export const SET_SELECTED_STOCK_ADJUSTMENT_CODE = "SET_SELECTED_STOCK_ADJUSTMENT_CODE";
export const SET_CREDIT_NOTE_DETAILS = "SET_CREDIT_NOTE_DETAILS";
export const SET_STOCK_ADJUSTMENT_DETAILS = "SET_STOCK_ADJUSTMENT_DETAILS";
export const SET_STOCK_ADJUSTMENT_REDUCER_INIT_MODE = "SET_STOCK_ADJUSTMENT_REDUCER_INIT_MODE";
export const GET_STOCK_ADJUSTMENT_LIST = "GET_STOCK_ADJUSTMENT_LIST";
export const GET_STOCK_ADJUSTMENT_STOCK_LIST = "GET_STOCK_ADJUSTMENT_STOCK_LIST";
export const GET_STOCK_ADJUSTMENT_DETAILS = "GET_STOCK_ADJUSTMENT_DETAILS";
export const GET_STOCK_DETAILS = "GET_STOCK_DETAILS";
export const GET_CART_DETAILS = "GET_CART_DETAILS"
export const GET_STA_PAYMENT_DETAILS = "GET_STA_PAYMENT_DETAILS";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";

